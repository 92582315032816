import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Button from '../elements/Button';
import { authService } from '../../services';
import { cookies, getDateAfter30Days } from '../../utils';
import { setUserLogin, setUserPassword } from '../../api';

const LoginWrapper = styled.div`
  width: 100%;
  max-width: 1452px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin: 0 auto;
  padding: 172px 122px 166px;
  justify-content: center;
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
    padding: 122px 16px 16px;
    min-width: 1272px;
  }
  .auth {
    margin-top: 6px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 111%;
    /* or 18px */

    letter-spacing: -0.01em;

    /* Gray */

    color: #c1c1c1;
    max-width: 385px;
  }
  Button {
    font-size: 32px;
    width: 385px;
    height: 71px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 24px 14px;
    gap: 8px;
  }
  label {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 111%;
    letter-spacing: -0.01em;
    color: #272727;
  }
  .passwordForgot {
    margin-top: 32px;
    margin-bottom: 48px;
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    display: flex;
    align-items: flex-end;
    letter-spacing: -0.01em;
    text-decoration-line: underline;
    color: #33cccc;
  }
  .forgot {
    margin-top: 40px;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    display: flex;
    align-items: flex-end;
    text-align: center;
    letter-spacing: -0.01em;
    color: #272727;
    .password {
      text-decoration-line: underline;
      cursor: pointer;
      color: #33cccc;
    }
  }
  .title {
    font-style: normal;
    font-weight: 400;
    font-size: 45px;
    line-height: 58px;
    letter-spacing: -0.02em;
    color: #272727;
  }
  .description {
    width: 348px;
    height: 58px;
    padding-top: 90px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
  }
  .loginInput {
    width: 385px;
    height: 71px;
    margin-top: 16px;
    margin-bottom: 32px;
    padding: 10px;
    font-size: 24px;
    border: 1px solid #33cccc;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .passwordInput {
    margin-top: 16px;
    margin-bottom: 32px;
    width: 385px;
    height: 71px;
    padding: 10px;
    font-size: 24px;
    border: 1px solid #33cccc;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .error {
    color: #ff4f4f;
    padding-left: 10px;
    margin-bottom: 32px;
    margin-top: -24px;
  }

  .login {
    margin-top: 40px;
    font-size: 22px;
    .button {
      width: 142px;
      height: 71px;
      text-decoration-line: underline;
      color: #33cccc;
      cursor: pointer;
    }
  }
`;
const Terms = styled.div`
  display: grid;
  grid-template-columns: 4% 96%;
  margin-bottom: 44px;
  margin-top: 44px;
  .termsDescription {
    padding-top: 4px;
    margin-left: 13px;
    display: flex;
    font-weight: 400;
    font-size: 16px;
    color: #272727;
    .terms {
      cursor: pointer;
      color: #33cccc;
      text-decoration-line: underline;
    }
  }
`;
const CheckBox = styled.div<{ color: string }>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 3px;
  max-width: 24px;
  min-height: 24px;
  left: 560px;
  top: 687px;
  border: 2px solid ${(props) => props.color};
  border-radius: 59px;
`;

function LoginPage() {
  const loginRef = useRef<any>();
  const firstPass = useRef<any>();
  const secondPass = useRef<any>();
  const passRef = useRef<any>();
  const [login, setLogin] = useState(false);
  const [loginValue, setLoginValue] = useState<string>('');
  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState('');
  const [passError, setPassError] = useState('');
  const [check, setCheck] = useState(false);
  const [passwordValue, setPasswordValue] = useState<string>('');
  const firstLogin = cookies.get('firstLogin');
  useEffect(() => {
    if (firstLogin) {
      setLogin(true);
    }
  }, [firstLogin]);

  function passwordValidation() {
    setPasswordValue(passRef.current.value);
    if (passRef.current.value === '' || !passRef.current.value) {
      setPassError('Введите пароль');
    } else {
      setPassError('');
    }
    return true;
  }
  function passwordRegValidation() {
    if (firstPass.current.value !== secondPass.current.value) {
      setPassError('Пароли не совпадают');
      setPasswordValue('');
    } else {
      setPasswordValue(firstPass.current.value);
      setPassError('');
    }
    return true;
  }
  function emailValidation() {
    setLoginValue(loginRef.current.value);
    if (/^[a-zA-Z0-9-_.]+@[a-zA-Z0-9-]+\.[A-Za-z]+$/.test(loginValue)) {
      setError('');
      setIsValid(true);
    } else {
      setIsValid(false);
      setError('Проверьте введенный email');
    }
    return true;
  }
  function loginUser() {
    passwordValidation();
    emailValidation();

    if (isValid && passError === '' && !passError) {
      authService
        .loginUser(loginValue, passwordValue)
        .then((e) => {
          cookies.set('auth', e.data.token, {
            path: '/',
            expires: getDateAfter30Days(),
          });
          setUserLogin(loginValue);
          setUserPassword(passwordValue);
        })
        .then(() => {
          cookies.set('firstLogin', true);
          window.location.href = '/';
        });
    }
  }
  function registerUser() {
    emailValidation();
    if (isValid) {
      authService
        .registerUser(loginValue, passwordValue)
        .then((e) => {
          cookies.set('firstLogin', true);
          cookies.set('auth', e.data.token, {
            path: '/',
            expires: getDateAfter30Days(),
          });
          setError('');
          setUserLogin(loginValue);
          setUserPassword(passwordValue);
        })
        .then(() => {
          window.location.href = '/';
        })
        .catch((e) => {
          if (e.response.status === 400) {
            // eslint-disable-next-line no-useless-concat
            if(RegExp('\\b'+  'is not in the allowed lists' + '\\b').test(e.response.data.message))
            {
              setError('Этот логин отсутствует в списках разрешенных');

              // eslint-disable-next-line no-useless-concat
            } else  if(RegExp('\\b' +  'already exists' + '\\b').test(e.response.data.message))
            {
              setError('Пользователь с таким e-mail уже существует');
            }

          }
        });
    }
  }
  function pressEnterLogin(e: any) {
    emailValidation();
    if (e === 'Enter') {
      loginUser();
    }
  }
  return (
    <div>
      <LoginWrapper>
        <div>
          <span className="title">Привет :)</span>
        </div>
        <div>
          <label htmlFor="email">
            Email
            {!login && (
              <div className="auth">
                Введите email, указанный при регистрации на agent.saleschain.io
              </div>
            )}
            <input
              ref={loginRef}
              onKeyUp={(e) => pressEnterLogin(e.key)}
              onChange={(e) => setLoginValue(e.target.value)}
              id="email"
              type="email"
              className="loginInput"
            />
          </label>
          {error !== '' && <div className="error">{error}</div>}
          {login && (
            <>
              <label htmlFor="password">
                Пароль
                <input
                  ref={passRef}
                  onKeyUp={(e) => pressEnterLogin(e.key)}
                  onChange={(e) => {
                    setPasswordValue(e.target.value);
                    passwordValidation();
                  }}
                  id="password"
                  type="password"
                  className="passwordInput"
                />
              </label>
              {passError !== '' && <div className="error">{passError}</div>}
            </>
          )}
          {!login && (
            <>
              <label htmlFor="firstPassword">
                Придумайте пароль
                <input
                  ref={firstPass}
                  onKeyUp={(e) => pressEnterLogin(e.key)}
                  onChange={() => {
                    passwordRegValidation();
                  }}
                  id="firstPassword"
                  type="password"
                  className="passwordInput"
                />
              </label>
              {passError !== '' && <div className="error">{passError}</div>}
              <label htmlFor="secondPassword">
                Повторите пароль
                <input
                  ref={secondPass}
                  onKeyUp={(e) => pressEnterLogin(e.key)}
                  onChange={() => {
                    passwordRegValidation();
                  }}
                  id="secondPassword"
                  type="password"
                  className="passwordInput"
                />
              </label>
              {passError !== '' && <div className="error">{passError}</div>}
            </>
          )}

          {login && (
            <NavLink to="/sendlink">
              <div className="passwordForgot">Восстановить</div>
            </NavLink>
          )}
          {!login && (
            <Terms>
              <CheckBox
                color={check ? '#33CCCC' : '#c1c1c1'}
                onClick={() => {
                  setCheck(!check);
                }}
              >
                {check && (
                  <svg
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.5 5.5L6.43319 11.1225L12 1"
                      stroke="#33CCCC"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </CheckBox>
              <span className="termsDescription">
                Я принимаю&nbsp;
                <NavLink to="/terms">
                  {' '}
                  <span className="terms"> пользовательское соглашение</span>
                </NavLink>
              </span>
            </Terms>
          )}
          {!login && (
            <>
              <Button
                disabled={passwordValue === '' || !check || error !== ''}
                fontSize={32}
                text="Зарегистрироваться"
                onClick={() => registerUser()}
              />
              <div className="login">
                Уже есть аккаунт?{' '}
                <span
                  aria-hidden
                  onClick={() => setLogin(true)}
                  className="button"
                >
                  Войдите
                </span>
              </div>
            </>
          )}
          {login && (
            <>
              <Button
                fontSize={32}
                onClick={() => loginUser()}
                text="Войти"
                disabled={passwordValue === '' || error !== ''}
              />
              <span className="forgot">
                Нет аккаунта?&nbsp;
                <span
                  aria-hidden
                  onClick={() => {
                    setLogin(false);
                  }}
                  className="password"
                >
                  Зарегистрируйтесь
                </span>
              </span>
            </>
          )}
        </div>
      </LoginWrapper>
    </div>
  );
}

export default LoginPage;
