import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { useState } from 'react';
// @ts-ignore
import ItemsCarousel from 'react-items-carousel';
import Typography from './Typography';

const ItemListWrapper = styled.div`
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
    max-width: 1333px;
    .rightChevron {
      padding-right: 50px;
      width: 200px;
      background: linear-gradient(
        270deg,
        #ffffff 12.5%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }
  .rightChevron {
    width: 200px;
    background: linear-gradient(
      270deg,
      #ffffff 12.5%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  .test {
    background: linear-gradient(
      270deg,
      #ffffff 12.5%,
      rgba(255, 255, 255, 0) 100%
    );
    transform: rotate(0.5turn);
  }

  margin-top: 42px;
  width: 1400px;
`;
const Image = styled.img`
  width: 263px;
  height: 381px;
  border: 2px solid #272727;
  border-radius: 26px;
`;
function ElementsList(props: any) {
  const { elementData, type } = props;
  let itemId: any;
  let itemType: any;
  const [activeItem, changeActiveItem] = useState(0);
  const elementList = elementData.map((item: any) => {
    if (type === 'modules/:id/courses') {
      itemType = `modules/${item.contentId}/courses`;
      itemId = item.lessons.sort((a: any, b: any) =>
        a.contentId > b.contentId ? 1 : -1
      )[0].contentId;
    } else {
      itemType = type;
      itemId = item.contentId;
    }
    return (
      <div key={item.contentId}>
        <NavLink to={`/${itemType}/${itemId}`}>
          <Image
            alt={item.title}
            src={item.imageFileKeyPreview || item.imageFileKey}
          />

          <Typography fz={21}>{item.title}</Typography>
        </NavLink>
      </div>
    );
  });

  return (
    <div>
      <ItemListWrapper>
        <ItemsCarousel
          numberOfCards={5}
          gutter={20}
          freeScrolling
          activePosition="center"
          chevronWidth={24}
          rightChevron={
            <div className="rightChevron">
              <svg
                width="29"
                height="455"
                viewBox="0 45 29 55"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 2L27 27.5L2 53"
                  stroke="#272727"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          }
          requestToChangeActive={changeActiveItem}
          activeItemIndex={activeItem}
          leftChevron={
            <div className="test">
              <svg
                width="29"
                height="500"
                viewBox="0 -55 29 55"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 2L27 27.5L2 53"
                  stroke="#272727"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          }
          outsideChevron={false}
        >
          {elementList}
        </ItemsCarousel>
      </ItemListWrapper>
    </div>
  );
}

export default ElementsList;
