function LikeIcon({ fill = '#C1C1C1' }: { fill?: string }) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1164_310)">
        <path
          d="M8 10.667H4C2.93913 10.667 1.92172 11.0884 1.17157 11.8386C0.421427 12.5887 0 13.6061 0 14.667L0 25.3337C0 26.3945 0.421427 27.4119 1.17157 28.1621C1.92172 28.9122 2.93913 29.3337 4 29.3337H8V10.667Z"
          fill={fill}
        />
        <path
          d="M18.6667 10.6663L19.4067 6.22898C19.5349 5.56771 19.4387 4.88258 19.1334 4.28215C18.8281 3.68173 18.3312 3.20036 17.7214 2.91432C17.022 2.60899 16.2324 2.58205 15.5139 2.839C14.7953 3.09595 14.2019 3.61742 13.8547 4.29698L10.6667 10.6663V29.333H29.3334L32 14.6663V10.6663H18.6667Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1164_310">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default LikeIcon;
