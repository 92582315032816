import styled from 'styled-components';
import {useEffect, useState} from "react";
import { NavLink } from 'react-router-dom';
import Button from './Button';
import PageTitle from './PageTitle';
import Divider from './Divider';
import { cookies } from '../../utils';
import {userInfoService} from "../../services";

import {UserData} from "../../models";

const Wrapper = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #000000;
  flex: none;
  order: 1;
  flex-grow: 0;
  width: 100%;
  max-width: 1552px;
  margin: 0 auto;
  padding: 72px 122px 166px;
  justify-content: center;
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
    padding: 122px 16px 16px;
    min-width: 1272px;
  }
  z-index: 99999;
`;

const BodyWrapper = styled.div`
  min-width: 606px;
  width: 70%;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #272727;
  margin: 0 auto;
  letter-spacing: -0.02em;
  padding: 72px 122px 166px;

  .changePassword {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 48px;
    cursor: pointer;
    color: #33cccc;
  }
  .button {
    margin-top: 64px;
  }
  .element {
    margin-bottom: 32px;
  }
  .info {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    margin-left: 20px;
    color: #272727;
  }
  .footer {
    width: 1150px;
    margin-top: 34px;
    display: grid;
    grid-template-columns: 50% 50%;
    .links {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 33px;
      text-decoration-line: underline;

      /* Accent */

      color: #33cccc;
    }
  }
`;

function AccManagement() {
  const [userData, setUserData] = useState<UserData>({
    dateBirth: 0,
    email: 'string',
    firstName: 'string',
    gender: 0,
    middleName: 'string',
    surname: 'string',
    playgroundGroup: 'string'
  });
  const [age, setAge] = useState<number>(0);
  useEffect(()=>{
    userInfoService.getUserInfo().then((e)=>{
      setUserData(e.data);
    });
  },[]);

  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const userDate = new Date(userData.dateBirth);
  const dobnow = new Date(today.getFullYear(), userDate.getMonth(), userDate.getDate());
    useEffect(()=>{
      setAge(today.getFullYear() - userDate.getFullYear());
      if (today < dobnow) {
        setAge(age-1);
      }
    },[userDate])

  return (
    <Wrapper>
      {' '}
      <PageTitle text="Личный кабинет" />
      <BodyWrapper>
        <div className="element">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.744 2.63395L21.272 7.52716C21.538 7.70006 21.671 7.78651 21.7674 7.90183C21.8527 8.00391 21.9167 8.12198 21.9558 8.24914C22 8.39278 22 8.55141 22 8.86867V16.2004C22 17.8805 22 18.7206 21.673 19.3623C21.3854 19.9268 20.9265 20.3858 20.362 20.6734C19.7202 21.0004 18.8802 21.0004 17.2 21.0004H6.8C5.11984 21.0004 4.27976 21.0004 3.63803 20.6734C3.07354 20.3858 2.6146 19.9268 2.32698 19.3623C2 18.7206 2 17.8805 2 16.2004V8.86867C2 8.55141 2 8.39278 2.04417 8.24914C2.08327 8.12198 2.14735 8.00391 2.23265 7.90183C2.32901 7.78651 2.46201 7.70006 2.72802 7.52716L10.256 2.63395M13.744 2.63395C13.1127 2.22364 12.7971 2.01848 12.457 1.93866C12.1564 1.8681 11.8436 1.8681 11.543 1.93866C11.2029 2.01848 10.8873 2.22364 10.256 2.63395M13.744 2.63395L19.9361 6.65886C20.624 7.10596 20.9679 7.32951 21.087 7.61301C21.1911 7.86076 21.1911 8.13998 21.087 8.38773C20.9679 8.67123 20.624 8.89478 19.9361 9.34188L13.744 13.3668C13.1127 13.7771 12.7971 13.9823 12.457 14.0621C12.1564 14.1326 11.8436 14.1326 11.543 14.0621C11.2029 13.9823 10.8873 13.7771 10.256 13.3668L4.06386 9.34188C3.37601 8.89478 3.03209 8.67123 2.91297 8.38773C2.80888 8.13998 2.80888 7.86076 2.91297 7.61301C3.03209 7.32951 3.37601 7.10596 4.06386 6.65886L10.256 2.63395M21.5 19.0004L14.8572 13.0004M9.14282 13.0004L2.5 19.0004"
              stroke="#33CCCC"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span className="info">{userData.email}</span>
        </div>
        <div className="element">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3 20C5.33579 17.5226 8.50702 16 12 16C15.493 16 18.6642 17.5226 21 20M16.5 7.5C16.5 9.98528 14.4853 12 12 12C9.51472 12 7.5 9.98528 7.5 7.5C7.5 5.01472 9.51472 3 12 3C14.4853 3 16.5 5.01472 16.5 7.5Z"
              stroke="#33CCCC"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span className="info">{userData.firstName} {userData.middleName} {userData.surname}</span>
        </div>
        <div className="element">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 14C8 14 9.5 16 12 16C14.5 16 16 14 16 14M15 9H15.01M9 9H9.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM15.5 9C15.5 9.27614 15.2761 9.5 15 9.5C14.7239 9.5 14.5 9.27614 14.5 9C14.5 8.72386 14.7239 8.5 15 8.5C15.2761 8.5 15.5 8.72386 15.5 9ZM9.5 9C9.5 9.27614 9.27614 9.5 9 9.5C8.72386 9.5 8.5 9.27614 8.5 9C8.5 8.72386 8.72386 8.5 9 8.5C9.27614 8.5 9.5 8.72386 9.5 9Z"
              stroke="#33CCCC"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <span className="info">{age} года, пол {userData.gender === 1 && 'женский'}
            {userData.gender === 0 && 'мужской'}</span>
        </div>
        <NavLink to="/changepassword"><div className="changePassword">Изменить пароль</div></NavLink>
        <Divider />
        <div className="footer">
          <div>
            <NavLink to="/terms">
              <div className="links">Прочитать пользовательское соглашение</div>
            </NavLink>
            <NavLink to="/politics">
            <div className="links">Политика конфиденциальности</div>
            </NavLink>
          </div>
          <div>
            <Button
              onClick={() => {
                cookies.remove('auth');
                window.location.assign('/login');
              }}
              text="Выйти"
            />
          </div>
        </div>
      </BodyWrapper>
    </Wrapper>
  );
}

export default AccManagement;
