import styled from 'styled-components';
import Typography from './Typography';

const MeditationWrapper = styled.div<{ borderColor: any }>`
  cursor: pointer;
  img {
    width: 411px;
    height: 233px;
    margin-bottom: 20px;
    border: ${(props) => props.borderColor};
    border-radius: 26px;
  }
  div {
    padding-left: 8px;
  }
`;

function Meditation({
  isWatched,
  src,
  description,
  onClick,
}: {
  isWatched?: boolean;
  src: string;
  description: string;
  onClick?: () => void;
}) {
  return (
    <MeditationWrapper
      borderColor={isWatched ? '6px solid #33CCCC' : '2px solid #272727'}
      onClick={onClick}
    >
      <img src={src} alt="meditationPicture" />
      <Typography fz={21} lh={27} fw={600}>
        {description}
      </Typography>
    </MeditationWrapper>
  );
}

export default Meditation;
