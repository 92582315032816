function FullScreenIcon({ onClick }: { onClick?: () => void }) {
  return (
    <svg
      onClick={onClick}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_900_2647)">
        <path
          d="M14.9999 10.333C14.7347 10.333 14.4803 10.4384 14.2928 10.6259C14.1053 10.8134 13.9999 11.0678 13.9999 11.333V12.333C13.9999 12.775 13.8243 13.199 13.5118 13.5115C13.1992 13.8241 12.7753 13.9997 12.3333 13.9997H11.3333C11.068 13.9997 10.8137 14.105 10.6261 14.2926C10.4386 14.4801 10.3333 14.7345 10.3333 14.9997C10.3333 15.2649 10.4386 15.5192 10.6261 15.7068C10.8137 15.8943 11.068 15.9997 11.3333 15.9997H12.3333C13.3054 15.9986 14.2374 15.612 14.9248 14.9246C15.6122 14.2372 15.9989 13.3051 15.9999 12.333V11.333C15.9999 11.0678 15.8946 10.8134 15.707 10.6259C15.5195 10.4384 15.2651 10.333 14.9999 10.333Z"
          fill="white"
        />
        <path
          d="M4.66667 0H3.66667C2.69453 0.00105878 1.76252 0.387707 1.07511 1.07511C0.387707 1.76252 0.00105878 2.69453 0 3.66667L0 4.66667C0 4.93188 0.105357 5.18624 0.292893 5.37377C0.48043 5.56131 0.734784 5.66667 1 5.66667C1.26522 5.66667 1.51957 5.56131 1.70711 5.37377C1.89464 5.18624 2 4.93188 2 4.66667V3.66667C2 3.22464 2.17559 2.80072 2.48816 2.48816C2.80072 2.17559 3.22464 2 3.66667 2H4.66667C4.93188 2 5.18624 1.89464 5.37377 1.70711C5.56131 1.51957 5.66667 1.26522 5.66667 1C5.66667 0.734784 5.56131 0.48043 5.37377 0.292893C5.18624 0.105357 4.93188 0 4.66667 0V0Z"
          fill="white"
        />
        <path
          d="M4.66667 13.9997H3.66667C3.22464 13.9997 2.80072 13.8241 2.48816 13.5115C2.17559 13.199 2 12.775 2 12.333V11.333C2 11.0678 1.89464 10.8134 1.70711 10.6259C1.51957 10.4384 1.26522 10.333 1 10.333C0.734784 10.333 0.48043 10.4384 0.292893 10.6259C0.105357 10.8134 0 11.0678 0 11.333L0 12.333C0.00105878 13.3051 0.387707 14.2372 1.07511 14.9246C1.76252 15.612 2.69453 15.9986 3.66667 15.9997H4.66667C4.93188 15.9997 5.18624 15.8943 5.37377 15.7068C5.56131 15.5192 5.66667 15.2649 5.66667 14.9997C5.66667 14.7345 5.56131 14.4801 5.37377 14.2926C5.18624 14.105 4.93188 13.9997 4.66667 13.9997Z"
          fill="white"
        />
        <path
          d="M12.3333 0H11.3333C11.068 0 10.8137 0.105357 10.6261 0.292893C10.4386 0.48043 10.3333 0.734784 10.3333 1C10.3333 1.26522 10.4386 1.51957 10.6261 1.70711C10.8137 1.89464 11.068 2 11.3333 2H12.3333C12.7753 2 13.1992 2.17559 13.5118 2.48816C13.8243 2.80072 13.9999 3.22464 13.9999 3.66667V4.66667C13.9999 4.93188 14.1053 5.18624 14.2928 5.37377C14.4803 5.56131 14.7347 5.66667 14.9999 5.66667C15.2651 5.66667 15.5195 5.56131 15.707 5.37377C15.8946 5.18624 15.9999 4.93188 15.9999 4.66667V3.66667C15.9989 2.69453 15.6122 1.76252 14.9248 1.07511C14.2374 0.387707 13.3054 0.00105878 12.3333 0V0Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_900_2647">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default FullScreenIcon;
