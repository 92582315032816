import styled from 'styled-components';
import { useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Button from './Button';
import { authService } from '../../services';

const Wrapper = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;

  line-height: 23px;
  letter-spacing: -0.02em;
  flex: none;
  order: 1;
  flex-grow: 0;
  display: table;
  margin: 0 auto;
  padding: 72px 122px 166px;

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
    padding: 122px 16px 16px;
    min-width: 1272px;
  }
  z-index: 99999;

  label {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 111%;
    letter-spacing: -0.01em;
    color: #272727;
  }
  .passChanged {
    div {
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 31px;
      /* identical to box height */

      text-align: center;
      letter-spacing: -0.01em;

      /* Body */

      color: #272727;
    }
  }
  .passwordInput {
    margin-top: 16px;
    margin-bottom: 16px;
    width: 385px;
    height: 71px;
    padding: 10px;
    font-size: 24px;
    border: 1px solid #33cccc;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .error {
    color: #ff4f4f;
    padding-left: 10px;

    margin-bottom: 32px;
  }
  Button {
    font-size: 32px;
    width: 385px;
    height: 71px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 24px 14px;
    margin-top: 48px;
    gap: 8px;
  }
`;

function ChangePassword() {
  const firstPass = useRef<any>();
  const secondPass = useRef<any>();
  const oldPass = useRef<any>();
  const [passError, setPassError] = useState('');
  const [passChanged, setPassChanded] = useState<boolean>(false);

  function passwordRegValidation() {
    if (firstPass.current.value !== secondPass.current.value) {
      setPassError('Пароли не совпадают');
    } else {
      setPassError('');
    }
    return true;
  }
  function pressEnterLogin() {}
  return (
    <Wrapper>
      {!passChanged && (
        <>
          <label htmlFor="oldPassword">
            Старый пароль
            <input
              ref={oldPass}
              onKeyUp={() => pressEnterLogin()}
              onChange={() => {
                passwordRegValidation();
              }}
              id="oldPassword"
              type="password"
              className="passwordInput"
            />
          </label>
          <label htmlFor="firstPassword">
            Придумайте пароль
            <input
              ref={firstPass}
              onKeyUp={() => pressEnterLogin()}
              onChange={() => {
                passwordRegValidation();
              }}
              id="firstPassword"
              type="password"
              className="passwordInput"
            />
          </label>
          {passError !== '' && <div className="error">{passError}</div>}
          <label htmlFor="secondPassword">
            Повторите пароль
            <input
              ref={secondPass}
              onKeyUp={() => pressEnterLogin()}
              onChange={() => {
                passwordRegValidation();
              }}
              id="secondPassword"
              type="password"
              className="passwordInput"
            />
          </label>
          {passError !== '' && <div className="error">{passError}</div>}
        </>
      )}

      {!passChanged && (
        <Button
          fontSize={32}
          onClick={() => {
            authService
              .changePassword(oldPass.current.value, firstPass.current.value)
              .then(() => {
                setPassChanded(true);
              });
          }}
          disabled={passError !== '' || !firstPass?.current || !oldPass.current}
          text="Сохранить"
        />
      )}
      {passChanged && (
        <>
          <div className="passChanged">
            <div>
              <svg
                width="168"
                height="168"
                viewBox="0 0 168 168"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M63 77L77 91L108.5 59.5M83.9521 35.9507C69.9566 19.5888 46.6182 15.1875 29.0829 30.1701C11.5475 45.1527 9.07877 70.2028 22.8494 87.9228C33.2506 101.307 62.799 128.177 76.6361 140.524C79.1797 142.794 80.4515 143.929 81.9408 144.376C83.2337 144.764 84.6704 144.764 85.9633 144.376C87.4526 143.929 88.7244 142.794 91.268 140.524C105.105 128.177 134.653 101.307 145.055 87.9228C158.825 70.2028 156.658 44.9951 138.821 30.1701C120.984 15.3451 97.9475 19.5888 83.9521 35.9507Z"
                  stroke="#33CCCC"
                  strokeWidth="8"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div>Пароль изменен</div>
          </div>
          <NavLink to="/">
            <Button fontSize={32} text="На главную" />
          </NavLink>
        </>
      )}
    </Wrapper>
  );
}

export default ChangePassword;
