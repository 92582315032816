import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TestItem, Question } from '../models';
import { testService } from '../services/testService';

const mappedColors: any = {
  orange: '#FDBF22',
  blue: '#3DB6FB',
  green: '#7633CC',
};

const useTestData = () => {
  const testId: any = useParams().id;

  const [testData, setTestData] = useState<TestItem>();
  const [questionsData, setQuestionsData] = useState<Question[]>([]);
  const [donutData, setDonutData] = useState([
    { count: 0, color: '#33cccc' },
    { count: 0, color: '#33cccc' },
    { count: 0, color: '#33cccc' },
  ]);

  useEffect(() => {
    testService.getTest(testId).then(({ data }) => {
      const {
        questions,
        title,
        category,
        imageFileKey,
        description,
        results,
        contentId,
        type: { name },
      } = data;
      setTestData({
        contentId,
        title,
        type: name,
        categoryName: category.name,
        imageFileKey,
        description,
        results,
      });
      const mappedQuestions = questions.map((q) => ({
        ...q,
        answers: q.answers.map((a) => ({
          ...a,
          color: mappedColors[a.color],
        })),
      }));

      const colors = Array.from(
        new Set(
          mappedQuestions
            .map((q) => q.answers.map((a) => a.color))
            .reduce((sum, acc) => [...sum, ...acc], [])
        )
      );
      setDonutData(
        colors.map((color) => ({
          count: 0,
          color,
        }))
      );

      setQuestionsData(
        mappedQuestions
          .sort((el, el2) => el.id - el2.id)
          .map((question) => {
            const {
              name: questionName,
              imageFileKey: questionImageFileKey,
              answers,
            } = question;

            return {
              name: questionName,
              imageFileKey: questionImageFileKey,
              answers: answers.map((el) => ({ ...el, selected: false })),
            };
          })
      );
    });
  }, [testId]);

  return {
    testData,
    donutData,
    setDonutData,
    questionsData,
    setQuestionsData,
  };
};

export default useTestData;
