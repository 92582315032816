const url = window.location.hostname;
let widget;

if(url === 'wellnessme.ru')
{
    widget = 'https://client.wellnessme.ru/'
} else if(url === 'testing-wellness.playground.sysdyn.ru')
{
    widget = 'https://testing-client.playground.sysdyn.ru/'
} else {
    widget = 'https://testing-client.playground.sysdyn.ru'
}



export const widgetUrl = widget;