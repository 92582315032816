import styled from 'styled-components';
import tutorialPic from '../../assets/images/tutorial.png';
import Divider from '../elements/Divider';
import Typography from '../elements/Typography';

const MainBlock = styled.div`
  width: 100%;
  max-width: 1752px;
  margin: 0 auto;
  padding: 72px 122px 166px;
  display: flex;
  justify-content: center;
`;
const Content = styled.div`
  width: 100%;
  max-width: 1272px;
`;

const TutorialText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 160%;
  display: flex;

  text-transform: uppercase;
  color: #c1c1c1;
`;

const TutorialPic = styled.img`
  margin-left: 339px;
  margin-top: 62px;
`;

const ArticleTitle = styled.div`
  width: 500px;
  margin-left: 339px;
  margin-top: 48px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 160%;
  color: #272727;
`;

const ArticleText = styled.div`
  width: 588px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  margin-top: 20px;
  margin-left: 339px;
  color: #272727;
`;

function TutorialPage() {
  return (
    <MainBlock>
      <Content>
        <TutorialText>ТУТОРИАЛ</TutorialText>
        <Typography width={798} fz={45} fw={400}>
          О проекте
        </Typography>
        <TutorialPic src={tutorialPic} />
        <ArticleTitle>Курсы</ArticleTitle>
        <div>
          <ArticleText>
            На сайте собраны бесплатные курсы, которые научат осознанности и
            гармонии в жизни. Всё просто и понятно — никаких скучных лекций.
          </ArticleText>
          <ArticleText>
            Каждая программа, разработанная экспертом, состоит из теории и
            практики. Все материалы можно читать, смотреть или слушать.
          </ArticleText>
          <ArticleText>
            Мы рекомендуем изучать не больше трёх курсов одновременно, но вы
            можете проходить столько программ, сколько захотите — главное, чтобы
            вам было комфортно, а процесс обучения был эффективен.
          </ArticleText>
        </div>
        <ArticleTitle>Статьи</ArticleTitle>
        <ArticleText>
          Мы ежедневно добавляем на сайт новые статьи. Каждую можно прочитать за
          1−3 минуты.
        </ArticleText>

        <ArticleText>
          Желаем вам интересной учёбы и развития полезных привычек!
          <Divider mt={64} />
        </ArticleText>
      </Content>
    </MainBlock>
  );
}
export default TutorialPage;
