import { useMemo } from 'react';
import styled from 'styled-components';

type Props = {
  a1: number;
  a2: number;
  a3: number;
  dashArray1: number[];
  dashArray2: number[];
  dashArray3: number[];
  getRotateZOne: number;
  getRotateZTwo: number;
  bigDonutRadius: number;
  bigDonutIndex: number;
  invisible: boolean;
};

const DonutWrapper = styled.div<Props>`
  opacity: ${(props: Props) => (props.invisible ? 0 : 1)};

  @keyframes percent1 {
    to {
      stroke-dasharray: ${(props: Props) =>
        `${props.dashArray1[0]} ${props.dashArray1[1]}`};
    }
  }

  @keyframes percent2 {
    to {
      stroke-dasharray: ${(props: Props) =>
        `${props.dashArray2[0]} ${props.dashArray2[1]}`};
    }
  }

  @keyframes percent3 {
    to {
      stroke-dasharray: ${(props: Props) =>
        `${props.dashArray3[0]} ${props.dashArray3[1]}`};
    }
  }

  width: 800px;
  height: 800px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(114%, 114%);
  }

  .donut {
    transform-origin: center center;
    transition: 0.5s ease-in-out;
    stroke-dashoffset: 0;
  }

  .donut:nth-child(1) {
    transform: rotateZ(-90deg);
    stroke-dasharray: ${(props: Props) =>
      `${props.dashArray1[0]} ${props.dashArray1[1]}`};
  }

  .donut:nth-child(2) {
    transform: ${(props: Props) => `rotateZ(${props.getRotateZOne}deg)`};
    stroke-dasharray: ${(props: Props) =>
      `${props.dashArray2[0]} ${props.dashArray2[1]}`};
  }

  .donut:nth-child(3) {
    transform: ${(props: Props) => `rotateZ(${props.getRotateZTwo}deg)`};
    stroke-dasharray: ${(props: Props) =>
      `${props.dashArray3[0]} ${props.dashArray3[1]}`};
  }
`;

type DonutProps = {
  data: {
    count: number;
    color: string;
  }[];
};

function Donut(props: DonutProps) {
  const { data } = props;

  const percents = useMemo(
    () => [
      ...data.map(
        (o) => (o.count / data.reduce((sum, acc) => sum + acc.count, 0)) * 100
      ),
    ],
    [data]
  );

  const biggestIndex = useMemo(() => {
    const biggestIdx = percents.findIndex((percent, percentIndex) =>
      percents.every(
        (el, index) =>
          el < percent || (percent === el && percentIndex === index)
      )
    );
    return biggestIdx !== -1 ? biggestIdx : -99;
  }, [percents]);

  const pi = 3.14159265359;
  const r1 = biggestIndex === 0 ? 270 : 300;
  const r2 = biggestIndex === 1 ? 270 : 300;
  const r3 = biggestIndex === 2 ? 270 : 300;

  const getDashArray1 = useMemo(
    () => [
      (2 * pi * r1 * percents[0]) / 100,
      2 * pi * r1 * (1 - percents[0] / 100),
    ],
    [percents, r1]
  );

  const getDashArray2 = useMemo(
    () => [
      (2 * pi * r2 * percents[1]) / 100,
      2 * pi * r2 * (1 - percents[1] / 100),
    ],
    [percents, r2]
  );

  const getDashArray3 = useMemo(
    () => [
      (2 * pi * r3 * percents[2]) / 100,
      2 * pi * r3 * (1 - percents[2] / 100),
    ],
    [percents, r3]
  );

  const getRotateZOne = useMemo(
    () => -90 + (percents[0] * 360) / 100,
    [percents]
  );

  const getRotateZTwo = useMemo(
    () => -90 + (percents[0] * 360) / 100 + (percents[1] * 360) / 100,
    [percents]
  );

  return (
    <DonutWrapper
      a1={percents[0]}
      a2={percents[1]}
      a3={percents[2]}
      dashArray1={getDashArray1}
      dashArray2={getDashArray2}
      dashArray3={getDashArray3}
      getRotateZOne={getRotateZOne}
      getRotateZTwo={getRotateZTwo}
      bigDonutIndex={biggestIndex}
      bigDonutRadius={270}
      invisible={percents.every((el) => el === 0)}
    >
      <svg xmlns="http://www.w3.org/2000/svg">
        {data.map((circleEl, index) => (
          <circle
            key={index}
            cx="400"
            cy="400"
            className="donut"
            r={index === biggestIndex ? '270' : '300'}
            strokeWidth={index === biggestIndex ? '160' : '100'}
            stroke={circleEl.color}
            fill="none"
          />
        ))}
      </svg>
    </DonutWrapper>
  );
}

export default Donut;
