import styled from 'styled-components';

const ControlButton = styled.div`
  cursor: pointer;
  min-width: 36px;
  min-height: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default ControlButton;
