import api from '../api';
import { CoursesListItem, CourseType, ModulesListItem } from '../models';

export const courseService = {
  getCourses: (pageSize: number) =>
    api.get<CoursesListItem[]>(
      `content/courses/all?page=1&page_size=${pageSize}`
    ),
  getCategory: () => api.get<any[]>(`content/course/categories`),
  getModules: (courseId: number) =>
    api.get<ModulesListItem[]>(`content/modules/course/${courseId}`),
  getLesson: (contentId: any) =>
    api.get<CourseType>(`content/lessons/${contentId}`),
  getModulesByContentId: (contentId: number) =>
    api.get<any>(`content/modules/${contentId}`),
  getAllModules: (activeCategory: string) =>
    api.get<any>(
      `content/modules/all?course_id=${activeCategory}&page=1&page_size=20`
    ),
};
