import styled from 'styled-components';
import NavBar from './NavBar';

const TitleDiv = styled.div`
  font-family: 'SB Sans Display';
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 27px;
  letter-spacing: -0.02em;
  color: #272727;
`;
const FooterText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.02em;
  color: #33cccc;
`;
const Gamelab = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 15px;
  letter-spacing: -0.02em;
  color: #33cccc;
`;
const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 151px;
  max-width: 1512px;
  margin: 0 auto;
  padding-left: 124px;
  padding-right: 124px;
  &.hidden {
    display: none;
  }
`;

function Footer() {
  return (
    <FooterWrapper className="footer-wrapper">
      <div>
        <TitleDiv>
          With love from <Gamelab>Gamelab</Gamelab>
        </TitleDiv>
        <FooterText>
          Nature animated icons created by Freepik — Flaticon
        </FooterText>
      </div>
      <NavBar />
    </FooterWrapper>
  );
}

export default Footer;
