import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import { cookies } from '../../utils';
import {widgetUrl} from "../../api/WidgetUrl";

const HeaderWrapper = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 99999;
  element::-webkit-scrollbar {
    display: none;
  }
  element {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
  }
`;
const CloseButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 50%;
  top: 25px;
  left: 480px;
`;
const WidgetWrapper = styled.div<{ position: number }>`
  margin-left: ${(props) => props.position}px;
  transition: 450ms;
`;
function Widget(props: any) {
  const Authorized = cookies.get<string>('auth');
  const [position, setPosition] = useState(-458);
  const { setOpen } = props;
  const iFrameRef = useRef<any>();
  const token = {
    token: Authorized,
  };
  function sendToken() {
    iFrameRef.current.contentWindow.postMessage(token, '*');
  }
  useEffect(() => {
    setPosition(0);
  }, []);

  return (
    <HeaderWrapper>
      <WidgetWrapper position={position}>
        <CloseButton
          onClick={() => {
            setPosition(-458);
            document.body.style.overflow = 'auto';
            setTimeout(() => setOpen(false), 450);
          }}
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.04289 11.5429C0.652369 11.9334 0.652369 12.5666 1.04289 12.9571C1.43342 13.3476 2.06658 13.3476 2.45711 12.9571L1.04289 11.5429ZM2.45711 1.04289C2.06658 0.652368 1.43342 0.652368 1.04289 1.04289C0.652368 1.43342 0.652368 2.06658 1.04289 2.45711L2.45711 1.04289ZM12.9571 2.45711C13.3476 2.06658 13.3476 1.43342 12.9571 1.04289C12.5666 0.652368 11.9334 0.652368 11.5429 1.04289L12.9571 2.45711ZM11.5429 12.9571C11.9334 13.3476 12.5666 13.3476 12.9571 12.9571C13.3476 12.5666 13.3476 11.9334 12.9571 11.5429L11.5429 12.9571ZM2.45711 12.9571L7.70711 7.70711L6.29289 6.29289L1.04289 11.5429L2.45711 12.9571ZM7.70711 6.29289L2.45711 1.04289L1.04289 2.45711L6.29289 7.70711L7.70711 6.29289ZM7.70711 7.70711L12.9571 2.45711L11.5429 1.04289L6.29289 6.29289L7.70711 7.70711ZM6.29289 7.70711L11.5429 12.9571L12.9571 11.5429L7.70711 6.29289L6.29289 7.70711Z"
              fill="#212121"
            />
          </svg>
        </CloseButton>
        <iframe
          onLoad={sendToken}
          ref={iFrameRef}
          allow="autoplay"
          style={{
            border: 'none',
            overflow: 'hidden',
            width: '100vw',
            height: '100vh',
          }}
          title="playground"
          src={widgetUrl}
        />
      </WidgetWrapper>
    </HeaderWrapper>
  );
}

export default Widget;
