import api from '../api';

export const authService = {
  registerUser: (loginValue: string, passwordValue: string) =>
    api.post('auth/signup', {
      firstName: 'string',
      login: loginValue,
      middleName: 'string',
      password: passwordValue,
      surname: 'string',
    }),
  loginUser: (loginValue: string, passwordValue: string) =>
    api.post('auth/signin', {
      login: loginValue,

      password: passwordValue,
    }),
    changePassword: (oldPassword: string, newPassword: string) =>
        api.post(`http://185.137.234.109:6574/api/v1/users/lk/update-password`,{
            newPassword,
            oldPassword
        }),
    sendResetLink: (email:string)=>
        api.post(`users/reset-password?email=${email}`)
};
