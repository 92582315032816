import styled from 'styled-components';
import Typography from './Typography';

type Props = {
  text: string;
  onClick?: () => void;
  disabled?: boolean;
  fontSize?: number;
};

const ButtonWrapper = styled.button`
  display: inline-flex;
  padding: 12px 18px;
  height: 50px;
  background-color: ${(props) => (props.disabled ? '#C1C1C1' : '#33cccc')};
  border-radius: 12px;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  div {
    margin-top: 1px;
  }
`;

function Button(props: Props) {
  const { text, onClick, disabled, fontSize } = props;
  return (
    <ButtonWrapper type="submit" disabled={disabled} onClick={onClick}>
      <Typography fz={fontSize || 20} lh={26} color="#fff">
        {text}
      </Typography>
    </ButtonWrapper>
  );
}

export default Button;
