import styled from 'styled-components';
import { useState } from 'react';
import CompletedIcon from './icons/CompletedIcon';
import Typography from './Typography';
// @ts-ignore
import preloaderImg from '../../assets/icons/1484.gif';

const ExerciseWrapper = styled.div`
  position: relative;
  img {
    cursor: pointer;
    width: 304px;
    height: 198px;
    margin-bottom: 8px;
    border: 2px solid #272727;
    border-radius: 26px;
  }
`;

const CompletedIconBox = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  right: 20px;
  bottom: 40px;
  padding-left: 0;
`;

function Exercise({
  src,
  description,
  isWatched,
  onClick,
}: {
  src: string;
  description: string;
  isWatched: boolean;
  onClick?: () => void;
}) {
  const [loaded, setLoaded] = useState(false);

  return (
    <ExerciseWrapper onClick={onClick}>
      <img
        onLoad={() => setLoaded(true)}
        alt="111"
        src={loaded ? src : preloaderImg}
      />
      <Typography pl={8} fz={16} lh={21}>
        {description}
      </Typography>
      {isWatched && (
        <CompletedIconBox>
          <CompletedIcon />
        </CompletedIconBox>
      )}
    </ExerciseWrapper>
  );
}

export default Exercise;
