import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import styled from 'styled-components';
import Footer from '../components/elements/Footer';
import Header from '../components/elements/Header';
import MainPage from '../components/pages/MainPage';
import ArticlesPage from '../components/pages/ArticlesPage';
import ArticlePage from '../components/pages/ArticlePage';
import CoursesPage from '../components/pages/CoursesPage';
import CoursePage from '../components/pages/CoursePage';
import MediaPage from '../components/pages/MediaPage';
import TutorialPage from '../components/pages/TutorialPage';
import ModulesPage from '../components/pages/ModulesPage';
import MeditationPage from '../components/pages/MeditationPage';
import FitnessPage from '../components/pages/FitnessPage';
import TestPage from '../components/pages/TestPage';
import LoginPage from '../components/pages/LoginPage';
import { cookies } from '../utils';
import Terms from '../components/elements/Terms';
import PasswordReset from '../components/elements/PasswordReset';
import SendResetLink from '../components/elements/SendResetLink';
import AccManagement from '../components/elements/AccManagement';
import Politics from '../components/elements/Politics';
import ChangePassword from '../components/elements/ChangePassword';

const HeaderWarpper = styled.div`
  //box-shadow: 0 2px 24px 0 rgb(0 0 0 / 35%);
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 99999;
  width: 100%;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
`;
const BodyWrapper = styled.div`
  margin-top: 100px;
`;
const Authorized = cookies.get<string>('auth');

const useRoutes = () => (
  <BrowserRouter>
    <HeaderWarpper>{Authorized && <Header />}</HeaderWarpper>

    <BodyWrapper>
      <Routes>
        <Route path="*" element={<Navigate to="/" replace />} />
        <Route path="/" element={<MainPage />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="passwordreset" element={<PasswordReset />} />
        <Route path="changepassword" element={<ChangePassword />} />
        <Route path="politics" element={<Politics />} />
        <Route path="lk" element={<AccManagement />} />
        <Route path="sendlink" element={<SendResetLink />} />
        <Route path="articles" element={<ArticlesPage />} />
        <Route path="terms" element={<Terms />} />
        <Route path="articles/:id" element={<ArticlePage />} />
        <Route path="courses" element={<CoursesPage />} />
        <Route path="modules/:id/courses/:Lessonid" element={<CoursePage />} />
        <Route path="media" element={<MediaPage />} />
        <Route path="media/:id" element={<MeditationPage />} />
        <Route path="media-fitness/:id" element={<FitnessPage />} />
        <Route path="modules/:id" element={<ModulesPage />} />
        <Route path="tutorial" element={<TutorialPage />} />
        <Route
          path="modules/:moduleid/courses/:lessonId/test/:id"
          element={<TestPage />}
        />
        <Route path="test/:id" element={<TestPage />} />
        {/* <Route path="dungeonmaster" element={<DungeonMaster />} />  */}
      </Routes>
    </BodyWrapper>
    {Authorized && <Footer />}
  </BrowserRouter>
);

export default useRoutes;
