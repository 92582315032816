import axios, { AxiosInstance } from 'axios';
import { cookies } from '../utils';

const auth = cookies.get('auth');
const url = window.location.hostname;
let API_URL: string;

if (url === 'wellnessme.ru') {
  API_URL = 'https://client.wellnessme.ru/api/v1/';
} else {
  API_URL = 'https://client.playground.sysdyn.ru/api/v1/';
}

const apiWidget: AxiosInstance = axios.create({
  baseURL: API_URL,
  responseType: 'json',
  withCredentials: true,
  headers: {
    Authorization: `Bearer ${auth}`,
  },
});

export const notificationService = {
  getNotify: () => apiWidget.get('notification'),
};
