import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Typography from '../elements/Typography';
import LikesBlock from '../elements/LikesBlock';
import Divider from '../elements/Divider';
import {
  courseService,
  actionService,
  userEventsService,
} from '../../services';
import PageTitle from '../elements/PageTitle';
import { CourseType, Actions } from '../../models';
import CourseControls from '../elements/CourseControls';
import CustomVideoPlayer from '../elements/VideoPlayer';

const PageWrapper = styled.div`
  width: 100%;
  max-width: 1752px;
  margin: 0 auto;
  padding: 72px 122px 166px;
  display: flex;
  justify-content: center;
`;
const Content = styled.div`
  width: 100%;
  max-width: 1272px;
`;

const Article = styled.div`
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  color: #c1c1c1;
`;
const MainBlock = styled.div`
  img {
    border-radius: 26px;
  }
  div {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #272727;
  }
  width: 588px;
  margin-left: 38px;
`;

const MainDiv = styled.div`
  padding-top: 64px;
  width: 100%;
  display: grid;
  grid-template-columns: 0.5fr 2fr;
`;
const LikeWrapper = styled.div`
  position: sticky;
  top: 368px;
  width: fit-content;
`;
const InfoBlock = styled.div`
  width: 250px;
  padding-right: 56px;
`;

const ProgressBarDiagram = styled.span<{
  rightDegrees: number;
  leftDegrees: number;
  color: string;
}>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 42px;
  .diagram {
    object-fit: contain;
    width: 64px;
    height: 64px;

    border-radius: 50%;
    background: #33cccc;
    position: relative;
    overflow: hidden;
  }
  .diagram::before {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    background: #ffffff;
    z-index: 3;
    opacity: 1;
  }
  .diagram .piece {
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    overflow: hidden;
    position: absolute;
  }
  .diagram .piece::before {
    content: '';
    display: block;
    position: absolute;
    width: 50%;
    height: 100%;
  }
  .diagram .piece.left {
    transform: rotate(0deg);
    z-index: 2;
    border-radius: 50%; /* only FireFox < 75.0v (fix bug)*/
  }
  .diagram.over_50 .piece.left {
    transform: rotate(${(props) => props.leftDegrees}deg);
  }
  .diagram .piece.right {
    transform: rotate(${(props) => props.rightDegrees}deg);
    z-index: 1;
    border-radius: 50%; /* only FireFox < 75.0v (fix bug)*/
  }
  .diagram.over_50 .piece.right {
    transform: rotate(360deg);
  }
  .diagram .left::before {
    margin: 0;

    background: black;
  }
  .diagram.over_50 .left::before {
    background: #33cccc;
  }
  .diagram .right::before {
    background: ${(props) => props.color};
  }
  .diagram .text {
    position: absolute;
    z-index: 3;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .diagram .text b {
    font-size: 72px;
  }
  .diagram .text span {
    font-size: 16px;
    display: block;
  }
`;

function CoursePage() {
  const [courseName, setCourseName] = useState('');
  const [lessonData, setLessonData] = useState<CourseType>();
  const [isLiked, setIsLiked] = useState<boolean>(false);
  const [moduleName, setModuleName] = useState('');
  const lessonId: any = useParams().Lessonid;
  const moduleId: any = useParams().id;
  const [watched, setWatched] = useState(false);
  const [actions, setLikes] = useState<Actions>();
  const [workouts, setWorkouts] = useState<{
    prev: { link: string; text: string } | null;
    next: { link: string; text: string } | null;
  }>({ prev: null, next: null });
  let rightDegrees;
  let leftDegrees = 0;

  const [percent, setPercent] = useState(0);
  const getLikes = () => {
    actionService.getActions(lessonId).then((response: any) => {
      setLikes(response.data);
    });
  };

  useEffect(() => {
    courseService.getLesson(lessonId).then((response: any) => {
      setLessonData(response.data);
      if(watched===false)
      {
        actionService.setWatched(response.data.contentId, 'LESSON').then(()=>{
          setWatched(true);
        });
      }

      getLikes();
    });
    actionService.getIsLike(lessonId).then((e) => {
      setIsLiked(e.data);
    });
    if (lessonId) {
      courseService.getModulesByContentId(moduleId).then((response: any) => {
        setCourseName(response.data.course.category.name);
        setModuleName(response.data.title);
        const lessonsData = response.data.lessons.sort(
          (a: any, b: any) => a.id - b.id
        );
        const currentIndex = lessonsData.findIndex(
          (el: any) => el.contentId === +lessonId
        );
        let prevLink = '';
        if (lessonsData[currentIndex - 1]) {
          if (lessonsData[currentIndex - 1].contentType === 'TEST') {
            prevLink = `/modules/${moduleId}/courses/${
              lessonsData[currentIndex - 1].contentId
            }/test/${lessonsData[currentIndex - 1].test.contentId}`;
          } else {
            prevLink = `/modules/${moduleId}/courses/${
              lessonsData[currentIndex - 1].contentId
            }`;
          }
        }
        const prev = lessonsData[currentIndex - 1]
          ? {
              link: prevLink,
              text: lessonsData[currentIndex - 1].title,
            }
          : null;
        let nextLink = '';
        if (lessonsData[currentIndex + 1]) {
          if (lessonsData[currentIndex + 1].contentType === 'TEST') {
            nextLink = `/modules/${moduleId}/courses/${
              lessonsData[currentIndex + 1].contentId
            }/test/${lessonsData[currentIndex + 1].test.contentId}`;
          } else {
            nextLink = `/modules/${moduleId}/courses/${
              lessonsData[currentIndex + 1].contentId
            }`;
          }
        }

        const next = lessonsData[currentIndex + 1]
          ? {
              link: nextLink,
              text: lessonsData[currentIndex + 1].title,
            }
          : null;
        setWorkouts({ prev, next });
      });
    }
  }, [lessonId,watched]);

  useEffect(() => {
    if (
      moduleName === 'Как восстановиться, не выезжая из города' &&
      workouts.next === null
    ) {
      userEventsService('COMPLETE_COURSE_HOW_RECOVER_WITHOUT_LEAVING_CITY');
      userEventsService('COMPLETE_N_COURSES');
    }

    if (moduleName === 'Как включиться в свою жизнь') {
      if (
        lessonData?.level === 1 &&
        lessonData.title === 'Подводим итоги первого уровня курса'
      ) {
        userEventsService('COMPLETE_COURSE_HOW_ENGAGE_YOUR_LIFE_LEVEL_1');
        userEventsService('COMPLETE_N_COURSES');
      }

      if (
        lessonData?.level === 2 &&
        lessonData.title === 'Подводим итоги второго уровня курса'
      ) {
        userEventsService('COMPLETE_COURSE_HOW_ENGAGE_YOUR_LIFE_LEVEL_2');
        userEventsService('COMPLETE_N_COURSES');
      }

      if (
        lessonData?.level === 3 &&
        lessonData.title === 'Подводим итоги третьего уровня курса'
      ) {
        userEventsService('COMPLETE_COURSE_HOW_ENGAGE_YOUR_LIFE_LEVEL_3');
        userEventsService('COMPLETE_N_COURSES');
      }
    }

    if (moduleName === 'Медитации: теория и лучшие практики') {
      if (
        lessonData?.level === 1 &&
        lessonData.title === 'Подводим итоги первого уровня курса'
      ) {
        userEventsService(
          'COMPLETE_MEDITATIONS_THEORY_AND_BEST_PRACTICES_LEVEL_1'
        );
        userEventsService('COMPLETE_N_COURSES');
      }

      if (
        lessonData?.level === 2 &&
        lessonData.title === 'Подводим итоги второго уровня курса'
      ) {
        userEventsService(
          'COMPLETE_MEDITATIONS_THEORY_AND_BEST_PRACTICES_LEVEL_2'
        );
        userEventsService('COMPLETE_N_COURSES');
      }

      if (
        lessonData?.level === 3 &&
        lessonData.title === 'Подводим итоги третьего уровня курса'
      ) {
        userEventsService(
          'COMPLETE_MEDITATIONS_THEORY_AND_BEST_PRACTICES_LEVEL_3'
        );
        userEventsService('COMPLETE_N_COURSES');
      }
    }

    if (moduleName === 'Здоровое и сильное тело') {
      if (
        lessonData?.level === 1 &&
        lessonData.title === 'Подводим итоги первого уровня фитнес-курса'
      ) {
        userEventsService(
          'COMPLETE_FITNESS_COURSE_HEALTHY_AND_STRONG_BODY_LEVEL_1'
        );
        userEventsService('COMPLETE_N_COURSES');
      }

      if (
        lessonData?.level === 2 &&
        lessonData.title === 'Подводим итоги второго уровня фитнес-курса'
      ) {
        userEventsService(
          'COMPLETE_FITNESS_COURSE_HEALTHY_AND_STRONG_BODY_LEVEL_2'
        );
        userEventsService('COMPLETE_N_COURSES');
      }

      if (
        lessonData?.level === 3 &&
        lessonData.title === 'Подводим итоги третьего уровня фитнес-курса'
      ) {
        userEventsService(
          'COMPLETE_FITNESS_COURSE_HEALTHY_AND_STRONG_BODY_LEVEL_3'
        );
        userEventsService('COMPLETE_N_COURSES');
      }
    }

    if (moduleName === 'Как питаться сбалансированно: практический курс') {
      if (lessonData?.title === 'Подводим итоги первой части курса') {
        userEventsService('COMPLETE_COURSE_HOW_EAT_BALANCED_1');
        userEventsService('COMPLETE_N_COURSES');
      }

      if (lessonData?.title === 'Подводим итоги курса') {
        userEventsService('COMPLETE_COURSE_HOW_EAT_BALANCED_2');
        userEventsService('COMPLETE_N_COURSES');
      }
    }

    if (moduleName === 'Как начать высыпаться' && workouts.next === null) {
      userEventsService('COMPLETE_COURSE_HOW_START_SLEEPING');
      userEventsService('COMPLETE_N_COURSES');
    }

    if (
      moduleName === 'Как научиться принимать и ценить свое Я' &&
      workouts.next === null
    ) {
      userEventsService('COMPLETE_COURSE_HOW_ACCEPT_AND_APPRECIATE_YOUR_SELF');
      userEventsService('COMPLETE_N_COURSES');
    }
  }, [moduleName, workouts.next, lessonData]);

  const getSelectedAction = () => {
    if (actions?.currentUserLiked) return 'like';
    if (actions?.currentUserDisliked) return 'dislike';
    return undefined;
  };

  const handleLikeClick = () => {
    // @ts-ignore
    window.ym(90053722, 'reachGoal', 'setLike');
    actionService.setLike(lessonId).then(() => {
      getLikes();
      if (!getSelectedAction() && !isLiked) {
        setIsLiked(true);
        userEventsService('RATE_N_LESSON');
        userEventsService('RATE_COURSE_LESSON');
      }
    });
  };
  const handleDislikeClick = () => {
    actionService.setDislike(lessonId).then(() => {
      getLikes();
    });
  };
  useEffect(() => {
    if (lessonData) {
      setPercent(
        Math.ceil(
          (lessonData.numberLessonsCompleted * 100) / lessonData.numberLessons
        )
      );
    }
  }, [setPercent, lessonData]);

  if (percent > 50) {
    rightDegrees = (360 * 50) / 100 + 180;
    leftDegrees = (360 * percent) / 100;
  } else {
    rightDegrees = (360 * percent) / 100 + 180;
  }
  const diagramBox = document.querySelectorAll('.diagram.progress');
  diagramBox.forEach((box) => {
    if (percent > 50) {
      box.classList.add('over_50');
    } else {
      box.classList.remove('over_50');
    }
  });
  // @ts-ignore
  window.ym(90053722, 'reachGoal', 'openLesson');
  const handlerScrollUp = () => {
    if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
      window.scrollBy(0, -50);
      setTimeout(handlerScrollUp, 10);
    }
  };

  function isValidImg() {
    const isThereImgDuplicate = lessonData?.text.includes(
      lessonData.imageFileKey
    );
    const imageFileKey = lessonData?.imageFileKey;
    const isWrongImgKey =
      imageFileKey === 'https://static.playground.sysdyn.ru/wellness/' ||
      imageFileKey === 'https://static.wellnessme.ru/wellness/';

    return !(isThereImgDuplicate || isWrongImgKey);
  }

  return lessonData && actions ? (
    <PageWrapper>
      <Content>
        <Article>
          Курс / {courseName}/ {moduleName}{' '}
        </Article>
        <PageTitle text={lessonData.title} />
        <MainDiv>
          <InfoBlock>
            <ProgressBarDiagram
              color={percent === 100 ? '33cccc' : 'black'}
              leftDegrees={leftDegrees}
              rightDegrees={rightDegrees}
            >
              <div>
                <div className="diagram progress">
                  <div className="piece left" />
                  <div className="piece right" />
                  <div className="text">{percent}%</div>
                </div>
              </div>
              <div>
                <Typography mt={10} fz={16}>
                  Пройдено
                </Typography>
                <div>
                  {lessonData.numberLessonsCompleted} из{' '}
                  {lessonData.numberLessons}
                </div>
              </div>
            </ProgressBarDiagram>
            <LikeWrapper>
              <LikesBlock
                dislikeClickHandler={handleDislikeClick}
                likeClickHandler={handleLikeClick}
                selected={getSelectedAction()}
                likes={actions.likes}
                dislikes={actions.dislikes}
              />
            </LikeWrapper>
          </InfoBlock>
          <MainBlock>
            <div>
              {isValidImg() && (
                <img alt="Картинка статьи" src={lessonData.imageFileKey} />
              )}

              <Typography fz={16} mt={!isValidImg() ? 0 : 48} mb={64} fw={400}>
                <div dangerouslySetInnerHTML={{ __html: lessonData.text }} />
                {lessonData.videoFileKey && (
                  <CustomVideoPlayer video={lessonData.videoFileKey} />
                )}
              </Typography>
              <Divider mb={64} />
              <div aria-hidden onClick={()=>{handlerScrollUp(); setWatched(false)}}>
                <CourseControls workouts={workouts} />
              </div>
            </div>
          </MainBlock>
        </MainDiv>
      </Content>
    </PageWrapper>
  ) : null;
}

export default CoursePage;
