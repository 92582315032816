import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import ItemList from '../elements/ItemList';
import api from '../../api';
import Preloader from '../elements/Preloader';
import { articleService } from '../../services';
import PageTitle from '../elements/PageTitle';
import { CategoriesType } from '../../models';

const Filter = styled.span<{ opacity: number }>`
  padding-right: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 27px;
  letter-spacing: -0.02em;
  color: #272727;
  opacity: ${(props) => props.opacity};
  cursor: pointer;
`;

const Image = styled.img`
  width: 416px;
  height: 283px;
  border: 2px solid #272727;
  border-radius: 26px;
  margin-bottom: 10px;
`;

const ArticleTitle = styled.div`
  text-decoration: none;
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 27px;
  margin-bottom: 40px;
  letter-spacing: -0.02em;
  color: #272727;
  margin-left: 10px;
`;

const MainDiv = styled.div`
  width: 100%;
  max-width: 1752px;
  margin: 0 auto;
  padding: 72px 122px 166px;
  display: flex;
  justify-content: center;
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
    padding: 122px 16px 16px;
    min-width: 1272px;
  }
`;

const ArticleBlock = styled.div`
  width: 100%;
  max-width: 1272px;
`;

const Pagination = styled.span<{ fontWeight: number }>`
  font-weight: ${(props) => props.fontWeight};
  margin-right: 5px;
  font-size: 20px;
  cursor: pointer;
`;

const CategoryWrapper = styled.div`
  padding-top: 32px;
  padding-bottom: 61px;
`;

function ArticlesPage() {
  // @ts-ignore
  window.ym(90053722, 'reachGoal', 'openAllArticles');
  const [preloader, setPreloader] = useState(true);
  const [articlesData, setArticlesData] = useState([]);
  const pageSize = 9;
  const [totalArticlesCount, setArticlesCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const pageCount = Math.ceil(totalArticlesCount / pageSize);
  const [activeCategory, setActiveCategory] = useState('Фитнес');
  const [catId, setCatId] = useState('');
  const [categories, setCategories] = useState<CategoriesType>();
  const pages = Array.from({ length: pageCount }, (_, i) => i + 1);
  const setFilter = (cat: any) => {
    if (cat.name === activeCategory) {
      setPageNumber(1);
      articleService
        .getArticles(1, '', 9)
        .then((response: any) => {
          setActiveCategory('');
          // @ts-ignore
          setCatId('');
          setArticlesData(response.data.content);
          setArticlesCount(response.data.totalElements);
        })
        .finally(() => setPreloader(false));
    } else {
      articleService
        .getArticles(1, cat.id, 9)
        .then((response: any) => {
          setActiveCategory(cat.name);
          setArticlesData(response.data.content);
          setArticlesCount(response.data.totalElements);
        })
        .finally(() => setPreloader(false));
    }
  };
  useEffect(() => {
    articleService.getCategory().then((response: any) => {
      setCategories(response.data);
    });
    articleService
      .getArticles(pageNumber, '1', 9)
      .then((response: any) => {
        setArticlesData(response.data.content);
        setArticlesCount(response.data.totalElements);
      })
      .finally(() => setPreloader(false));
  }, []);
  const categoryElements = categories?.map((item: any, index: number) => (
    <Filter
      opacity={activeCategory === item.name ? 1 : 0.2}
      key={index}
      onClick={() => {
        setPreloader(true);
        setCatId(String(item.id));
        setFilter(item);
      }}
    >
      {item.name}
    </Filter>
  ));

  return (
    <div>
      {preloader && (
        <div>
          <Preloader />
        </div>
      )}
      {!preloader && (
        <MainDiv>
          <ArticleBlock>
            <PageTitle text="Статьи" />
            <CategoryWrapper>{categoryElements}</CategoryWrapper>
            <ItemList cols={3} colGap={19} rowGap={56} mb={120} itemWidth={411}>
              {articlesData.map((article: any) => (
                <NavLink
                  key={article.contentId}
                  to={`/articles/${article.contentId}`}
                >
                  <span>
                    <span key={article.contentId} className="col-4">
                      <Image src={article.imageFileKey} />
                    </span>
                    <ArticleTitle>{article.title}</ArticleTitle>
                  </span>
                </NavLink>
              ))}
            </ItemList>

            {pages.map((page: any, index: number) => (
              <Pagination
                key={index}
                onClick={() => {
                  setPreloader(true);
                  setPageNumber(page);
                  api
                    .get(
                      `content/articles/all?category_id=${catId}&page=${page}&page_size=${pageSize}`
                    )
                    .then((response: any) => {
                      setArticlesData(response.data.content);
                      setArticlesCount(response.data.totalElements);
                    })
                    .finally(() => setPreloader(false));
                }}
                fontWeight={page === pageNumber ? 600 : 400}
              >
                {pages.length > 1 && page}
              </Pagination>
            ))}
          </ArticleBlock>
        </MainDiv>
      )}
    </div>
  );
}

export default ArticlesPage;
