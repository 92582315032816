import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useEffect, useState, useCallback } from 'react';
import Preloader from '../elements/Preloader';
import LikesBlock from '../elements/LikesBlock';
import Divider from '../elements/Divider';
import {
  articleService,
  actionService,
  userEventsService,
} from '../../services';
import { ArticleType, Actions } from '../../models';
import PageTitle from '../elements/PageTitle';

const Article = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 160%;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #c1c1c1;
`;

const Image = styled.img`
  margin-bottom: 48px;
  width: 579px;
  height: 325px;
  border-radius: 26px;
`;

const MainDiv = styled.div`
  width: 100%;
  max-width: 1497px;
  margin: 0 auto;
  padding: 72px 122px 166px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

const ArticleBlock = styled.div`
  div {
    width: 588px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #272727;
  }

  a {
    color: royalblue;
  }

  a:visited {
    color: #ffa4b3;
  }
  margin-bottom: 64px;
`;

const ArticleContent = styled.div`
  width: 600px;
  align-self: center;
  margin-top: -61px;
`;

const ArticleHeaderWrapper = styled.div`
  max-width: 660px;
`;

const LikesBlockWrapper = styled.div`
  position: sticky;
  top: 368px;
  width: fit-content;
`;

const PageTitleWrapper = styled.div`
  min-height: 118px;
  margin-bottom: 62px;
`;

function ArticlePage() {
  const [preloader, setPreloader] = useState(true);
  const [articleState, setArticleState] = useState<ArticleType>();
  const [actions, setActions] = useState<Actions>();
  const [isLiked, setIsLiked] = useState<boolean>(false);

  const articleId: any = useParams().id;
  const getActions = useCallback(() => {
    actionService
      .getActions(articleId)
      .then((response: any) => {
        setActions(response.data);
      })
      .finally(() => {
        setPreloader(false);
      });
  }, [articleId]);

  useEffect(() => {
    actionService.getIsLike(articleId).then((e) => {
      setIsLiked(e.data);
    });
    articleService.getArtilce(articleId).then((response: any) => {
      setArticleState(response.data);
      actionService.setWatched(response.data.contentId, 'ARTICLE');
      getActions();
      userEventsService('READ_NEW_ARTICLE');
      userEventsService('READ_10_ARTICLE');
    });
  }, [getActions, articleId]);

  const getSelectedAction = () => {
    if (actions?.currentUserLiked) return 'like';
    if (actions?.currentUserDisliked) return 'dislike';
    return undefined;
  };
  const handleLikeClick = () => {
    // @ts-ignore
    window.ym(90053722, 'reachGoal', 'setLike');
    actionService.setLike(articleId).then(() => {
      getActions();
      if (!getSelectedAction() && !isLiked) {
        setIsLiked(true);
        userEventsService('LIKE_ARTICLE');
      }
    });
  };
  const handleDislikeClick = () => {
    actionService.setDislike(articleId).then(() => {
      getActions();
    });
  };
  // @ts-ignore
  window.ym(90053722, 'reachGoal', 'openArticle');
  return actions && articleState ? (
    <div>
      {preloader && (
        <div>
          <Preloader />
        </div>
      )}

      {!preloader && (
        <MainDiv>
          <ArticleHeaderWrapper>
            <Article>Статья</Article>
            <PageTitleWrapper>
              <PageTitle text={articleState.title} />
            </PageTitleWrapper>
          </ArticleHeaderWrapper>

          <LikesBlockWrapper>
            <LikesBlock
              dislikeClickHandler={handleDislikeClick}
              likeClickHandler={handleLikeClick}
              selected={getSelectedAction()}
              likes={actions.likes}
              dislikes={actions.dislikes}
            />
          </LikesBlockWrapper>

          <ArticleContent>
            <Image alt="Картинка статьи" src={articleState.imageFileKey} />
            <ArticleBlock>
              <div dangerouslySetInnerHTML={{ __html: articleState.text }} />
            </ArticleBlock>
            <Divider />
          </ArticleContent>
        </MainDiv>
      )}
    </div>
  ) : (
    <Preloader />
  );
}

export default ArticlePage;
