import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import PlayBtnIcon from '../../assets/icons/PlayBtnIcon';
import PauseBtnIcon from '../../assets/icons/PauseBtnIcon';
import ControlButton from '../styled/ControlButton';

const MediaPlayerWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 48px;
  .rhap_current-time {
    color: #c1c1c1;
    position: absolute;
    right: 0;
    top: -18px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }
  .rhap_progress-indicator {
    opacity: 0;
    background-color: #33cccc;
  }
  .rhap_progress-filled {
    background-color: #33cccc;
    border-radius: 0;
  }
  .rhap_download-progress {
    background-color: #33cccc;
    opacity: 0.2;
  }
  .rhap_progress-bar {
    height: 100%;
    overflow: hidden;
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  .rhap_progress-section {
    position: relative;
  }
  .rhap_total-time {
    display: none;
  }
  .rhap_container {
    box-shadow: none;
    width: 100%;
    padding: 0;
    margin-left: 11px;
  }
  .rhap_progress-container {
    margin: 0;
    height: 6px;
  }
  .rhap_controls-section {
    display: none;
  }
`;

function CustomAudioPlayer({
  audio,
  onEnded,
}: {
  audio: string;
  onEnded?: () => void;
}) {
  const audioRef = useRef<any>(null);
  const audioEl = audioRef.current?.audio.current;

  const [sources] = useState([
    audio,
    'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3',
  ]);
  const [src, setSrc] = useState('');
  const [isPaused, setPaused] = useState(true);

  useEffect(() => {
    setSrc(sources[0]);
    audioEl?.pause();
  }, [sources, src, audioEl]);

  const handlePlayClick = () => {
    if (audioEl.paused) {
      audioEl.play();
      setPaused(false);
    } else {
      audioEl.pause();
      setPaused(true);
    }
  };

  const handleAudioEnd = () => {
    setSrc(sources[1]);
    setPaused(true);
    if (onEnded) onEnded();
  };

  return (
    <MediaPlayerWrapper>
      <ControlButton onClick={handlePlayClick}>
        {isPaused ? <PlayBtnIcon /> : <PauseBtnIcon />}
      </ControlButton>
      <AudioPlayer
        ref={audioRef}
        src={src}
        layout="horizontal-reverse"
        showJumpControls={false}
        customAdditionalControls={[]}
        customVolumeControls={[]}
        onEnded={handleAudioEnd}
      />
    </MediaPlayerWrapper>
  );
}

export default CustomAudioPlayer;
