import styled from 'styled-components';
import Button from './Button';

const HeaderWrapper = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #000000;
  flex: none;
  order: 1;
  flex-grow: 0;
  width: 100%;
  max-width: 1552px;
  margin: 0 auto;
  padding: 72px 122px 166px;
  justify-content: center;
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
    padding: 122px 16px 16px;
    min-width: 1272px;
  }
  z-index: 99999;
`;
const PoliticsWrapper = styled.div`
  max-width: 1252px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #000000;
  padding: 72px 122px 166px;
  .button {
    margin-top: 64px;
  }
`;

function Politics() {
  return (
    <HeaderWrapper>
      {/* <PageTitle text="Политика конфиденциальности сайта «Wellness»." /> */}
      <PoliticsWrapper>
        <b>Политика конфиденциальности сайта «Wellness».</b>
        <br />
        <br />
        Мы – ООО Техномедиа, владелец данного Сайта.
        <br />
        <br />
        Этот документ (далее также – «Политика») определяет то, как мы можем
        обрабатывать персональные данные его пользователей (далее также – «Вы»).
        Он описывает:
        <br />
        <br />
        <ul style={{ paddingLeft: '35px' }}>
          <li>
            какие Ваши персональные данные мы можем обрабатывать и откуда мы их
            получаем;
          </li>
          <br />
          <li>зачем, как и почему мы их обрабатываем;</li>
          <br />
          <li>
            сколько мы храним Ваши персональные данные и как мы их защищаем;
          </li>
          <br />
          <li>
            какие основные права возникают у Вас в связи с обработкой и как Вам
            их реализовать.
          </li>
        </ul>
        <br />
        <b>
          1. Какие Ваши персональные данные мы можем обрабатывать и откуда мы их
          получаем?
        </b>
        <br />
        <br />
        1.1. Когда Вы начинаете использовать сервис, мы можем получить (напрямую
        от Вас) следующие данные о Вас:
        <br />
        <br />
        <ul style={{ paddingLeft: '35px' }}>
          <li>
            Адрес электронной почты – когда Вы авторизуетесь на Сайте с
            использованием учетной записи с Сайта «Сбер Агент»
            (https://agent.saleschain.io/);
          </li>
          <br />
          <li>
            ФИО – когда Вы авторизуетесь на Сайте с использованием учетной
            записи с Сайта «Сбер Агент» (https://agent.saleschain.io/);
          </li>
          <br />
          <li>
            IP-адрес – когда Вы авторизуетесь на Сайте с использованием учетной
            записи с Сайта «Сбер Агент» (https://agent.saleschain.io/);
          </li>
        </ul>
        <br />
        1.2. Мы также можем использовать cookie файлы.
        <br />
        1.3. Cookie – это технология, которая позволяет отследить и «запомнить»
        определенную информацию о Вас и тех действиях, которые Вы осуществляете
        при использовании сайта.
        <br />
        1.4. Если сайт не использует cookie, то каждый раз, когда вы переходите
        на новую страницу такого сайта или обновляете страницу, на которой Вы
        находитесь, сайт «забывает» о том, что Вы на нем сделали: например, если
        вы залогинились в свой аккаунт, то без использования cookie при каждом
        переходе на новую страницу (или при обновлении той страницы, на которой
        Вы находитесь) сайт будет «забывать», что Вы уже залогинены, и будет
        предлагать Вам сделать это еще раз. Также, например, сайт может
        «забыть», что вы находились на определенной странице и при его
        обновлении Вам придется снова вспоминать, как Вы до нее добрались.
        <br /> 1.5. Существует несколько различных типов cookie файлов:
        <br />
        <br />
        <ul style={{ paddingLeft: '35px' }}>
          <li>
            Тип 1: cookie файлы, которые необходимы для обеспечения быстрой и
            безопасной работы сайта. Они используются, поскольку без них Вы не
            сможете использовать его функционал нормальным образом (обратите
            внимание на примеры выше);
            <br />
            <br />
          </li>
          <li>
            Тип 2: cookie файлы, которые не являются необходимыми для нормальной
            работы сайта, но позволяют «запомнить» Ваши личные предпочтения при
            его использовании (например, то, какой язык интерфейса Вы выбрали).
            Они используются для того, чтобы сделать использование сайта более
            простым и удобным для Вас;
            <br />
            <br />
          </li>
          <li>
            Тип 3: cookie файлы, которые собирают информацию о том, как Вы
            пользуетесь сайтом (например, информацию о том, какие страницы Вы
            посещаете больше всего, сколько времени Вы проводите на них, иное).
            Они используются для того, чтобы мы могли понять, как Вы используете
            сайт (в частности, например, есть ли у Вас какие-либо трудности с
            нахождением определенной информации на нем, какие разделы сайта
            вызывают у Вас особый интерес, иное), а также улучшить его
            соответствующим образом (например, изменить интерфейс так, чтобы он
            стал более понятным для Вас);
            <br />
            <br />
          </li>
          <li>
            Тип 4: cookie файлы третьих лиц (например, социальных сетей или
            сервисов, которые анализируют использование веб-страниц). Они могут
            использоваться как для интеграции контента (например, плагинов) на
            сайт, так и для целей, аналогичных вышеуказанным (см., в частности,
            cookie файлы типа 3).
            <br />
            <br />
          </li>
        </ul>
        1.6. На данном сайте мы используем следующие cookie файлы:
        <br />
        <br />
        <table border={1} align="center" cellSpacing={5}>
          <tr>
            <td>
              <b>Наименование cookie файла</b>
            </td>
            <td>
              <b>Назначение и какая информация собирается с его помощью</b>
            </td>
            <td>
              <b>Тип</b>
            </td>
          </tr>
          <tr>
            <td>auth</td>
            <td>Токен авторизации пользователя</td>
            <td>third-party</td>
          </tr>
          <tr>
            <td>firstLogin</td>
            <td>
              Логинился ли ранее клиент на сайте wellness с этого браузера и
              устройства. Нужно для отрисовки окна авторизации и регистрации.
              Если пользователь логинился, то все следующие разы при входе
              открывается окно авторизации. Если пользователь не логинился
              ранее, то при входе открывается окно регистрации.
            </td>
            <td>Тип2</td>
          </tr>
        </table>
        <br />
        <br />
        1.7. Cookie файлы устанавливаются в веб-браузер на Вашем устройстве. В
        большинстве случаев это происходит автоматически. Вы всегда можете
        отказаться от сохранения cookie файлов на своем устройстве, изменив
        настройки своего браузера соответствующим образом – Вы можете узнать,
        как это сделать в справке или инструкции к Вашему браузеру, на сайте
        производителя Вашего браузера либо связавшись со службой поддержки
        производителя Вашего браузера. <br />
        1.8. Указанные в этом разделе данные являются Вашими персональными
        данными.
        <br />
        <br />
        <b>2. Зачем нам нужны эти данные?</b>
        <br />
        <br />
        2.1. Адрес электронной почты и ФИО нужны нам для того, чтобы
        идентифицировать Вас при авторизации на Сайте;
        <br />
        2.2. Адрес электронной почты и ФИО нужны нам для того, чтобы
        предоставить Вам уникальный контент на Сайте;
        <br />
        2.3. Адрес электронной почты и ФИО нужны нам для того, чтобы Вы могли
        просматривать в личном кабинете на текущем Сайте и Сайте «Сбер Агент»
        (https://agent.saleschain.io/) развитие своего персонажа, прогресс
        выполнения заданий, свой уровень.
        <br />
        2.4. Адрес электронной почты нужен нам, чтобы Вы могли перейти с
        текущего Сайта на Сайт «Сбер Агент» (https://agent.saleschain.io/) без
        необходимости повторно авторизовываться.
        <br />
        <br />
        <b>3. Как мы обрабатываем Ваши персональные данные?</b>
        <br />
        <br />
        3.1. Когда мы собираем указанные в разделе 1 персональные данные и
        используем их для достижения целей, указанных в разделе 2, мы
        осуществляем их обработку. Такая обработка может включать в себя
        следующие действия, совершаемые с Вашими персональными данными при
        помощи средств вычислительной техники (автоматизированная обработка):
        <br />
        <br />
        <ul style={{ paddingLeft: '35px' }}>
          <li>сбор;</li>
          <li>запись;</li>
          <li>систематизация;</li>
          <li>накопление;</li>
          <li>хранение;</li>
          <li>уточнение (обновление, изменение);</li>
          <li>извлечение;</li>
          <li>использование;</li>
          <li>блокирование;</li>
          <li>удаление;</li>
          <li>уничтожение Ваших персональных данных;</li>
        </ul>
        <br />
        <b>
          4. На каких правовых основаниях мы обрабатываем Ваши персональные
          данные?
        </b>
        <br />
        <br />
        4.1. Мы обрабатываем указанные в разделе 1 данные исключительно в
        случае, если применяется одно из следующих правовых оснований для такой
        обработки:
        <br />
        <br />
        <ul style={{ paddingLeft: '35px' }}>
          <li>
            Вы дали согласие на обработку таких данных для одной или нескольких
            указанных в разделе 2 целей;
          </li>
          <br />

          <li>
            обработка Ваших персональных данных необходима для того, чтобы мы
            имели возможность исполнить наши договорные обязательства перед Вами
            или заключить с Вами договор;
          </li>

          <br />
          <li>
            их обработка необходима для того, чтобы мы могли осуществить наши
            права и законные интересы либо способствовать осуществлению прав и
            законных интересов третьих лиц (в том числе, для достижения
            общественно значимых целей). Перед осуществлением такой обработки мы
            всегда проверяем, не будут ли нарушаться Ваши права и свободы. Если
            мы принимаем решение об осуществлении обработки на этом основании,
            мы также принимаем соответствующие меры, направленные на защиту
            Ваших прав и свобод, связанных с такой обработкой;
          </li>
          <br />

          <li>
            их обработка необходима для того, чтобы исполнить возложенную на нас
            законодательством Российской Федерации обязанность;
          </li>
          <br />

          <li>
            их обработка необходима в связи с нашим участием в судопроизводстве;
          </li>
          <br />

          <li>
            иное правовое основание, предусмотренное ст. 6 Федерального закона
            от 27.07.2006 № 152-ФЗ «О персональных данных.
          </li>
        </ul>
        <br />
        <b>5. Кому мы можем передать Ваши персональные данные?</b>
        <br />
        <br />
        5.1. Мы имеем право передать Ваши персональные данные третьим лицам или
        государственным органам (в частности, органам дознания и следствия, иным
        уполномоченным органам по основаниям, предусмотренным действующим
        законодательством Российской Федерации) в случаях, предусмотренных
        разделом 4.
        <br />
        <br />
        <b>6. Как долго мы можем обрабатывать Ваши персональные данные?</b>
        <br />
        <br />
        6.1. В том случае, если обработка Ваших персональных данных основана на
        согласии, данном Вами для соответствующей цели обработки, мы можем
        обрабатывать такие данные в течение срока его действия.
        <br />
        6.2. В случае достижения цели обработки персональных данных, для которой
        было дано Ваше согласие, истечения срока его действия или в случае
        отзыва Вами согласия, мы прекращаем обработку и уничтожаем Ваши
        персональные данные, соответственно:
        <br />
        <br />
        <ul style={{ paddingLeft: '35px' }}>
          <li>
            в течение 30 дней с даты достижения цели обработки персональных
            данных, для которой было дано Ваше согласие;
          </li>
          <br />
          <li>
            в течение 30 дней с даты истечения срока действия Вашего согласия на
            обработку персональных данных;
          </li>
          <br />
          <li>
            в течение 30 дней с даты поступления к нам отзыва Вашего согласия на
            обработку персональных данных.
          </li>
          <br />
        </ul>
        6.3. В том случае, если обработка Ваших персональных данных основана на
        ином правовом основании (см. Раздел 4), мы можем обрабатывать такие
        данные исключительно до достижения цели соответствующей обработки. В
        случае достижения цели обработки мы прекращаем такую обработку и
        уничтожаем Ваши персональные данные в течение 30 дней с даты достижения
        цели их обработки.
        <br />
        <br />
        6.4. Указанные в пункте 6.3 правила применяются, если:
        <br />
        <br />
        <ul style={{ paddingLeft: '35px' }}>
          <li>
            нам более не нужно обрабатывать Ваши персональные данные для
            достижения иных целей, при наличии соответствующего правового
            основания; и/или
          </li>
          <br />
          <li>
            иной срок прекращения обработки и уничтожения Ваших персональных
            данных не предусмотрен соглашением между Вами и нами или договором,
            по которому Вы являетесь выгодоприобретателем или поручителем; и/или
          </li>
          <br />
          <li>
            мы не вправе осуществлять обработку Ваших персональных данных на
            иных основаниях, предусмотренных Федеральным законом от 27.07.2006 №
            152-ФЗ «О персональных данных» либо другими федеральными законами
            (см. Раздел 4).
          </li>
        </ul>
        <br />
        6.5. Если мы по каким-либо причинам не сможем уничтожить Ваши
        персональные данные в указанные сроки, мы заблокируем их и уничтожим в
        течение 6 месяцев, если иной срок не будет установлен федеральными
        законами. Отсчет 6 месяцев ведется от той же даты, с которой началось
        течение соответствующего первоначального срока для прекращения обработки
        и уничтожения: то есть, от даты достижения цели обработки, даты
        истечения срока действия согласия или от даты поступления отзыва
        согласия.
        <br />
        <br />
        <b>7. Как мы защищаем Ваши персональные данные?</b>
        <br />
        <br />
        7.1. Доступ к Вашим персональным данным может быть предоставлен только
        тем нашим работникам, которым он действительно нужен для исполнения их
        трудовых обязанностей.
        <br />
        7.2. Для обеспечения безопасности и конфиденциальности Ваших
        персональных данных такие работники также обязаны соблюдать внутренние
        нормативные документы, устанавливающие правила и процедуры в отношении
        обработки Ваших персональных данных – в том числе, направленные на
        предотвращение и выявление нарушений законодательства Российской
        Федерации, устранение последствий таких нарушений, проведение
        внутреннего контроля соответствия обработки Ваших персональных данных
        требованиям законодательства Российской Федерации, а также
        устанавливающие организационные и технические меры по обеспечению
        безопасности таких данных.
        <br />
        7.3. Мы принимаем (и, там где это необходимо в случае передачи Ваших
        персональных данных, обеспечиваем их принятие получателем персональных
        данных) все необходимые организационные и технические меры, в частности,
        предусмотренные частью 2 статьи 19 Федерального закона от 27.07.2006 №
        152-ФЗ «О персональных данных», для защиты Ваших персональных данных от
        неправомерного или случайного доступа к ним, уничтожения, изменения,
        блокирования, копирования, предоставления, распространения персональных
        данных, а также от иных неправомерных действий в их отношении.
        <br />
        <br />
        <b>
          8. Какие основные права возникают у Вас в связи с обработкой Ваших
          персональных данных и как Вам их реализовать?
        </b>
        <br />
        <br />
        У Вас есть, в частности:
        <br />
        <br />
        8.1. Право на доступ к обрабатываемым персональным данным, указанным в
        разделе 1.
        <br />
        <p style={{ paddingLeft: '35px' }}>
          8.1.1. Вы (или Ваш представитель) можете обратиться к нам лично или
          направить нам запрос в письменной форме о предоставлении Вам сведений
          о том, какие Ваши персональные данные мы обрабатываем, как и на каких
          основаниях и для каких целей.
          <br />
          8.1.2. Если Вы (или Ваш представитель) хотите направить нам запрос в
          письменной форме, то Вы можете:
          <br />
          <ul style={{ paddingLeft: '35px' }}>
            <li>
              направить его на следующий адрес электронной почты:
              iosemenov@gmail.com ;
            </li>
            <li>
              направить его по следующему почтовому адресу: 185002, Карелия, г.
              Петрозаводск, ул. Чапаева 45, офис – 301.
            </li>
          </ul>
        </p>
        <br />
        8.2. Право требовать от нас уточнения Ваших персональных данных,
        указанных в разделе 1, их блокирования или уничтожения в случае, если
        персональные данные являются неполными, неточными, неактуальными
        (устаревшими) незаконно полученными или не являются необходимыми для
        заявленной цели обработки.
        <br />
        <p style={{ paddingLeft: '35px' }}>
          8.2.1. Для этого Вы можете обратиться к нам лично или направить нам
          письменный запрос по тем же адресам, которые указаны выше в пункте
          8.1.
        </p>
        <br />
        <b>9. Дополнительные условия</b>
        <br />
        <br />
        9.1. Мы оставляем за собой право вносить изменения в Политику в любое
        время. Мы рекомендуем Вам регулярно просматривать Политику
        конфиденциальности – в частности, перед предоставлением Ваших
        персональных данных сервису.
        <br />
        9.2. Если мы примем решение изменить или обновить нашу Политику
        конфиденциальности, мы опубликуем эти изменения здесь{' '}
        <a href="https://wellnessme.ru/terms" target="_blank" rel="noreferrer">
          https://wellnessme.ru/terms
        </a>{' '}
        , чтобы Вы всегда знали, как мы обрабатываем Ваши персональные данные.
        <br />
        <div className="button">
          {' '}
          <Button
            onClick={() => {
              window.history.back();
            }}
            text="Все понятно!"
          />
        </div>
      </PoliticsWrapper>
    </HeaderWrapper>
  );
}

export default Politics;
