import styled from 'styled-components';
import duckImg from '../../assets/images/duckNew.gif';
import loveImg from '../../assets/images/loveNew.gif';
import happySmile from '../../assets/images/happyNew.gif';
import rainbow from '../../assets/images/rainbowNew.gif';

const MainDiv = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: white;
  display: grid;
  place-items: center;
  top: 0;
  left: 0;
`;

const Image = styled.img`
  width: 170px;
`;

const imgSources = [loveImg, happySmile, rainbow, duckImg];
const rand = Math.floor(Math.random() * 4);

type Props = {
  withoutStyles?: boolean;
};

function Preloader(props: Props) {
  const { withoutStyles } = props;
  return withoutStyles ? (
    <Image alt="preloader-img" src={imgSources[rand]} />
  ) : (
    <MainDiv>
      <Image alt="preloader-img" src={imgSources[rand]} />
    </MainDiv>
  );
}

export default Preloader;
