import styled from 'styled-components';
import { NavLink, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Button from '../elements/Button';
import PageTitle from '../elements/PageTitle';
import Preloader from '../elements/Preloader';
import { courseService } from '../../services';
import { ModuleItem } from '../../models';
import Lessons from '../elements/Lessons';

const ModulesCount = styled.div`
  margin-top: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #c1c1c1;
  flex: none;
  order: 1;
  flex-grow: 0;
  flex: none;
  flex-grow: 0;
`;
const ModulesBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin-top: 72px;
  width: 1264px;
  border: 2px solid #f5f5f5;
  border-radius: 32px;
  padding: 10px;
`;
const ImageModule = styled.img`
  width: 320px;
  height: 188px;
  border: 2px solid #272727;
  border-radius: 26px;
`;
const DescriptionModules = styled.div`
  margin-left: 51px;
`;
const ButtonWrapper = styled.div`
  width: 200px;
  padding-left: 40px;
  margin-top: 40px;
  padding-right: 40px;
`;
const ProgressLabelModules = styled.div`
  width: ${(props: { percent: number }) => `${props.percent * 100}%`};
  background-color: #33cccc;
  border-radius: 8px;
  height: 8px;
`;
const ProgressBarModules = styled.div`
  width: 621px;
  margin-top: 9px;
  border-radius: 8px;

  height: 8px;
  background-color: #f5f5f5;
`;
const ProgressModules = styled.div`
  display: flex;
  justify-content: start;
  margin-top: 40px;
  font-style: normal;
  width: 621px;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #272727;
  span {
    font-weight: 400;
    font-size: 20px;
    margin-left: auto;
    margin-right: 0;
  }
`;
const MainBlock = styled.div`
  width: 100%;
  max-width: 1752px;
  margin: 0 auto;
  padding: 72px 122px 166px;
  display: flex;
  justify-content: center;
`;
const Content = styled.div`
  width: 100%;
  max-width: 1272px;
`;
const ModuleBody = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;
function ModulesPage() {
  const [preloader, setPreloader] = useState(true);
  const [courseData, setCourseData] = useState<ModuleItem[]>();
  const [courseName, setCourseName] = useState('');

  const moduleId: any = useParams().id;
  useEffect(() => {
    courseService
      .getModules(moduleId)
      .then((response: any) => {
        setCourseData(response.data.content);
        setCourseName(response.data.content[0].course.category.name);
      })
      .finally(() => setPreloader(false));
  }, []);

  const courseElements = courseData?.map((item: any, index: number) => {
    const watchedLessons = item?.lessons
      .sort((a: any, b: any) => a.id - b.id)
      .filter((element: any) => element.isWatched).length;
    const allLessons = item?.lessons.sort((a: any, b: any) => a.id - b.id);
    let nextLessonId;
    if (item.lessons[watchedLessons]) {
      nextLessonId = allLessons[watchedLessons].contentId;
    }

    return (
      <ModulesBlock key={index}>
        <ImageModule src={item.imageFileKey} />
        <DescriptionModules>
          <ModuleBody>
            <div>
              <Lessons item={item} />
              <ProgressModules>
                {item.numberLessonsCompleted === 0 &&
                  `${item.numberLessons} уроков в модуле`}
                {item.numberLessonsCompleted > 0 &&
                  `Ваш прогресс ${item.numberLessonsCompleted} уроков из ${item.numberLessons} `}

                <span>
                  {Math.ceil(
                    (item.numberLessonsCompleted * 100) / item.numberLessons
                  )}
                  %
                </span>
              </ProgressModules>
              <ProgressBarModules>
                <ProgressLabelModules
                  percent={item.numberLessonsCompleted / item.numberLessons}
                />
              </ProgressBarModules>
            </div>

            <ButtonWrapper>
              {nextLessonId && (
                <NavLink
                  to={`/modules/${item.contentId}/courses/${nextLessonId}`}
                >
                  <Button
                    text={watchedLessons === 0 ? 'Начать' : 'Продолжить'}
                  />
                </NavLink>
              )}
            </ButtonWrapper>
          </ModuleBody>
        </DescriptionModules>
      </ModulesBlock>
    );
  });

  return (
    <div>
      {preloader && (
        <div>
          <Preloader />
        </div>
      )}
      {!preloader && (
        <MainBlock>
          <Content>
            <ModulesCount>Курсы</ModulesCount>
            <PageTitle mb={0} text={courseName} />
            {courseElements}
          </Content>
        </MainBlock>
      )}
    </div>
  );
}

export default ModulesPage;
