import styled, { css } from 'styled-components';

type Props = {
  mb?: number;
  mt?: number;
  color?: string;
};

const DividerWrapper = styled.div`
  ${(propList: Props) =>
    propList.mb &&
    css`
      margin-bottom: ${(props: Props) => `${props.mb ?? 0}px;`};
    `};
  ${(propList: Props) =>
    propList.mt &&
    css`
      margin-top: ${(props: Props) => `${props.mt ?? 0}px;`};
    `};
  background-color: ${(props: Props) => `${props.color ?? '#c1c1c1'};`};
  height: 1px;
  width: 100%;
`;

function Divider(props: Props) {
  return <DividerWrapper {...props} />;
}

export default Divider;
