import styled from 'styled-components';
import DislikeIcon from '../../assets/icons/DislikeIcon';
import LikeIcon from '../../assets/icons/LikeIcon';

type Props = {
  likes: number;
  dislikes: number;
  selected?: string;
  likeClickHandler(): any;
  dislikeClickHandler(): any;
};

const LikesBlockWrapper = styled.div`
  position: sticky;
  top: 368px;
  width: fit-content;
  display: flex;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  svg {
    cursor: pointer;
  }
  &:first-child {
    margin-right: 34px;
    svg {
      margin-bottom: 11px;
    }
  }
  &:last-child {
    padding-top: 8px;
    svg {
      margin-bottom: 3px;
    }
  }
`;

function LikesBlock(props: Props) {
  const {
    selected,
    likes,
    likeClickHandler: onLikeClick,
    dislikeClickHandler: onDislikeClick,
  } = props;
  return (
    <LikesBlockWrapper {...props}>
      <IconWrapper onClick={onLikeClick}>
        <LikeIcon fill={selected === 'like' ? '#33CCCC' : undefined} />
        {likes}
      </IconWrapper>
      <IconWrapper onClick={onDislikeClick}>
        <DislikeIcon fill={selected === 'dislike' ? '#33CCCC' : undefined} />
      </IconWrapper>
    </LikesBlockWrapper>
  );
}

export default LikesBlock;
