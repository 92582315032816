import styled from 'styled-components';
import Typography from '../Typography';

type Props = {
  text: string;
  onClick?: () => void;
  disabled?: boolean;
};

const ButtonWrapper = styled.button`
  padding: 12px 18px;
  font-size: 20px;
  color: #ffffff;
  min-width: 135px;
  cursor: pointer;
  height: 50px;
  background: #33cccc;
  border-radius: 12px;
`;

export function AllArticleButton(props: Props) {
  const { text, onClick, disabled } = props;
  return (
    <ButtonWrapper type="submit" disabled={disabled} onClick={onClick}>
      <Typography fz={20} lh={26} color="#fff">
        {text}
      </Typography>
    </ButtonWrapper>
  );
}
