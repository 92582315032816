import axios, { AxiosInstance } from 'axios';
import { cookies, getDateAfter30Days } from '../utils';

export const API_URL = process.env.REACT_APP_API_URL || 'https://wellness.playground.sysdyn.ru/api/v1/';
// eslint-disable-next-line import/no-mutable-exports
export let userLogin = '';
// eslint-disable-next-line import/no-mutable-exports
export let userPassword = '';
export function setUserLogin(login: string) {
  userLogin = login;
}
export function setUserPassword(password: string) {
  userPassword = password;
}
const auth = cookies.get('auth');
const api: AxiosInstance = axios.create({
  baseURL: API_URL,
  responseType: 'json',
  withCredentials: true,
  headers: {
    Authorization: `Bearer ${auth}`,
  },
});

let isAlreadyFetchingAccessToken = false;
let subscribers: any[] = [];

function onAccessTokenFetched(accessToken: any) {
  subscribers = subscribers.filter((callback) => callback(accessToken));
  subscribers = [];
}

function addSubscriber(callback: any) {
  subscribers.push(callback);
}

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const {
      config,
      response: { status },
    } = error;
    const originalRequest = config;
    if (status === 401) {
      if (userLogin === '' && userPassword === '') {
        window.location.assign('/login');
      }
    }
    if (status === 401 || status === 500) {
      if (
        !isAlreadyFetchingAccessToken &&
        userPassword !== '' &&
        userLogin !== ''
      ) {
        window.location.assign('/');
        isAlreadyFetchingAccessToken = true;
        api
          .post<{ type: string; token: string }>('/auth/signin', {
            login: userLogin,
            password: userPassword,
          })
          .then(({ data }) => {
            onAccessTokenFetched(data.token);
            cookies.set('auth', data.token, {
              path: '/',
              sameSite: 'lax',
              expires: getDateAfter30Days(),
            });
          })
          .catch(() => {})
          .finally(() => {
            isAlreadyFetchingAccessToken = false;
          });
      }

      const retryOriginalRequest = new Promise((resolve) => {
        addSubscriber((accessToken: any) => {
          originalRequest.headers.Authorization = `Bearer ${accessToken}`;
          resolve(axios(originalRequest));
        });
      });
      return retryOriginalRequest;
    }
    return Promise.reject(error);
  }
);

api.interceptors.request.use(
  (config) => {
    const token = cookies.get('auth');
    if (auth && config.headers && !config.url?.includes('auth/signin')) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default api;
