function CompletedIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#33CCCC" />
      <path
        d="M10.2495 17.1998C9.84073 17.2 9.44872 17.0374 9.15993 16.748L5.86581 13.4536C5.51136 13.0989 5.51136 12.5238 5.86581 12.1691C6.22037 11.8145 6.79512 11.8145 7.14968 12.1691L10.2495 15.2704L18.4503 7.06577C18.8048 6.71115 19.3796 6.71115 19.7341 7.06577C20.0886 7.42049 20.0886 7.99551 19.7341 8.35023L11.3391 16.748C11.0503 17.0374 10.6582 17.2 10.2495 17.1998Z"
        fill="white"
      />
    </svg>
  );
}

export default CompletedIcon;
