import styled from 'styled-components';

type Props = { mb?: number };
const TitleImage = styled.img<Props>`
  margin-bottom: ${(props: Props) => `${props.mb ?? 25}px;`};
  width: 596px;
  height: 325px;

  border-radius: 26px;
`;

export default TitleImage;
