import Typography from './Typography';

type Props = {
  text: string;
  navLabel?: string;
  mb?: number;
};

function PageTitle(props: Props) {
  const { navLabel, text, mb } = props;
  return (
    <>
      {navLabel && (
        <Typography fw={600} fz={12} color="#C1C1C1" lh={19} mb={1}>
          {navLabel}
        </Typography>
      )}
      <Typography fz={45} lh={58} mb={mb}>
        {text}
      </Typography>
    </>
  );
}
export default PageTitle;
