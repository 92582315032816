import api from '../api';
import { MediaFitnessListItem, MediaListItem, Meditation } from '../models';

export const mediaService = {
  getAllMedia: (page = 1, size = 10) =>
    api.get<{ content: MediaListItem[] }>(
      `content/media/all?page=${page}&page_size=${size}`
    ),
  getAllMediaFitness: (fitnessLevel: string, page = 1, size = 30) =>
    api.get<{ content: MediaFitnessListItem[] | MediaListItem['fitness'] }>(
      `content/media-fitness/all?page=${page}&page_size=${size}&fitness_level=${fitnessLevel}`
    ),
  getMedia: (id: string) =>
    api.get<Meditation>(`content/media-meditation/${id}`),
  getMediaFitness: (id: string) =>
    api.get<MediaFitnessListItem>(`content/media-fitness/${id}`),
};
