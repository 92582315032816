import { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import screenful from 'screenfull';
import FullScreenIcon from '../../assets/icons/FullScreenIcon';
import PauseBtnIcon from '../../assets/icons/PauseBtnIcon';
import PlayBtnIcon from '../../assets/icons/PlayBtnIcon';
import ControlButton from '../styled/ControlButton';

type Props = { fullScreen?: boolean };
const PlayerWrapper = styled.div<Props>`
  z-index: 10;
  position: relative;
  width: 100%;
  aspect-ratio: 1.78;
  & > div:first-child {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 160px;
    height: 160px;
    z-index: 10;
    svg {
      width: 160px;
      height: 160px;
    }
  }
  & > div:last-child {
    width: 100% !important;
    height: 100% !important;
  }
  video {
    height: unset !important;
    width: 100% !important;
    height: 100% !important;
    object-fit: fill;
  }
`;

const VideoControls = styled.div`
  max-width: 1264px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
`;

const VideoControlsWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(39, 39, 39, 0.4);
  height: 82px;
  padding: 20px 124px;
  z-index: 10;
  svg {
    cursor: pointer;
  }
  input[type='range'] {
    -webkit-appearance: none;
    background-image: linear-gradient(#33cccc, #33cccc);
    background-size: 0% 100%;
    background-repeat: no-repeat;
    width: 100%;
    margin-left: 11px;
    margin-right: 48px;
    cursor: pointer;
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: 6px;
      width: 10px;
      background: transparent;
      cursor: ew-resize;
    }
    &::-webkit-slider-runnable-track {
      -webkit-appearance: none;
    }
  }
`;

function CustomVideoPlayer({
  video,
  onEnded,
}: {
  video: string;
  onEnded?: () => void;
}) {
  const timeoutRef = useRef<any>();
  const intervalRef = useRef<any>();
  const playerRef = useRef<any>(null);
  const playerContainerRef = useRef<HTMLDivElement>(null);
  const controlsRef = useRef<HTMLDivElement>(null);
  const bigControlRef = useRef<HTMLDivElement>(null);

  const [playing, setPlaying] = useState(false);
  const [visible, setVisible] = useState<number | null>(2);
  const [touched, setTouched] = useState(false);

  const handlePlayPause = () => {
    if (visible) {
      setTouched(true);
      setPlaying((prev) => !prev);
    }
  };

  const toggleFullScreen = () => {
    if (playerContainerRef.current)
      screenful.toggle(playerContainerRef.current);
  };

  useEffect(() => {
    if (bigControlRef.current && controlsRef.current && (visible || !touched)) {
      bigControlRef.current.style.opacity = '1';
      controlsRef.current.style.opacity = '1';

      if (touched) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
          setVisible(null);
          bigControlRef.current!.style.opacity = '0';
          controlsRef.current!.style.opacity = '0';
        }, 1000);
      }
    }
    if (bigControlRef.current && controlsRef.current && !visible) {
      clearTimeout(timeoutRef.current);
      bigControlRef.current.style.opacity = '0';
      controlsRef.current.style.opacity = '0';
    }
  }, [visible]);

  useEffect(() => {
    const rangeInput = document.querySelector(
      'input[type="range"]'
    ) as HTMLInputElement;

    intervalRef.current = setInterval(() => {
      const duration = playerRef.current?.getDuration();
      const currentTime = playerRef.current?.getCurrentTime();
      const playedPercent = Math.floor((currentTime / duration) * 100);

      rangeInput.style.backgroundSize = `${
        ((playedPercent - 0) * 100) / (100 - 0)
      }% 100%`;
    }, 1000);

    function handleInputChange(e: any) {
      const { target } = e;
      const { min } = target;
      const { max } = target;
      const val = target.value;

      const duration = playerRef.current?.getDuration();
      const percent = val / 10;
      const seconds = (duration / 100) * percent;
      playerRef.current?.seekTo(seconds);

      target.style.backgroundSize = `${
        ((val - min) * 100) / (max - min)
      }% 100%`;
    }

    rangeInput?.addEventListener('input', handleInputChange);

    return () => {
      rangeInput?.removeEventListener('input', handleInputChange);
      clearInterval(intervalRef.current);
    };
  }, []);

  const handleMouseMove = () => setVisible(Math.random());

  const hanldeMouseLeave = () => setVisible(null);

  const handleProgress = (changeEvent: any) => console.log(changeEvent);

  return (
    <PlayerWrapper
      ref={playerContainerRef}
      onMouseMove={handleMouseMove}
      onMouseLeave={hanldeMouseLeave}
    >
      <ControlButton onClick={handlePlayPause} ref={bigControlRef}>
        {playing ? (
          <PauseBtnIcon fill="#fff" color="#272727" />
        ) : (
          <PlayBtnIcon fill="#fff" color="#272727" />
        )}
      </ControlButton>
      <VideoControlsWrapper ref={controlsRef}>
        <VideoControls>
          <ControlButton onClick={handlePlayPause}>
            {playing ? <PauseBtnIcon /> : <PlayBtnIcon />}
          </ControlButton>
          <input type="range" id="volume" name="time" min="0" max="1000" />
          <FullScreenIcon onClick={toggleFullScreen} />
        </VideoControls>
      </VideoControlsWrapper>
      <ReactPlayer
        ref={playerRef}
        url={video}
        playing={playing}
        muted={false}
        onProgress={handleProgress}
        onEnded={onEnded}
      />
    </PlayerWrapper>
  );
}

export default CustomVideoPlayer;
