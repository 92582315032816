import { ReactNode } from 'react';
import styled, { css } from 'styled-components';

type Props = {
  children: ReactNode;
  cols: number;
  colGap: number;
  rowGap: number;
  itemWidth: number;
  mb?: number;
};

const StyledItemList = styled.div`
  display: grid;
  grid-template-columns: ${(props: Props) =>
    `repeat(auto-fill, ${props.itemWidth}px);`};
  justify-content: space-between;
  column-gap: ${(props: Props) => `${props.colGap}px;`};
  row-gap: ${(props: Props) => `${props.rowGap}px;`};
  ${(propList: Props) =>
    propList.mb &&
    css`
      margin-bottom: ${(props: Props) => `${props.mb}px;`};
    `};
`;

function ItemList(props: Props) {
  const { children } = props;
  return <StyledItemList {...props}>{children}</StyledItemList>;
}
export default ItemList;
