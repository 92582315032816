import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Typography from '../elements/Typography';
import 'react-h5-audio-player/lib/styles.css';
import CustomAudioPlayer from '../elements/AudioPlayer';
import Divider from '../elements/Divider';
import TitleImage from '../styled/TitleImage';
import ContentWrapper from '../styled/ContentWrapper';
import PageTitle from '../elements/PageTitle';
import LikesBlock from '../elements/LikesBlock';
import { mediaService, actionService, userEventsService } from '../../services';
import { Actions, Meditation } from '../../models';
import Preloader from '../elements/Preloader';

const PageWrapper = styled.div`
  width: 100%;
  max-width: 1752px;
  margin: 0 auto;
  padding: 58px 122px 110px;
  display: flex;
  justify-content: center;
`;
const LikeWrapper = styled.div`
  position: sticky;
  top: 368px;
  width: fit-content;
`;
const Content = styled.div`
  width: 100%;
  max-width: 1272px;
`;

function MeditationPage() {
  const mediaId = useParams().id;

  const [preloader, setPreloader] = useState(true);
  const [meditation, setMeditation] = useState<Meditation>();
  const [actions, setActions] = useState<Actions>();
  const [hasWatched, setHasWatched] = useState(false);

  useEffect(() => {
    if (mediaId) {
      mediaService.getMedia(mediaId).then(({ data }) => {
        setMeditation(data);
        actionService.setWatched(data.contentId, 'MEDITATION');
        actionService
          .getActions(data.contentId)
          .then((actionsData) => setActions(actionsData.data))
          .finally(() => {
            setPreloader(false);
          });
      });
    }
  }, [mediaId]);

  useEffect(() => {
    if (hasWatched) {
      userEventsService('DO_MEDITATION');
      userEventsService('COMPLETE_MEDITATIONS');
    }
  }, [hasWatched]);

  const getSelectedAction = () => {
    if (actions?.currentUserLiked) return 'like';
    if (actions?.currentUserDisliked) return 'dislike';
    return undefined;
  };

  const handleLikeClick = () => {
    // @ts-ignore
    window.ym(90053722, 'reachGoal', 'setLike');
    actionService
      .setLike(meditation!.contentId)
      .then(() =>
        actionService
          .getActions(meditation!.contentId)
          .then((actionsData) => setActions(actionsData.data))
      );
  };
  const handleDislikeClick = () =>
    actionService
      .setDislike(meditation!.contentId)
      .then(() =>
        actionService
          .getActions(meditation!.contentId)
          .then((actionsData) => setActions(actionsData.data))
      );
  // @ts-ignore
  window.ym(90053722, 'reachGoal', 'openMeditation');
  return meditation && actions ? (
    <PageWrapper>
      <Content>
        <PageTitle
          mb={64}
          navLabel="МЕДИА / МЕДИТАЦИИ"
          text={meditation.title}
        />
        <ContentWrapper>
          <LikeWrapper>
            <LikesBlock
              likes={actions.likes}
              dislikes={actions.dislikes}
              selected={getSelectedAction()}
              likeClickHandler={handleLikeClick}
              dislikeClickHandler={handleDislikeClick}
            />
          </LikeWrapper>
          <div>
            <TitleImage src={meditation.imageFileKey} alt="meditationImage" />
            <CustomAudioPlayer
              audio={meditation.mp3FileKey}
              onEnded={() => setHasWatched(true)}
            />
            <Typography fz={16}>{meditation.description}</Typography>
            <Divider mb={64} mt={64} />
            {/* <Typography fz={24} fw={600} mb={40}>
              Примеры сидячей позы
            </Typography>
            <Typography fz={24} fw={600} mb={20}>
              Что делать?
            </Typography>
            <Typography fz={20} fw={600} mb={8}>
              Итак, первое — запланируйте перерыв
            </Typography>
            <Typography fz={16} mb={20}>
              Временной отрезок может быть любым комфортным для тебя: 5, 10 или
              15 минут. Используй его, чтобы заметить, что происходит вокруг.
              Буквально!
            </Typography>
            <Typography fz={20} fw={600} mb={8}>
              Второе, оглянитесь и выберите предмет или ситуацию, за которой вам
              хочется понаблюдать сейчас
            </Typography>
            <Typography fz={16} mb={20}>
              Дождь за окном, кружащиеся у монитора пылинки, спящий кот —
              подойдёт всё что угодно.
            </Typography>
            <Typography fz={20} fw={600} mb={8}>
              Далее — сосредоточьтесь на том, о чём думаете
            </Typography>
            <Typography fz={16} mb={20}>
              Например, когда смотрите на кота.
            </Typography>
            <Typography fz={20} fw={600} mb={8}>
              Наконец, начните вести дневник
            </Typography>
            <Typography fz={16} mb={64}>
              Записывайте в него, получается ли у вас сосредоточиться на
              настоящем. Если нет — не страшно. Возможно, пока такая
              концентрация вам непривычна, и это нормально. Фиксируйте свои
              ощущения: о чём были ваши мысли, что вы чувствовали после?
            </Typography> 
            <Divider /> */}
          </div>
        </ContentWrapper>
      </Content>
    </PageWrapper>
  ) : (
    <Preloader />
  );
}

export default MeditationPage;
