import api from '../api';
import { Actions } from '../models';

export const actionService = {
  getActions: (contentId: number) =>
    api.get<Actions>(`content/actions/${contentId}`),
  setLike: (articleId: number) => api.post(`content/actions/like/${articleId}`),
  setDislike: (articleId: number) =>
    api.post(`content/actions/dislike/${articleId}`),
  setWatched: (contentId: number, contentType: string) =>
    api.post(`content/actions/watch/${contentId}`, {
      name: `${contentType}`,
    }),
  getIsLike: (contentId: number) =>
    api.get(`content/actions/is-like/${contentId}`),
  setFirstLogin: (props: boolean) =>
    api({
      url: 'content/actions/first-login',
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: String(props),
    }),

  getFirstLogin: () => api.get('content/actions/is-first-login'),
};
