import styled from 'styled-components';
import Button from './Button';

const HeaderWrapper = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #000000;
  flex: none;
  order: 1;
  flex-grow: 0;
  width: 100%;
  max-width: 1552px;
  margin: 0 auto;
  padding: 72px 122px 166px;
  justify-content: center;
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
    padding: 122px 16px 16px;
    min-width: 1272px;
  }
  z-index: 99999;
`;

const TermWrapper = styled.div`
  max-width: 1252px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #000000;
  padding: 72px 122px 166px;
  .button {
    margin-top: 64px;
  }
`;

function Terms() {
  return (
    <HeaderWrapper>
      {/* <PageTitle text="Пользовательское соглашение" /> */}
      <TermWrapper>
        <b>Пользовательское соглашение сайта Wellness</b>
        <br />
        <br />
        Настоящее пользовательское соглашение о Пользовании Сайтом и
        использовании Контента, размещенного на Сайте (далее – Соглашение),
        регулирует Пользование Сайтом и использование Контента, размещенного на
        Сайте, и устанавливает связанные с этим гражданские права и обязанности
        Пользователя и Компании. Перед Пользованием Сайтом и/или использованием
        Контента Сайта, пожалуйста, ознакомьтесь с условиями настоящего
        Соглашения. Осуществляя регистрацию на Сайте или продолжая использовать
        Сайт, размещенные на нем материалы, Контент, Пользователь тем самым
        принимает и обязуется выполнять все без исключения условия настоящего
        Соглашения. В случае, если Пользователь не согласен с условиями
        настоящего Соглашения полностью или частично, он обязуется покинуть Сайт
        и прекратить любое Пользование Сайтом и использование Контента. <br />
        <br />
        <b>1. Термины и определения</b>
        <br />
        <br />
        1.1. <b>Авторизация</b> – процедура ввода данных учетной записи (логина
        и пароля) Пользователем, позволяющая осуществить доступ к Аккаунту
        Пользователя. Авторизация осуществляется при помощи учетных данных
        (логина и пароля), используемых Пользователем на сайте «Сбер Агент»
        (https://agent.saleschain.io/).
        <br />
        1.2. <b>Аккаунт Пользователя / Профиль / Учетная запись </b>–
        совокупность защищенных веб-страниц Сайта, доступ к которым Пользователь
        получает после ввода логина и пароля, содержащая информацию и данные
        Пользователя, указанные Пользователем, данные о Пользователе,
        предоставленные Пользователем в процессе Пользования настоящим Сайтом и
        сайтом «Сбер Агент» (https://agent.saleschain.io/), данные о настройках
        Сайта. <br />
        1.3. <b>Компания</b> – Общество с ограниченной ответственностью
        «Техномедиа» (ОГРН 1121001001802; иные реквизиты -
        https://sysdyn.ru/#about).
        <br />
        1.4. <b> Контент Компании / Контент</b> – любая информация,
        представленная в текстовом, графическом, аудиовизуальном (видео)
        форматах, а также элементы дизайна, иллюстрации, графические
        изображения, скрипты, видео, звуки, текст и другие объекты, размещенные
        на Сайте, являющиеся охраняемыми результатами интеллектуальной
        деятельности или не являющиеся таковыми, права на которые принадлежат
        Компании. <br />
        1.5. <b>Пользователь</b> – дееспособное физическое лицо, достигшее
        восемнадцатилетнего возраста, зарегистрировавшееся на сайте «Сбер Агент»
        (https://agent.saleschain.io/) и осуществляющее Пользование Сайтом на
        Устройстве. <br />
        1.6. <b>Пользование Сайтом</b> – действия Пользователя, направленные на
        взаимодействие с материалами, Контентом и/или функциональными
        возможностями Сайта, заключающиеся в поиске, просмотре (ознакомлении) и
        изучении Пользователем материалов и/или Контента (или информации,
        содержащейся в материалах и/или Контенте или получаемой через видео,
        статьи, материалы и другие источники такой информации, размещенные на
        Сайте). При этом Пользователь не вправе осуществлять копирование, запись
        (воспроизведение) и/или хранение материалов и/или Контента на Устройстве
        и/или ином оборудовании, а также на каком-либо материальном носителе,
        или осуществлять какое-либо иное использование (по смыслу пункта 2
        статьи 1270 ГК РФ), а также способами «декомпилирование»,
        «дизассемблирование», «декодирование», «эмуляция», «нарушение
        целостности», «восстановление исходного кода».
        <br />
        1.7. <b>Сайт</b> – совокупность программ для электронных вычислительных
        машин (включая, web-приложения) и иной информации (включая, Контент
        Компании), содержащейся в информационной системе, доступ к которой
        обеспечивается посредством информационно-телекоммуникационной сети
        «Интернет» по доменному имени https://wellnessme.ru/, позволяющему
        идентифицировать Сайты в сети «Интернет», а также совокупность
        соответствующих веб-страниц. <br />
        1.8. <b>Возможности Сайта</b> – функционал и функциональные возможности
        Сайта, доступные всем Пользователям после Авторизации для удаленного
        Пользования Сайтом через сеть «Интернет». Перечень и состав доступного
        Пользователю функционала и функциональных возможностей может меняться
        Компанией в одностороннем порядке без какого-либо уведомления
        Пользователя.
        <br />
        1.9. <b> Устройство</b>– устройство Пользователя (персональный компьютер
        смартфон, планшет или другое устройство), имеющее доступ к сети
        Интернет.
        <br />
        <br />
        <b>2. Предмет Соглашения</b>
        <br />
        <br />
        2.1. Настоящее Соглашение является соглашением Компании и Пользователя
        об установлении гражданских прав и обязанностей, связанных с
        Пользованием Сайтом и использованием Контента Компании, размещенного на
        Сайте.
        <br />
        2.2. Пользователь может и обязуется осуществлять Пользование Сайтом,
        использовать Контент Компании исключительно в соответствии с условиями и
        положениями настоящего Соглашения.
        <br /> 2.3. Пользователю запрещено: <br />
        <p style={{ marginLeft: '35px' }}>
          2.3.1. создавать «inlining or embedded links» (встроенные ссылки),
          позволяющие отображать определенную информацию, Контент Компании и/или
          иные объекты интеллектуальной собственности с Сайта на любом другом
          сайте (в том числе, на собственном сайте Пользователя) посредством
          embed, а также фреймы (frames), являющиеся специальным окном
          веб-браузера для представления внешнего сайта;
          <br /> 2.3.2. создавать любые гиперссылки (включая, «inlining or
          embedded links») на материалы Сайта, Контент Компании, Контент иных
          Пользователей, доступные Пользователю только после Авторизации.
        </p>
        2.4. Принимая условия настоящего Соглашения, в порядке, установленном в
        п. Соглашения, Пользователь, тем самым, помимо всего прочего,
        подтверждает, что он соответствует требованиям, предъявляемым к
        Пользователю и указанным в настоящем Соглашении.
        <br />
        <br />
        <b>3. Общие положения</b> <br />
        <br />
        3.1. Пользование Сайтом на иных условиях, отличных от описанных в данном
        Соглашении, не допускается. Соглашение может быть изменено Компанией без
        какого-либо специального уведомления Пользователя Сайта. Изменения
        вступают в силу с момента публикации новой редакции Соглашения на Сайте.
        Действующая редакция доступна по адресу:{' '}
        <a href="https://wellnessme.ru/terms" target="_blank" rel="noreferrer">
          https://wellnessme.ru/terms
        </a>
        <br />
        3.2. Перед началом Пользования Сайтом, использованием Контента
        Пользователь обязуется ознакомиться с текстом данного Соглашения. Если
        Пользователь не согласен с каким-либо отдельным положением или с
        Соглашением в целом, он обязан прекратить любое Пользование Сайтом,
        использование Контента. Пользователь, выражая свое согласие с условиями
        Соглашения в соответствии с п. [7.1], с изменениями, вносимыми в текст
        Соглашения Компанией, в соответствии с п. [7.2] Соглашения и в
        совокупности с фактом продолжения Пользования Сайтом, использования
        Контента, тем самым, безоговорочно и полностью соглашается со всеми
        условиями настоящего Соглашения.
        <br />
        3.3. В рамках п. [3.2] и п. [7.1]. Соглашения: (i) под «началом
        Пользования Сайтом» следует понимать совершение Пользователем любого
        действия, отвечающего определению «Пользование Сайтом»; (ii) под
        «началом использования» следует понимать совершение Пользователем любых
        действий, направленных на использование Контента Компании. Для целей
        настоящего Соглашения под «Пользованием Сайта» также следует понимать,
        включая, но, не ограниваясь: загрузку Сайта (отдельных веб-страниц
        Сайта) в веб-браузере, загрузку отдельных элементов Сайта в
        веб-браузере, осуществление Авторизации на Сайте.
        <br />
        3.4. Сбор и обработка Компанией персональных данных Пользователей
        осуществляется на основании и в соответствии с «Политикой
        конфиденциальности Сайта». <br />
        <br />
        <b>4. Дополнительные условия</b>
        <br />
        <br />
        4.1. В случае если использование Контента Компании рассматривается
        национальным законодательством Пользователя в качестве запрещённой
        деятельности, либо деятельности, на осуществление которой требуется
        получение специальных разрешений, подача уведомлений и т.п.,
        Пользователь обязуется отказаться от использования Контента, либо от
        Пользования Сайтом в целом и самостоятельно несёт ответственность за
        невыполнение настоящего условия.
        <br />
        4.2. Настоящим Компания и Пользователь, как Стороны настоящего
        Соглашения, пришли к соглашению, что: <br />
        <p style={{ marginLeft: '35px' }}>
          4.2.1. Безопасность и конфиденциальность данных, обрабатываемых
          Пользователем на Устройстве, обеспечивается непосредственно
          Пользователем. Пользователь понимает и принимает риски, связанные с
          передачей персональных данных и иной конфиденциальной информации с
          использованием сети Интернет; <br />
          4.2.2. Компания вправе установить любые лимиты и ограничения на
          Пользование Сайтом и может менять их по собственному усмотрению, без
          предварительного уведомления Пользователя; <br />
          4.2.3. Компания может использовать сервисы иных компаний для целей
          реализации отдельных функциональных возможностей Сайта, при этом
          Пользователь может осуществлять Пользование Сайтом только на условиях
          Соглашения, если непосредственно на самом Сайте или в Соглашении не
          указаны иные условия;
          <br />
          4.2.4. Для бесперебойной работы Сайта (и отдельных функциональных
          возможностей Сайта) Пользователю необходимо обеспечить надлежащее
          качество доступа к сети Интернет на своем Устройстве.
        </p>
        <br />
        <br />
        <b>5. Права и обязанности Сторон </b>
        <br />
        <br />
        <b>5.1. Компания вправе:</b>
        <br />
        <p style={{ marginLeft: '35px' }}>
          5.1.1. Определять структуру, внешний вид и наполнение Сайта,
          информацию и материалы, содержащиеся на Сайте, содержание и состав
          Контента.
          <br />
          5.1.2. Без какого-либо уведомления и/или объяснения причин -
          ограничить или запретить (временно или перманентно) доступность Сайта
          любому Пользователю или всем Пользователям, в том числе, в случае
          нарушения Пользователем условий настоящего Соглашения.
          <br />
          5.1.3. Проводить плановые и внеплановые профилактические работы на
          Сайте, для чего временно приостанавливать работу Сайта. Компания по
          своему усмотрению может уведомлять Пользователей о времени проведения
          данных работ и/или об их длительности, и/или об их характере путем
          размещения соответствующих уведомлений на Сайте.
          <br />
          5.1.4. В любое время изменять оформление Сайта, его содержание,
          изменять или дополнять используемые скрипты, программное обеспечение,
          состав и содержание Контента и другие объекты, используемые,
          хранящиеся и/или размещенные на Сайте, любые серверные приложения, с
          уведомлением Пользователя или без такового.
          <br />
          5.1.5. Блокировать и удалять Профиль Пользователя по своему
          усмотрению, в том числе в случае совершения Пользователем действий,
          нарушающих законодательство Российской Федерации или положения
          Соглашения. В случае удаления или блокировки Профиля Пользователя
          Соглашение прекращает свое действие.
          <br />
          5.1.6. В любое время без предварительного уведомления Пользователя в
          одностороннем порядке ограничивать, расширять, дополнять,
          модифицировать и иным образом изменять функциональные возможности
          Сайта и/или объем доступного Контента, а также любые элементы и части
          Сайта, а также изменять документы, связанные с Сайтом.
          <br />
          5.1.7. Без предварительного уведомления Пользователя изменять по
          своему усмотрению технические и иные характеристики любой части Сайта,
          его функциональные возможности и/или содержание и состав Контента.
        </p>
        <br />
        <b>5.2. Компания обязуется:</b>
        <p style={{ marginLeft: '35px' }}>
          5.2.1. Принимать необходимые организационные и технические меры для
          поддержания работоспособности Сайта, а также для обеспечения
          конфиденциальности и защиты информации, полученной от Пользователя.
        </p>
        <b>5.3. Пользователь вправе:</b>
        <br />
        <p style={{ marginLeft: '35px' }}>
          5.3.1. Осуществлять Пользование Сайтом, использовать Контент на
          условиях и в соответствии с настоящим Соглашением.
        </p>
        <b>5.4. Пользователь обязуется:</b>
        <br />
        <p style={{ marginLeft: '35px' }}>
          5.4.1. Осуществлять Пользование Сайтом, использование Контента
          исключительно на условиях и в соответствии с настоящим Соглашением.
          <br />
          5.4.2. Хранить в тайне и не раскрывать третьим лицам информацию об
          учетных данных Пользователя / Профиле Пользователя, с помощью которых
          осуществляется доступ к Профилю. В случае, если такая информация по
          тем или иным причинам станет известна третьим лицам, Пользователь
          обязуется немедленно изменить свои учетные данные.
          <br />
          5.4.3. Не использовать Устройство и другие программно-аппаратные
          средства, позволяющие нарушить функционирование Сайта.
          <br />
          5.4.4. Соблюдать и не нарушать все права и законные интересы Контента,
          как правообладателя Контента и Сайта при Пользовании Сайтом,
          использовании Контента.
          <br />
          5.4.5. Незамедлительно информировать Компанию обо всех ставших ему
          известными случаях противоправного использования Сайта, материалов
          Сайта, Контента третьими лицами. <br />
        </p>
        <b>5.5. Пользователю запрещается:</b>
        <br />
        <p style={{ marginLeft: '35px' }}>
          5.5.1. помимо изложенного в соответствующих положениях Соглашения, в
          любой форме и любым способом (если иное прямо не разрешено условиями
          Соглашения): (i) копировать, изменять (модифицировать), создавать
          производные произведения, дополнять, распространять (в том числе
          посредством предоставления права использования), продавать, доводить
          до всеобщего сведения, публиковать и совершать иные действия, которые
          прямо не указаны в Соглашении, как допустимые, в отношении информации
          и материалов Сайта, исходного/объектного кода Сайта и/или Контента
          Сайта; (ii) пытаться обойти технические меры защиты Сайта и/или
          пытаться получить исходный / объектный кода Сайта; (iii) совершать
          любые действия, затрудняющие / блокирующие / ухудшающие
          функционирование Сайта; (iv) передавать третьим лицам данные Учетной
          записи / Профиля Пользователя, необходимые для входа в Профиль и
          предоставлять третьим лицам возможность осуществлять Пользование
          Сайтом, использование Контента. <br />
          5.5.2. осуществлять несанкционированный сбор персональных данных
          других Пользователей; <br />
          5.5.3. использовать любые автоматические или автоматизированные
          средства для сбора информации и материалов, размещенных на Сайте, а
          также Контента; <br />
          5.5.4. осуществлять действия, направленные на дестабилизацию
          функционирования Сайта, осуществлять попытки несанкционированного
          доступа к управлению Сайтом или его закрытым разделам (разделам,
          доступ к которым разрешен только Компании), а также осуществлять любые
          иные аналогичные действия; <br />
          5.5.5. осуществлять несанкционированный доступ к Профилям иных
          Пользователей путем подборки или введения учетных данных, а также
          предпринимать попытки такого доступа. <br />
        </p>
        <br />
        <b>6. Ответственность Сторон</b>
        <br />
        <br /> 6.1. Функционал и функциональные возможности Сайта, материалы и
        информация, размещенные на Сайте, а также Контент предоставляются на
        условиях «как есть» (as is). Компания не предоставляет никаких гарантий
        в отношении соответствия всего вышеизложенного конкретным целям и
        ожиданиям Пользователя, а также не предоставляет никаких иных гарантий,
        прямо не указанных в Соглашении.
        <br /> 6.2. Ни при каких условиях Компания не несет ответственности
        перед Пользователем (и какими-либо третьими лицами) за убытки, включая
        любые прямые, косвенные, умышленные, случайные или последующие убытки
        любого характера проистекающие из данного Соглашения и/или из
        Пользования Сайтом, использования Контента или невозможности осуществить
        Пользование Сайта, использование Контента (включая, но, не
        ограничиваясь, убытками, возникшими в результате потери деловой
        репутации, прекращения работы, технического сбоя, аварии или
        неисправности или любых коммерческих убытков, издержек или потерь, а
        равно упущенной выгоды или неосновательного обогащения) даже если
        Компании было известно или должно было быть известно о возможности таких
        убытков или Пользователь был предупрежден о возможности таких убытков.
        <br />
        6.3. Компания предпринимает все разумные меры и осуществляет любые
        целесообразные действия, направленные на обеспечение сохранности
        конфиденциальных данных Пользователей и поддержания работоспособности
        Сайта. Компанией не производится гарантийное обслуживание Сайта. <br />
        6.4. Компания не несет ответственность за передачу Пользователем
        информации третьим лицам, а также, в случае если информация с Сайта
        стала доступна третьим лицам вследствие их несанкционированного доступа
        к Устройству, или действий вирусного или вредоносного программного
        обеспечения на Устройстве. <br />
        6.5. Компания не несет ответственности за невозможность осуществлять
        Пользование Сайтом, использование Контента. <br />
        6.6. Компания оставляет за собой право предоставления функционала и
        функциональных возможностей Сайта в ограниченном режиме. <br />
        6.7. Соглашение не дает Пользователю никаких прав на использование
        охраняемых результатов интеллектуальной деятельности, за исключением
        прямо указанных в Соглашении. <br />
        6.8. За неисполнение либо ненадлежащее исполнение обязательств, взятых
        на себя Пользователем по Соглашению, а также за нарушения условий
        Соглашения, Пользователь несет ответственность в соответствии с
        законодательством Российской Федерации. <br />
        6.9. Любое нарушение условий данного Соглашения Пользователями ведет к
        прекращению действия Соглашения. <br />
        6.10. В случае Пользования Сайтом, использования Контента после
        прекращения действия Соглашения, Пользователь несет ответственность в
        соответствии с законодательством Российской Федерации. <br />
        6.11. Компания не несет никакой ответственности за действия
        Пользователей, а также за сервисы третьих лиц, доступных на Сайте.
        Сервисы третьих лиц доступны на условиях соответствующих соглашений,
        заключаемых между Пользователем и соответствующим третьим лицом. Все
        разногласия по вопросам таких сервисов, в том числе претензии к их
        безопасности, законности и соответствия Сервисов ожиданиям и целям
        Пользователя решаются между Пользователем и соответствующим третьим
        лицом самостоятельно, без привлечения Компании. Компания не несет
        ответственности за некорректное функционирование или не функционирование
        соответствующих сервисов, либо за неполучение ожидаемых Пользователем
        результатов от использования таких сервисов. <br />
        6.12. Пользователь самостоятельно несет ответственность за
        противоправные действия, осуществляемые с использованием Профиля
        Пользователя, включая ответственность перед третьими лицами.
        <br /> 6.13. Пользователь несет полную ответственность за любые
        действия, совершаемые им на Сайте (включая, но не ограничиваясь
        действиями, связанными с Пользованием Сайтом, использованием Контента).
        Пользователь обязуется самостоятельно разрешать претензии третьих лиц,
        связанные с неправомерным размещением им Контента пользователя. <br />
        6.14. Переход к сайтам третьих лиц, установка каких-либо программ для
        ЭВМ и заказ Сервисов у третьих лиц осуществляется Пользователем на свой
        риск и под свою ответственность. К взаимоотношениям Пользователя и
        третьих лиц положения настоящего Соглашения не применяются. <br />
        6.15. Компания не несет ответственности за подбор пароля третьими лицами
        и любые действия, совершенные ими с использованием Профиля Пользователя.{' '}
        <br />
        6.16. Все действия, совершенные Пользователем или от имени Пользователя
        на Сайте под его Профилем, считаются совершенными лично Пользователем.{' '}
        <br />
        6.17. За неисполнение либо ненадлежащее исполнение обязательств, взятых
        на себя Пользователем по Соглашению, за нарушения условий Соглашения, а
        также за нарушение гарантий и заверений, предусмотренных настоящим
        Соглашением, Пользователь несет ответственность в соответствии с
        законодательством Российской Федерации.
        <br />
        <br />
        <b> Заключительные положения</b>
        <br />
        <br /> 7.1. Посещая Сайт, создавая Профиль (осуществляя Авторизацию на
        Сайте) и/или начиная Пользование Сайтом, Пользователь тем самым
        совершает конклюдентные действия, выражающие полное и безоговорочное
        согласие Пользователя заключить настоящее Соглашение на условиях,
        изложенных в настоящем Соглашении (без каких-либо ограничений,
        исключений и/или изъятий условий Соглашения), что в силу ст. ст. 435 и
        438 Гражданского кодекса РФ является принятием (акцептом) Пользователем
        оферты Компании. <br />
        7.2. Соглашение может изменяться Компанией в одностороннем порядке.
        Пользователь, во время каждого посещения Сайта и переда началом
        Пользования Сайтом, использованием Контента, фактической Авторизацией,
        обязуется ознакомиться с редакцией Соглашения, действующей на момент
        посещения Сайта и Пользования Сайтом, и использования Контента. Любые
        конклюдентные действия Пользователя (как это определено в п.7.1
        Соглашения), совершенные Пользователем после внесения Компанией
        изменений в условия настоящего Соглашения и публикации новой /
        измененной версии Соглашения на Сайте, является согласием Пользователя
        изменить условия настоящего Соглашения в соответствии с новой /
        измененной редакцией Компании, опубликованной на Сайте (без каких-либо
        ограничения, исключений и/или изъятий условий Соглашения).
        Соответствующие изменения вступают в силу с даты их публикации, если
        иное не оговорено в соответствующей публикации.
        <p style={{ marginLeft: '35px' }}>
          7.2.1. Настоящим Компания и Пользователь пришли к соглашению, что
          Компания имеет право: в любой момент в одностороннем внесудебном
          порядке отказать от Соглашения (от исполнения Соглашения) уведомив
          Пользователя об этом посредством публикации соответствующего
          уведомления на Сайте; заблокировать Пользователю возможность
          осуществлять Пользование Сайтом.
          <br /> 7.2.2. Если Пользователь не согласен, с вносимыми изменениями
          и/или с новой редакцией Соглашения (в том числе, с каким-либо
          отдельным положением или с новой редакцией Соглашения в целом), он
          обязан незамедлительно покинуть Сайт и прекратить любое Пользование
          Сайтом и использование Контента. <br />
        </p>
        7.3. Соглашение действует с момента совершения Пользователем
        конклюдентных действий (как это определено в п.7.1 Соглашения),
        выражающих полное и безоговорочное согласие Пользователя заключить
        настоящее Соглашение, до момента удаления Профиля Пользователя или
        наступления иных условий, указанных в настоящем Соглашении. Соглашение
        также немедленно прекращается в случае нарушения Пользователем условий
        настоящего Соглашения. <br />
        7.4. Настоящим Компания заявляет, что она является единственным
        обладателем всех исключительных прав на Сайт, алгоритмы, заложенные в
        основу функционала Сайта, отдельные элементы Сайта, а также на иные
        охраняемые результаты интеллектуальной деятельности, представляющие
        собой части Сайта и/или его функционала, Контент, размещенный на Сайте,
        и правомочен распоряжаться ими по своему усмотрению, в рамках
        действующего законодательства. <br />
        7.6. Настоящим Компания заявляет, что законодательство Российской
        Федерации подлежит применению к правам и обязанностям Пользователя и
        Компании по Соглашению, вне зависимости от места нахождения Компании,
        Пользователя или Устройства Пользователя.
        <br />
        7.7. Все споры, разногласия и претензии, которые могут возникнуть в
        связи с исполнением, расторжением или признанием недействительным
        Соглашения, Пользователь и Компания будут стремиться решить путем
        переговоров с соблюдением обязательного претензионного порядка. Срок для
        досудебного урегулирования претензий составляет 30 (тридцать) рабочих
        дней с момента получения соответствующей претензии. Однако если
        возникшие споры не представляется возможным решить путем переговоров,
        они будут разрешаться в судебном порядке в соответствии с
        законодательством Российской Федерации по месту нахождения Компании.
        Настоящим Компания оставляет за собой право не применять в обязательном
        порядке претензионный порядок разрешения споров, а сразу обратиться в
        суд. <br />
        7.8. В предмет настоящего Соглашения входит только Контент, размещенный
        на Сайте на момент фактического посещения Сайта и/или Пользования. Любое
        использование Контента после его удаления с Сайта, является
        неправомерным использованием.
        <div className="button">
          {' '}
          <Button
            onClick={() => {
              window.history.back();
            }}
            text="Все понятно!"
          />
        </div>
      </TermWrapper>
    </HeaderWrapper>
  );
}

export default Terms;
