import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ArrowLeftIcon from '../../assets/icons/ArrowLeftIcon';
import Typography from './Typography';

type Props = {
  workouts: {
    prev: { link: string; text: string } | null;
    next: { link: string; text: string } | null;
  };
  mb?: number;
};

const CourseControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  svg {
    margin-bottom: 16px;
  }
  & > div {
    max-width: 180px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    &:last-child {
      text-align: right;
      svg {
        margin-left: auto;
        transform: rotateY(180deg);
      }
    }
  }
`;

function CourseControls(props: Props) {
  const { workouts } = props;

  const prevLink = workouts.prev?.link || '';
  const nextLink = workouts.next?.link || '';

  return (
    <CourseControlsWrapper {...props}>
      <div>
        {workouts.prev ? (
          <Link to={prevLink}>
            <ArrowLeftIcon />
            <Typography fz={16} lh={21} opacity={0.3}>
              {workouts.prev.text}
            </Typography>
          </Link>
        ) : null}
      </div>
      <div>
        {workouts.next ? (
          <Link to={nextLink}>
            <ArrowLeftIcon />
            <Typography fz={16} lh={21} opacity={0.3}>
              {workouts.next.text}
            </Typography>
          </Link>
        ) : null}
      </div>
    </CourseControlsWrapper>
  );
}

export default CourseControls;
