import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { useSpring, animated, config } from '@react-spring/web';
import { NavLink, useLocation } from 'react-router-dom';
import { cookies } from '../../utils';
import Wellness from './icons/Wellness';
import NavBar from './NavBar';
import Widget from './Widget';

const LogoWrapper = styled(animated.div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 200px;
`;

const Logo = styled(animated.div)`
  height: 48px;
  width: 48px;
`;

const LogoName = styled(animated.div)`
  font-size: 32px;
  margin-right: 8px;
  letter-spacing: -0.02em;
  color: #272727;
`;

const HeaderWrapper = styled.div`
  height: 100px;
  padding-left: 56px;
  padding-right: 56px;
  margin: 0 auto;
  max-width: 1512px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

function Header() {
  const [isWidgetOpen, setIsWidgetOpen] = useState(false);
  const { pathname } = useLocation();
  const withAnim = pathname === '/';

  const Authorized = cookies.get<string>('auth');
  // const Authorized = null;

  const [isNavShown, setIsNavShown] = useState(false);

  const [movingLogoUpStyles, movingLogoUpApi] = useSpring(() => ({
    from: {
      transform: withAnim
        ? 'scale(3) translateY(150px)'
        : 'scale(1) translateY(0px)',
    },
    config: config.stiff,
  }));

  const [movingStyles, movingApi] = useSpring(() => ({
    from: {
      transform: withAnim
        ? 'scale(3) translateY(250px) translateX(70px)'
        : 'scale(1) translateY(0px) translateX(0px)',
      opacity: withAnim ? 0 : 1,
    },
    config: config.stiff,
  }));

  useEffect(
    () => {
      const animationHandler = () => {
        if (document.hidden) {
          movingApi.pause();
          movingLogoUpApi.pause();
        } else {
          movingApi.resume();
          movingLogoUpApi.resume();
        }
      };

      document.addEventListener('visibilitychange', animationHandler);

      let timer: ReturnType<typeof setTimeout>;
      if (withAnim) {
        movingLogoUpApi.start({
          to: [
            { transform: 'scale(3) translateY(150px)' },
            {
              transform: 'scale(1) translateY(0px)',
              delay: Authorized !== null ? 2000 : 4000,
            },
          ],
        });
        movingApi.start({
          to: [
            {
              transform: 'scale(3) translateY(250px) translateX(70px)',
              opacity: 0,
            },
            {
              transform: 'scale(3) translateY(150px) translateX(70px)',
              opacity: 1,
              delay: 1000,
            },
            {
              transform: 'scale(1) translateY(0px) translateX(0px)',
              opacity: 1,
              delay: Authorized !== null ? 500 : 2500,
            },
          ],
        });

        // eslint-disable-next-line prefer-const
        timer = setTimeout(
          () => setIsNavShown(true),
          Authorized !== null ? 2200 : 12000
        );
      } else {
        movingLogoUpApi.start({
          to: [{ transform: ' scale(1) translateY(0px)' }],
        });
        setIsNavShown(true);
      }
      return () => {
        clearTimeout(timer);
        document.removeEventListener('visibilitychange', animationHandler);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      {isWidgetOpen && <Widget setOpen={setIsWidgetOpen} />}
      <HeaderWrapper>
        <NavLink to="/">
          <LogoWrapper>
            <animated.div style={movingLogoUpStyles}>
              <Logo>
                <Wellness />
              </Logo>
            </animated.div>
            <animated.div style={movingStyles}>
              <LogoName>Wellness</LogoName>
            </animated.div>
          </LogoWrapper>
        </NavLink>
        <div />
        {isNavShown && (
          <NavBar
            withPlayground
            openWidget={() => {
              document.body.style.overflow = 'hidden';

              setIsWidgetOpen((prev) => !prev);
            }}
          />
        )}
      </HeaderWrapper>
    </>
  );
}

export default Header;
