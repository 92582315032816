import { createGlobalStyle } from 'styled-components';
import useRoutes from '../hooks/useRoutes';
import fonts from '../global/fonts.module.css';

const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'SB Sans Display', sans-serif;
    color: #272727;
  }
  div, button, input{
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    letter-spacing: -0.02em;
  }
  body {
    min-width: 1333px;
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
      overflow:scroll;
    }
  }
  button {
    background: none;
    border: none;
  }
  img {
      max-width: 100%;
      object-fit: cover;
  }
  a {
    text-decoration: none;
    &:visited {
      color: unset;
    }
  }
  ${fonts}
`;

function App() {
  const routes = useRoutes();
  return (
    <>
      <GlobalStyles />
      {routes}
    </>
  );
}

export default App;
