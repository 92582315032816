function ArrowLeftIcon({ onClick }: { onClick?: () => void }) {
  return (
    <svg
      onClick={onClick}
      width="41"
      height="20"
      viewBox="0 0 41 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.5 10H1M1 10L10 1M1 10L10 19"
        stroke="#33CCCC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ArrowLeftIcon;
