function PauseBtnIcon({ fill, color }: { fill?: string; color?: string }) {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="36" height="36" rx="18" fill={fill ?? '#33CCCC'} />
      <g clipPath="url(#clip0_900_2629)">
        <path
          d="M14.3333 10C13.7145 10 13.121 10.2458 12.6834 10.6834C12.2458 11.121 12 11.7145 12 12.3333V23.6667C12 24.2855 12.2458 24.879 12.6834 25.3166C13.121 25.7542 13.7145 26 14.3333 26C14.9522 26 15.5457 25.7542 15.9832 25.3166C16.4208 24.879 16.6667 24.2855 16.6667 23.6667V12.3333C16.6667 11.7145 16.4208 11.121 15.9832 10.6834C15.5457 10.2458 14.9522 10 14.3333 10V10Z"
          fill={color ?? 'white'}
        />
        <path
          d="M21.6668 10C21.048 10 20.4545 10.2458 20.0169 10.6834C19.5793 11.121 19.3335 11.7145 19.3335 12.3333V23.6667C19.3335 24.2855 19.5793 24.879 20.0169 25.3166C20.4545 25.7542 21.048 26 21.6668 26C22.2857 26 22.8792 25.7542 23.3167 25.3166C23.7543 24.879 24.0002 24.2855 24.0002 23.6667V12.3333C24.0002 11.7145 23.7543 11.121 23.3167 10.6834C22.8792 10.2458 22.2857 10 21.6668 10V10Z"
          fill={color ?? 'white'}
        />
      </g>
      <defs>
        <clipPath id="clip0_900_2629">
          <rect
            width="16"
            height="16"
            fill={color ?? 'white'}
            transform="translate(10 10)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default PauseBtnIcon;
