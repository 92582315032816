function PlayBtnIcon({ fill, color }: { fill?: string; color?: string }) {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="36" height="36" rx="18" fill={fill ?? '#33CCCC'} />
      <g clipPath="url(#clip0_900_2626)">
        <path
          d="M24.6613 15.3125L18.3027 10.6498C17.806 10.2861 17.2184 10.0671 16.6048 10.0169C15.9913 9.9668 15.3759 10.0875 14.8268 10.3657C14.2777 10.644 13.8163 11.0688 13.4939 11.5931C13.1715 12.1175 13.0005 12.7209 13 13.3365V22.6665C12.999 23.2826 13.1691 23.887 13.4912 24.4122C13.8134 24.9375 14.275 25.363 14.8246 25.6415C15.3742 25.92 15.9904 26.0405 16.6045 25.9896C17.2185 25.9387 17.8064 25.7183 18.3027 25.3531L24.6613 20.6905C25.0833 20.3808 25.4263 19.9762 25.6628 19.5093C25.8993 19.0425 26.0225 18.5265 26.0225 18.0031C26.0225 17.4798 25.8993 16.9638 25.6628 16.4969C25.4263 16.0301 25.0833 15.6254 24.6613 15.3158V15.3125Z"
          fill={color ?? 'white'}
        />
      </g>
      <defs>
        <clipPath id="clip0_900_2626">
          <rect
            width="16"
            height="16"
            fill={color ?? 'white'}
            transform="translate(11 10)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default PlayBtnIcon;
