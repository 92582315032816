import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { Fragment, useEffect, useState } from 'react';
import PageTitle from '../elements/PageTitle';
import Typography from '../elements/Typography';
import Divider from '../elements/Divider';
import ContentWrapper from '../styled/ContentWrapper';
import LikesBlock from '../elements/LikesBlock';
import CourseControls from '../elements/CourseControls';
import CustomVideoPlayer from '../elements/VideoPlayer';
import { mediaService, actionService, userEventsService } from '../../services';
import { Actions, MediaFitnessListItem } from '../../models';
import Preloader from '../elements/Preloader';

const PageWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 1752px;
  padding: 40px 120px 75px;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 100%;
  max-width: 1272px;
  .benefits-training {
    font-size: 16px;
  }
  .advice {
    font-size: 16px;
    margin-bottom: 20px;

    & > a {
      color: royalblue;
    }
  }
`;

const VideoPlayerWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Advices = styled.ol`
  padding-left: 55px;
`;

const RoundTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

const RoundList = styled.ul`
  padding-left: 50px;
`;

const RoundText = styled.li`
  font-size: 16px;
`;

const roundNames = [
  'Первый круг',
  'Второй круг',
  'Третий круг',
  'Четвертый круг',
  'Пятый круг',
];

function FitnessPage() {
  // @ts-ignore
  window.ym(90053722, 'reachGoal', 'openFitness');
  const mediaId = useParams().id;

  const [fitnessData, setFitnessData] = useState<MediaFitnessListItem>();
  const [actions, setActions] = useState<Actions>();
  const [workouts, setWorkouts] = useState<{
    prev: { link: string; text: string } | null;
    next: { link: string; text: string } | null;
  }>({ prev: null, next: null });
  const [hasWatched, setHasWatched] = useState(false);

  useEffect(() => {
    if (hasWatched) {
      userEventsService('COMPLETE_WORKOUT');
      userEventsService('COMPLETE_N_VIDEO_WORKOUTS');
    }
  }, [hasWatched]);

  useEffect(() => {
    if (mediaId) {
      mediaService.getMediaFitness(mediaId).then(({ data }) => {
        setFitnessData({
          ...data,
          rounds: data.rounds.sort((a, b) => a.id - b.id),
        });

        if (data.contentId) {
          actionService.setWatched(data.contentId, 'FITNESS');
          actionService
            .getActions(data.contentId)
            .then((actionsData) => setActions(actionsData.data));
        }

        if (data.fitnessLevel) {
          mediaService
            .getAllMediaFitness(data.fitnessLevel)
            .then(({ data: fitnessRes }) => {
              const workoutsData = fitnessRes.content;
              const currentIndex = workoutsData.findIndex(
                (el) => el.contentId === +mediaId
              );
              const prev = workoutsData[currentIndex - 1]
                ? {
                    link: `/media-fitness/${
                      workoutsData[currentIndex - 1].contentId
                    }`,
                    text: workoutsData[currentIndex - 1].title,
                  }
                : null;
              const next = workoutsData[currentIndex + 1]
                ? {
                    link: `/media-fitness/${
                      workoutsData[currentIndex + 1].contentId
                    }`,
                    text: workoutsData[currentIndex + 1].title,
                  }
                : null;
              setWorkouts({ prev, next });
            });
        }
      });
    }
  }, [mediaId]);

  const getSelectedAction = () => {
    if (actions?.currentUserLiked) return 'like';
    if (actions?.currentUserDisliked) return 'dislike';
    return undefined;
  };

  const handleLikeClick = () => {
    // @ts-ignore
    window.ym(90053722, 'reachGoal', 'setLike');
    actionService
      .setLike(fitnessData!.contentId)
      .then(() =>
        actionService
          .getActions(fitnessData!.contentId)
          .then((actionsData) => setActions(actionsData.data))
      );
  };
  const handleDislikeClick = () =>
    actionService
      .setDislike(fitnessData!.contentId)
      .then(() =>
        actionService
          .getActions(fitnessData!.contentId)
          .then((actionsData) => setActions(actionsData.data))
      );

  const renderFitnessRounds = () => {
    const rounds = new Map();

    fitnessData?.rounds.map(({ number, name }) =>
      rounds.set(
        number,
        rounds.get(number) ? [...rounds.get(number), name] : [name]
      )
    );

    return (
      <>
        <Typography fz={20} mb={25} fw={600}>
          Что делать, если нельзя прыгать
        </Typography>
        {Array.from(rounds).map((round, index) => (
          <Fragment key={index}>
            <RoundTitle>{roundNames[round[0] - 1]}</RoundTitle>
            <RoundList>
              {round[1].map((text: string, textIndex: number) => (
                <RoundText key={textIndex}>{text}</RoundText>
              ))}
            </RoundList>
          </Fragment>
        ))}
        <Divider mt={64} mb={64} />
      </>
    );
  };

  return fitnessData && actions ? (
    <>
      <VideoPlayerWrapper>
        <CustomVideoPlayer
          video={fitnessData.videoFileKey}
          onEnded={() => setHasWatched(true)}
        />
      </VideoPlayerWrapper>
      <PageWrapper>
        <Content>
          <PageTitle
            mb={64}
            navLabel="МЕДИА / ФИТНЕС-ТРЕНИРОВКИ"
            text={fitnessData.title}
          />
          <ContentWrapper>
            <LikesBlock
              likes={actions.likes}
              dislikes={actions.dislikes}
              selected={getSelectedAction()}
              likeClickHandler={handleLikeClick}
              dislikeClickHandler={handleDislikeClick}
            />
            <div>
              <div
                className="benefits-training"
                dangerouslySetInnerHTML={{
                  __html: fitnessData.benefitsTraining,
                }}
              />
              <Divider mb={64} mt={64} />
              {fitnessData.rounds.length > 0 && renderFitnessRounds()}
              {fitnessData.advices.length > 0 && (
                <>
                  <Advices>
                    {fitnessData.advices
                      .sort((a, b) => a.id - b.id)
                      .map((advice) => (
                        <li key={advice.id}>
                          <div
                            className="advice"
                            dangerouslySetInnerHTML={{
                              __html: advice.advice,
                            }}
                          />
                        </li>
                      ))}
                  </Advices>
                  <Divider mt={64} mb={64} />
                </>
              )}
              <CourseControls workouts={workouts} />
            </div>
          </ContentWrapper>
        </Content>
      </PageWrapper>
    </>
  ) : (
    <Preloader />
  );
}

export default FitnessPage;
