import { NavLink } from 'react-router-dom';
import Lottie from 'lottie-react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import AnimatedButton from './icons/AnimatedButton.json';
import PlaygroundIcon from '../../assets/icons/PlaygroundIcon';
import AccButton from './icons/AccButton';
import { userInfoService, notificationService } from '../../services';
import { UserData } from '../../models';

const LottieStyle = {
  marginTop: -5,
  height: 56,
};
const NavBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .widgetButton {
    outline: none;
  }
  a {
    font-family: 'SB Sans Display';
    font-size: 18px;
    line-height: 111%;
    letter-spacing: -0.02em;
    color: #272727;
  }
  a.active {
    color: #33cccc;
  }
  & > * {
    margin-left: 48px;
  }
  svg {
    cursor: pointer;
  }
`;

function NavBar({
  withPlayground,
  openWidget,
}: {
  withPlayground?: boolean;
  openWidget?: () => void;
}) {
  const [notify, setNotify] = useState(false);
  const handlerScrollUp = () => {
    if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
      window.scrollBy(0, -50);
      setTimeout(handlerScrollUp, 10);
    }
  };
  const [userData, setUserData] = useState<UserData>({
    dateBirth: 0,
    email: 'string',
    firstName: 'string',
    gender: 0,
    middleName: 'string',
    surname: 'string',
    playgroundGroup: 'string',
  });
  useEffect(() => {
    userInfoService.getUserInfo().then((e) => {
      setUserData(e.data);
    });
  }, []);
  useEffect(() => {
    notificationService.getNotify().then((response) => {
      if (response.data.notify === true) {
        setNotify(response.data.notify);
      }
    });
  }, []);

  return (
    <NavBarWrapper>
      <NavLink to="/articles">Статьи</NavLink>
      <NavLink to="/courses">Курсы</NavLink>
      <NavLink to="/media">Медиа</NavLink>
      <NavLink onClick={handlerScrollUp} to="/tutorial">
        Туториал
      </NavLink>
      <NavLink to="/lk">
        <AccButton />
      </NavLink>
      {userData.playgroundGroup === 'PlaygroundGroupC' && (
        <div
          className="widgetButton"
          role="button"
          tabIndex={0}
          aria-hidden
          onClick={
            openWidget
              ? () => {
                  openWidget();
                  setNotify(false);
                }
              : () => {}
          }
        >
          {withPlayground && notify && (
            <Lottie style={LottieStyle} animationData={AnimatedButton} />
          )}
          {withPlayground && !notify && <PlaygroundIcon />}
        </div>
      )}
    </NavBarWrapper>
  );
}

export default NavBar;
