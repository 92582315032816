import { Fragment, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PageTitle from '../elements/PageTitle';
import ContentWrapper from '../styled/ContentWrapper';
import TitleImage from '../styled/TitleImage';
import Typography from '../elements/Typography';
import Button from '../elements/Button';
import Divider from '../elements/Divider';
import happySmile from '../../assets/images/happyNew.gif';
import sadSmile from '../../assets/images/sadSmile.gif';
import {
  testService,
  courseService,
  actionService,
  userEventsService,
} from '../../services';
import { TestScreen } from '../../models/Test';
import Preloader from '../elements/Preloader';
import Test from '../elements/Test';
import Donut from '../elements/Donut';
import {
  PageWrapper,
  ButtonWrapper,
  ResultBlock,
  TestResultImage,
  TestWrapper,
  DonutWrapper,
} from '../styled/TestPage';
import useTestData from '../../hooks/useTestData';
import CourseControls from '../elements/CourseControls';

function TestPage() {
  const moduleId: any = useParams().moduleid;
  const lessonId: any = useParams().id;
  const courseId: any = useParams().lessonId;
  const [courseName, setCourseName] = useState('');
  const [moduleName, setModuleName] = useState('');
  const [workouts, setWorkouts] = useState<{
    prev: { link: string; text: string } | null;
    next: { link: string; text: string } | null;
  }>({ prev: null, next: null });
  const [resultTest, setResultText] = useState('');
  const [currentScreen, setCurrentScreen] = useState<TestScreen>('intro');
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const { testData, donutData, setDonutData, questionsData, setQuestionsData } =
    useTestData();

  useEffect(() => {
    if (moduleId) {
      courseService.getModulesByContentId(moduleId).then((response: any) => {
        setCourseName(response.data.course.category.name);
        setModuleName(response.data.title);
        const lessonsData = response.data.lessons.sort(
          (a: any, b: any) => a.id - b.id
        );
        const currentIndex = lessonsData.findIndex(
          (el: any) => el.test?.contentId === +lessonId
        );
        if (courseId) {
          actionService.setWatched(
            lessonsData[currentIndex].test.contentId,
            'LESSON'
          );
        }
        actionService.setWatched(testData!.contentId, 'TEST');
        let prevLink = '';
        if (lessonsData[currentIndex - 1]) {
          if (lessonsData[currentIndex - 1].contentType === 'TEST') {
            prevLink = `/test/${lessonsData[currentIndex - 1].test.contentId}`;
          } else {
            prevLink = `/modules/${moduleId}/courses/${
              lessonsData[currentIndex - 1].contentId
            }`;
          }
        }
        const prev = lessonsData[currentIndex - 1]
          ? {
              link: prevLink,
              text: lessonsData[currentIndex - 1].title,
            }
          : null;
        let nextLink = '';
        if (lessonsData[currentIndex + 1]) {
          if (lessonsData[currentIndex + 1].contentType === 'TEST') {
            nextLink = `/test/${lessonsData[currentIndex + 1].test.contentId}`;
          } else {
            nextLink = `/modules/${moduleId}/courses/${
              lessonsData[currentIndex + 1].contentId
            }`;
          }
        }
        const next = lessonsData[currentIndex + 1]
          ? {
              link: nextLink,
              text: lessonsData[currentIndex + 1].title,
            }
          : null;
        setWorkouts({ prev, next });
      });
    }
  }, [lessonId, courseName, moduleName]);

  useEffect(() => {
    if (
      testData?.title === 'Что вы знаете о сахаре?' &&
      currentQuestionIndex === questionsData.length - 1
    ) {
      userEventsService('COMPLETE_TEST_WHAT_DO_YOU_KNOW_ABOUT_SUGAR');
      userEventsService('COMPLETE_4_TEST_FOOD_QUIZZES');
    }

    if (
      testData?.title === 'Сбалансирован ли ваш рацион?' &&
      currentQuestionIndex === questionsData.length - 1
    ) {
      userEventsService('COMPLETE_TEST_IS_YOUR_DIET_BALANCED');
      userEventsService('COMPLETE_4_TEST_FOOD_QUIZZES');
    }

    if (
      testData?.title ===
        'Достаточно ли вы получаете витаминов и минералов из пищи?' &&
      currentQuestionIndex === questionsData.length - 1
    ) {
      userEventsService(
        'COMPLETE_TEST_ARE_YOU_GETTING_ENOUGH_VITAMINS_AND_MINERALS_FROM_FOOD'
      );
      userEventsService('COMPLETE_4_TEST_FOOD_QUIZZES');
    }

    if (
      testData?.title === 'Что вы знаете о здоровом питании?' &&
      currentQuestionIndex === questionsData.length - 1
    ) {
      userEventsService('COMPLETE_TEST_WHAT_DO_YOU_KNOW_ABOUT_HEALTHY_EATING');
      userEventsService('COMPLETE_4_TEST_FOOD_QUIZZES');
    }
  }, [currentQuestionIndex, questionsData, testData?.title]);
  const handlerScrollUp = () => {
    if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
      window.scrollBy(0, -50);
      setTimeout(handlerScrollUp, 10);
    }
  };
  const getCurrentHint = () => {
    const { answers } = questionsData[currentQuestionIndex];
    const selectedAnswer = answers.find((el) => el.selected);
    return selectedAnswer?.description ?? '';
  };

  const handleAnswerClick = (questionIndex: number, answerIndex: number) => {
    const newQuestionsData = questionsData.map(
      (questionItem, questionItemIndex) =>
        questionItemIndex !== questionIndex
          ? questionItem
          : {
              ...questionItem,
              answers: questionItem.answers.map((answer, ansIndex) =>
                ansIndex !== answerIndex
                  ? { ...answer, selected: false }
                  : { ...answer, selected: true }
              ),
            }
    );

    if (testData?.type === 'color wheel') {
      setDonutData((prev) =>
        prev.map((el) => ({
          ...el,
          count: newQuestionsData.filter(
            (question) =>
              question.answers.findIndex(
                (answer) => answer.selected && answer.color === el.color
              ) !== -1
          ).length,
        }))
      );
    }

    setQuestionsData(newQuestionsData);
  };

  const getCounOfAnswers = useCallback(() => {
    let correctAnswers = 0;
    let incorrectAnswer = 0;
    questionsData.forEach((q) => {
      const curAnswer = q.answers.find((a) => a.selected);
      if (curAnswer?.correct) {
        correctAnswers += 1;
      } else {
        incorrectAnswer += 1;
      }
    });
    return { correctAnswers, incorrectAnswer };
  }, [questionsData]);

  const handleChangeQuizIndex = useCallback(
    (count: number) => {
      if (currentQuestionIndex === 0 && count === -1) {
        setCurrentScreen('intro');
        return;
      }
      if (currentQuestionIndex === questionsData.length - 1) {
        const params: any = {};

        const setColors = () => {
          params.blue_answer = donutData.find(
            (el) => el.color === '#3DB6FB'
          )?.count;
          params.green_answer = donutData.find(
            (el) => el.color === '#7633CC'
          )?.count;
          params.orange_answer = donutData.find(
            (el) => el.color === '#FDBF22'
          )?.count;
        };

        const setPoints = () => {
          params.score = questionsData.reduce(
            (sum, cur) => sum + cur.answers.find((a) => a.selected)!.point,
            0
          );
        };

        const setCounts = () => {
          const { correctAnswers, incorrectAnswer } = getCounOfAnswers();
          params.correct_answers = correctAnswers;
          params.incorrect_answer = incorrectAnswer;
        };

        switch (testData?.type) {
          case 'color wheel':
            setColors();
            break;
          case 'default':
            setPoints();
            break;
          case 'default+':
          case 'with picture':
            setCounts();
            break;
          default:
            break;
        }

        testService
          .getResult(String(testData?.contentId), params)
          .then(({ data }) => {
            setResultText(data);
            setCurrentScreen('result');
          });
        return;
      }
      setCurrentQuestionIndex((prev) => prev + count);
    },
    [
      currentQuestionIndex,
      donutData,
      getCounOfAnswers,
      questionsData,
      testData?.contentId,
      testData?.type,
    ]
  );

  const isNextBtnDisabled = useCallback(
    () =>
      !questionsData[currentQuestionIndex].answers.find(
        (answer) => answer.selected
      ),
    [currentQuestionIndex, questionsData]
  );

  useEffect(() => {
    const listener = (event: any) => {
      if (isNextBtnDisabled()) return;
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        handleChangeQuizIndex(1);
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [handleChangeQuizIndex, isNextBtnDisabled]);

  const renderTitle = () =>
    testData!.description.includes('<b>') ? (
      <div
        className="test-description"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: testData!.description }}
      />
    ) : (
      <Typography fz={16} mb={48}>
        {testData!.description}
      </Typography>
    );

  const renderLeftControl = () => {
    if (testData?.type === 'color wheel' || testData?.type === 'default') {
      return <Button text="Назад" onClick={() => handleChangeQuizIndex(-1)} />;
    }
    if (
      testData?.type &&
      ['with picture', 'default+'].includes(testData.type)
    ) {
      // eslint-disable-next-line react/no-danger
      return <div dangerouslySetInnerHTML={{ __html: getCurrentHint() }} />;
    }
    return <div />;
  };

  const renderAllQuestions = () => (
    <>
      {testData && (
        <div className="all-questions-description">{renderTitle()}</div>
      )}
      {testData &&
        questionsData.map((el, index) => (
          <Fragment key={index}>
            <Test
              type={testData.type}
              question={el}
              onClick={(answerIndex) => handleAnswerClick(index, answerIndex)}
            />
            <Divider mb={64} mt={64} color="#F5F5F5" />
          </Fragment>
        ))}
    </>
  );

  const renderCurrentQuestion = () =>
    testData && (
      <>
        <Test
          type={testData.type}
          question={questionsData[currentQuestionIndex]}
          onClick={(answerIndex) =>
            handleAnswerClick(currentQuestionIndex, answerIndex)
          }
        />
        <Divider
          mb={currentQuestionIndex === questionsData.length ? 64 : 32}
          mt={questionsData[currentQuestionIndex].imageFileKey ? 0 : 64}
          color="#F5F5F5"
        />
      </>
    );

  const renderTestResult = () =>
    testData && (
      <>
        <Typography fw={600} fz={24} lh={31} mb={20}>
          {testData.results[0].title}
        </Typography>
        <Typography fz={16}>{testData.results[0].description}</Typography>
        {testData.type !== 'all answers are visible' && <Divider mt={64} />}
      </>
    );

  function getResultImage() {
    const { correctAnswers, incorrectAnswer } = getCounOfAnswers();
    const percentOfSuccess =
      (correctAnswers / (correctAnswers + incorrectAnswer)) * 100;

    return percentOfSuccess >= 50 ? happySmile : sadSmile;
  }

  return testData && questionsData.length ? (
    <PageWrapper>
      <PageTitle
        mb={64}
        navLabel={
          testData.categoryName === 'media' ? 'МЕДИА / ТЕСТ' : 'КУРСЫ / ТЕСТ'
        }
        text={testData.title || ''}
      />
      <ContentWrapper>
        <div />
        <div>
          {currentScreen === 'intro' && (
            <>
              {testData.imageFileKey &&
                testData.imageFileKey !== '' &&
                testData.imageFileKey !==
                  'https://static.playground.sysdyn.ru/wellness/' && (
                  <TitleImage
                    src={testData.imageFileKey || ''}
                    alt="testImage"
                    mb={48}
                  />
                )}

              {testData && renderTitle()}
              <ButtonWrapper>
                <div />
                <Button
                  text="Начать"
                  onClick={() => {
                    actionService.setWatched(testData!.contentId, 'TEST');
                    setCurrentScreen('test');
                  }}
                />
              </ButtonWrapper>
            </>
          )}
          {currentScreen === 'result' && (
            <ResultBlock>
              <Typography fz={24} fw={600} mb={56}>
                Поздравляем, вы завершили тест!
              </Typography>
              <TestResultImage src={getResultImage()} alt="resultSmile" />
              <div
                className="result-text"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: resultTest }}
              />
              <Divider mt={64} />
            </ResultBlock>
          )}
          {currentScreen === 'test' && (
            <TestWrapper>
              <DonutWrapper>
                <Donut data={donutData} />
              </DonutWrapper>
              {testData.type === 'all answers are visible'
                ? renderAllQuestions()
                : renderCurrentQuestion()}
              {testData.type === 'all answers are visible' ? (
                renderTestResult()
              ) : (
                <ButtonWrapper>
                  {renderLeftControl()}
                  <Button
                    disabled={isNextBtnDisabled()}
                    text="Далее"
                    onClick={() => handleChangeQuizIndex(1)}
                  />
                </ButtonWrapper>
              )}
            </TestWrapper>
          )}
          {workouts.next && workouts.prev && <Divider mt={64} mb={64} />}

          <div aria-hidden onClick={handlerScrollUp}>
            <CourseControls workouts={workouts} />
          </div>
        </div>
      </ContentWrapper>
    </PageWrapper>
  ) : (
    <Preloader />
  );
}

export default TestPage;
