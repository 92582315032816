function AccButton() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="#F5F5F5" />
      <path
        d="M11.75 26.3333C13.8911 24.0624 16.7981 22.6667 20 22.6667C23.2019 22.6667 26.1089 24.0624 28.25 26.3333M24.125 14.875C24.125 17.1532 22.2782 19 20 19C17.7218 19 15.875 17.1532 15.875 14.875C15.875 12.5968 17.7218 10.75 20 10.75C22.2782 10.75 24.125 12.5968 24.125 14.875Z"
        stroke="#33CCCC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default AccButton;
