function DislikeIcon({ fill = '#C1C1C1' }: { fill?: string }) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1164_313)">
        <path
          d="M24 21.333H28C29.0609 21.333 30.0783 20.9116 30.8284 20.1614C31.5786 19.4113 32 18.3939 32 17.333V6.66634C32 5.60547 31.5786 4.58806 30.8284 3.83791C30.0783 3.08777 29.0609 2.66634 28 2.66634H24V21.333Z"
          fill={fill}
        />
        <path
          d="M13.3333 21.3337L12.5933 25.771C12.4651 26.4323 12.5613 27.1174 12.8666 27.7178C13.1719 28.3183 13.6688 28.7996 14.2786 29.0857C14.978 29.391 15.7676 29.4179 16.4861 29.161C17.2047 28.9041 17.7981 28.3826 18.1453 27.703L21.3333 21.3337V2.66702H2.66665L-2.09808e-05 17.3337V21.3337H13.3333Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1164_313">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(32 32) rotate(-180)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default DislikeIcon;
