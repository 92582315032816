import api from '../api';
import { ArticlesListItem, ArticleType, CategoriesType } from '../models';

export const articleService = {
  getArticles: (pageNumber: number, activeCategory: string, pageSize: number) =>
    api.get<ArticlesListItem[]>(
      `content/articles/all?category_id=${activeCategory}&page=${pageNumber}&page_size=${pageSize}`
    ),
  getCategory: () => api.get<CategoriesType[]>(`content/article/categories`),
  getArtilce: (articleId: any) =>
    api.get<ArticleType>(`content/articles/${articleId}`),
};
