import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { articleService } from '../../services';
import { CategoriesType } from '../../models';

const Filter = styled.span<{ opacity: number }>`
  padding-right: 16px;
  font-size: 21px;
  opacity: ${(props) => props.opacity};
  cursor: pointer;
`;
const ArticleFilter = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
`;
type Props = {
  category: any;
  setFilter(category: any, activeCategory: any): any;
};
function CategoryFilter(props: Props) {
  const { setFilter: onSetFilter, category } = props;
  const [categories, setCategories] = useState<CategoriesType>();
  const [activeCategory, setActiveCategory] = useState('Фитнес');
  const courseCategories = [
    {
      id: 3,
      contentId: 3,
      name: 'Фитнес',
    },
    {
      id: 4,
      contentId: 4,
      name: 'Питание',
    },
    {
      id: 2,
      contentId: 2,
      name: 'Осознанность',
    },
    {
      id: 5,
      contentId: 5,
      name: 'Сон',
    },
    {
      id: 1,
      contentId: 1,
      name: 'Отдых',
    },
    {
      id: 6,
      contentId: 6,
      name: 'Психология',
    },
  ];
  let categoryElements;
  const getCategories = async () => {
    await articleService.getCategory().then((response: any) => {
      setCategories(response.data);
    });
  };
  useEffect(() => {
    getCategories();
  }, []);
  if (category === 'articleCategory') {
    categoryElements = categories?.map((item: any, index: number) => (
      <Filter
        opacity={activeCategory === item.name ? 1 : 0.2}
        key={index}
        onClick={() => {
          if (activeCategory === item.name) setActiveCategory('');
          else setActiveCategory(item.name);
          onSetFilter(item, activeCategory);
        }}
      >
        {item.name}
      </Filter>
    ));
  }
  if (category === 'courseCategory') {
    categoryElements = courseCategories?.map((item: any, index: number) => (
      <Filter
        opacity={activeCategory === item.name ? 1 : 0.2}
        key={index}
        onClick={() => {
          if (activeCategory === item.name) setActiveCategory('');
          else setActiveCategory(item.name);
          onSetFilter(item, activeCategory);
        }}
      >
        {item.name}
      </Filter>
    ));
  }

  return <ArticleFilter>{categoryElements}</ArticleFilter>;
}
export default CategoryFilter;
