function LeftChevron() {
  return (
    <div className="test">
      <svg
        width="29"
        height="500"
        viewBox="0 -55 29 55"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 2L27 27.5L2 53"
          stroke="#272727"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

export default LeftChevron;
