import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const LessonsWrapper = styled.div`
  div {
    font-family: 'SB Sans Display', sans-serif;
    font-style: normal;
    line-height: 31px;
    letter-spacing: 0;
  }
`;
const ModuleStyle = styled.div<{ color: any }>`
  font-weight: 600;
  padding-bottom: 10px;
  font-size: 20px;
  line-height: 26px;
  color: ${(props) => props.color};
`;
const DropIcon = styled.span`
  margin-left: 15px;
`;
const CourseTitleModule = styled.div<{
  color: any;
  margin: number;
  marginBottom: number;
  fontSize: number;
}>`
  color: ${(props) => props.color};
  margin-top: ${(props) => props.margin}px;
  margin-bottom: ${(props) => props.marginBottom}px;
  text-decoration: underline dashed;
  font-weight: 600;
  font-size: ${(props) => props.fontSize}px;
  line-height: 31px;
  cursor: pointer;
`;

function Lessons(props: any) {
  const [show, setShow] = useState(false);
  const [showFirstLvl, setShowFirstLvl] = useState(false);
  let firstLvlWatched = 0;
  let firstTotalWatched = 0;
  let secondLvlWatched = 0;
  let secondTotalWatched = 0;
  let thirdLvlWatched = 0;
  let thirdTotalWatched = 0;
  const [showSecondLvl, setShowSecondLvl] = useState(false);
  const [showThirdLvl, setShowThirdLvl] = useState(false);
  const { item } = props;
  const withoutLvl = item.lessons
    .filter((items: any) => items.level === null)
    .map((lesson: any, lessonIndex: number) => (
      <NavLink
        key={lessonIndex}
        to={
          lesson.contentType === 'TEST'
            ? `/modules/${item?.contentId}/courses/${lesson?.contentId}/test/${lesson.test?.contentId}`
            : `/modules/${item?.contentId}/courses/${lesson?.contentId}`
        }
      >
        <ModuleStyle color={lesson.isWatched ? '#33CCCC' : '#272727'}>
          {lesson.title}
        </ModuleStyle>
      </NavLink>
    ));

  const firstLvl = item.lessons
    .filter((items: any) => items.level === 1)
    // eslint-disable-next-line array-callback-return
    .map((lesson: any, lessonIndex: number) => {
      if (lesson.isWatched) {
        firstLvlWatched += 1;
      }
      firstTotalWatched += 1;
      return (
        <NavLink
          key={lessonIndex}
          to={
            lesson.contentType === 'TEST'
              ? `/test/${lesson.test?.contentId}`
              : `/modules/${item?.contentId}/courses/${lesson?.contentId}`
          }
        >
          <ModuleStyle color={lesson.isWatched ? '#33CCCC' : '#272727'}>
            {lesson.title}
          </ModuleStyle>
        </NavLink>
      );
    });

  const secondLvl = item.lessons
    .filter((items: any) => items.level === 2)
    .map((lesson: any, lessonIndex: number) => {
      if (lesson.isWatched) {
        secondLvlWatched += 1;
      }
      secondTotalWatched += 1;
      return (
        <NavLink
          key={lessonIndex}
          to={
            lesson.contentType === 'TEST'
              ? `/test/${lesson.test?.contentId}`
              : `/modules/${item?.contentId}/courses/${lesson?.contentId}`
          }
        >
          <ModuleStyle color={lesson.isWatched ? '#33CCCC' : '#272727'}>
            {lesson.title}
          </ModuleStyle>
        </NavLink>
      );
    });

  const thirdLvl = item.lessons
    .filter((items: any) => items.level === 3)
    .map((lesson: any, lessonIndex: number) => {
      if (lesson.isWatched) {
        thirdLvlWatched += 1;
      }
      thirdTotalWatched += 1;

      return (
        <NavLink
          key={lessonIndex}
          to={
            lesson.contentType === 'TEST'
              ? `/test/${lesson.test?.contentId}`
              : `/modules/${item?.contentId}/courses/${lesson?.contentId}`
          }
        >
          <ModuleStyle color={lesson.isWatched ? '#33CCCC' : '#272727'}>
            {lesson.title}
          </ModuleStyle>
        </NavLink>
      );
    });

  return (
    <LessonsWrapper>
      <CourseTitleModule
        color="#272727"
        marginBottom={26}
        fontSize={24}
        margin={49}
        onClick={() => {
          setShow(!show);
          if (show) {
            // @ts-ignore
            window.ym(90053722, 'reachGoal', 'openLessons');
          }
        }}
      >
        {item.title}{' '}
        <DropIcon>
          {!show ? (
            <svg
              width="14"
              height="8"
              viewBox="0 0 14 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L7 7L13 1"
                stroke="#272727"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg
              width="14"
              height="8"
              viewBox="0 0 14 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 7L7 1L13 7"
                stroke="#272727"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </DropIcon>
      </CourseTitleModule>
      {show && (
        <div>
          {withoutLvl && <div>{withoutLvl}</div>}
          {item.lessons.filter((items: any) => items.level === 1).length >
            0 && (
            <div>
              <CourseTitleModule
                color={
                  firstLvlWatched === firstTotalWatched ? '#33CCCC' : '#272727'
                }
                marginBottom={0}
                fontSize={20}
                onClick={() => setShowFirstLvl(!showFirstLvl)}
                margin={0}
              >
                Уровень 1
              </CourseTitleModule>
              {showFirstLvl && <div>{firstLvl}</div>}
            </div>
          )}
          {item.lessons.filter((items: any) => items.level === 2).length >
            0 && (
            <div>
              <CourseTitleModule
                color={
                  secondLvlWatched === secondTotalWatched
                    ? '#33CCCC'
                    : '#272727'
                }
                marginBottom={0}
                fontSize={20}
                onClick={() => setShowSecondLvl(!showSecondLvl)}
                margin={0}
              >
                Уровень 2
              </CourseTitleModule>
              {showSecondLvl && <div>{secondLvl}</div>}
            </div>
          )}
          {item.lessons.filter((items: any) => items.level === 3).length >
            0 && (
            <div>
              <CourseTitleModule
                color={
                  thirdLvlWatched === thirdTotalWatched ? '#33CCCC' : '#272727'
                }
                marginBottom={0}
                fontSize={20}
                onClick={() => setShowThirdLvl(!showThirdLvl)}
                margin={0}
              >
                Уровень 3
              </CourseTitleModule>
              {showThirdLvl && <div>{thirdLvl}</div>}
            </div>
          )}
        </div>
      )}
    </LessonsWrapper>
  );
}

export default Lessons;
