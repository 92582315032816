import { useState } from 'react';
// @ts-ignore
import ItemsCarousel from 'react-items-carousel';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import articleTop from '../../assets/images/article-top.png';
import Typography from './Typography';
import RightChevron from './icons/RightChevron';
import LeftChevron from './icons/LeftChevron';

const ContinueImage = styled.img`
  height: 283px;
  min-width: 260px;
  margin-right: 10px;
  border: 2px solid #272727;
  border-radius: 26px;
`;
const ItemListWrapper = styled.div`
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
    max-width: 1333px;
    .rightChevron {
      padding-right: 50px;
      width: 200px;
      background: linear-gradient(
        270deg,
        #ffffff 12.5%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }
  .rightChevron {
    width: 200px;
    background: linear-gradient(
      270deg,
      #ffffff 12.5%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  .test {
    background: linear-gradient(
      270deg,
      #ffffff 12.5%,
      rgba(255, 255, 255, 0) 100%
    );
    transform: rotate(0.5turn);
  }

  margin-top: 42px;
  width: 1400px;
`;
function LastView(props: any) {
  const { lastView } = props;

  const [activeItem, changeActiveItem] = useState(0);

  const lastViewList = lastView
    ?.sort((a: any, b: any) => a.modifiedDate - b.modifiedDate)
    .map((item: any) => {
      let link: string = '';
      if (item.contentType === 'ARTICLE') {
        link = `articles/${item.contentId}`;
      }
      if (item.contentType === 'LESSON') {
        link = `modules/${item.moduleId}/courses/${item.contentId}`;
      }
      if (item.contentType === 'TEST') {
        link = `test/${item.contentId}`;
      }
      if (item.contentType === 'MEDITATION') {
        link = `media/${item.contentId}`;
      }
      if (item.contentType === 'FITNESS') {
        link = `media-fitness/${item.contentId}`;
      }
      return (
        <div key={item.contentId}>
          <NavLink to={link}>
            <ContinueImage
              alt="pic one"
              src={item.imageFileKey || articleTop}
            />
            <Typography fw={600} color="#33CCCC">
              {item.contentType === 'LESSON' && 'Урок'}
              {item.contentType === 'ARTICLE' && 'Статья'}
              {item.contentType === 'TEST' && 'Тест'}
              {item.contentType === 'MEDITATION' && 'Медитация'}
              {item.contentType === 'FITNESS' && 'Фитнес-тренировка'}
            </Typography>
            <Typography lh={27} fw={600} fz={21}>
              {item.title}
            </Typography>
          </NavLink>
        </div>
      );
    })
    .reverse();
  return (
    <div>
      <ItemListWrapper>
        <ItemsCarousel
          numberOfCards={5}
          gutter={20}
          freeScrolling
          activePosition="center"
          chevronWidth={24}
          rightChevron={<RightChevron />}
          requestToChangeActive={changeActiveItem}
          activeItemIndex={activeItem}
          leftChevron={<LeftChevron />}
          outsideChevron={false}
        >
          {lastViewList}
        </ItemsCarousel>
      </ItemListWrapper>
    </div>
  );
}

export default LastView;
