import styled from 'styled-components';
import { useState } from 'react';
import Button from './Button';
import { authService } from '../../services';

const Wrapper = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;

  line-height: 23px;
  letter-spacing: -0.02em;
  flex: none;
  order: 1;
  flex-grow: 0;
  display: table;
  margin: 0 auto;
  padding: 72px 122px 166px;

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
    padding: 122px 16px 16px;
    min-width: 1272px;
  }
  z-index: 99999;

  label {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 111%;
    letter-spacing: -0.01em;
    color: #272727;
  }
  .passwordInput {
    margin-top: 16px;
    margin-bottom: 16px;
    width: 385px;
    height: 71px;
    padding: 10px;
    font-size: 24px;
    border: 1px solid #33cccc;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .error {
    color: #ff4f4f;
    padding-left: 10px;

    margin-bottom: 32px;
  }
  Button {
    width: 385px;
    height: 71px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 24px 14px;
    margin-top: 48px;
    gap: 8px;
  }
`;

function PasswordReset() {
  const [email, setEmail] = useState<string>('');
  const [linkSend, setLinkSend] = useState<boolean>(false);
  const [error, setError] = useState('');
  return (
    <Wrapper>
      {!linkSend && (
        <>
          {' '}
          <label htmlFor="email">
            Введите email,
            <br /> указанный при регистрации
            <input
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              id="email"
              type="email"
              className="passwordInput"
            />
          </label>
          {error !== '' && <div className="error">{error}</div>}
          <Button
            fontSize={32}
            onClick={() => {
              authService.sendResetLink(email).then(() => {
                setLinkSend(true);
              }).catch((e)=>{
                if(e.response.status === 400){
setError('Пользователь с таким e-mail не найден');
                }
              });
            }}
            text="Отправить"
          />
        </>
      )}
      {linkSend && (
        <div>
          На указанную почту отправлена ссылка для восстановления пароля
        </div>
      )}
    </Wrapper>
  );
}

export default PasswordReset;
