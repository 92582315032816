import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Preloader from '../elements/Preloader';
import { courseService } from '../../services';

const TitleDiv = styled.div`
  font-size: 45px;
  line-height: 58px;
  letter-spacing: -0.02em;
  color: #272727;
`;

const ModuleCount = styled.div`
  margin-top: 6px;
  font-size: 20px;
  line-height: 26px;
  color: #c1c1c1;
`;

const ModulesBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin-top: 72px;
  border: 2px solid #f5f5f5;
  border-radius: 32px;
  padding: 10px;
`;
const Image = styled.img`
  width: 499px;
  height: 294px;
  border: 2px solid #272727;
  border-radius: 26px;
`;

const Description = styled.div`
  margin-left: 51px;
`;

const CourseTitle = styled.div`
  margin-top: 49px;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 41px;
  color: #272727;
  flex: none;
  order: 0;
  flex-grow: 0;
`;
const ProgressLabel = styled.div`
  width: ${(props: { percent: number }) => `${props.percent * 100}%`};
  background-color: #33cccc;
  border-radius: 8px;
  height: 8px;
`;

const ProgressBar = styled.div`
  width: 621px;
  margin-top: 9px;
  border-radius: 8px;
  height: 8px;
  background-color: #f5f5f5;
`;

const Progress = styled.div`
  display: flex;
  justify-content: start;
  margin-top: 40px;
  width: 621px;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  span {
    font-weight: 400;
    font-size: 20px;
    margin-left: auto;
    margin-right: 0;
  }
`;

const MainBlock = styled.div`
  width: 100%;
  max-width: 1752px;
  margin: 0 auto;
  padding: 72px 122px 166px;
  display: flex;
  justify-content: center;
`;
const Content = styled.div`
  width: 100%;
  max-width: 1272px;
`;
// @ts-ignore
window.ym(90053722, 'reachGoal', 'openAllCourses');
function CoursesPage() {
  const [preloader, setPreloader] = useState(true);
  const [courseData, setCourseData] = useState([]);
  useEffect(() => {
    courseService
      .getCourses(10)
      .then((response: any) => {
        setCourseData(response.data.content);
      })
      .finally(() => setPreloader(false));
  }, []);
  const courseElement = courseData?.map((item: any, index: number) => {
    const percent = Math.ceil(
      (item.numberLessonsCompleted * 100) / item.numberLessons
    );

    return (
      <NavLink
        onClick={() => {
          // @ts-ignore
          window.ym(90053722, 'reachGoal', 'openModule');
        }}
        key={index}
        to={`/modules/${item.id}`}
      >
        <ModulesBlock>
          <Image src={item.imageFileKeyPreview} />
          <Description>
            <CourseTitle>{item.title}</CourseTitle>
            <ModuleCount>
              {item.numberModules}
              {item.numberModules === 0 && ' модулей'}
              {item.numberModules === 1 && ' модуль'}
              {item.numberModules > 1 && item.numberModules < 5 && ' модуля'}
              {item.numberModules > 5 && ' модулей'}
            </ModuleCount>
            <Progress>
              Ваш прогресс {item.numberLessonsCompleted} уроков из{' '}
              {item.numberLessons} <span>{percent ? `${percent}` : '0'}%</span>
            </Progress>
            <ProgressBar>
              <ProgressLabel
                percent={item.numberLessonsCompleted / item.numberLessons}
              />
            </ProgressBar>
          </Description>
        </ModulesBlock>
      </NavLink>
    );
  });
  return (
    <div>
      {preloader && (
        <div>
          <Preloader />
        </div>
      )}
      {!preloader && (
        <MainBlock>
          <Content>
            <TitleDiv>Курсы </TitleDiv>
            {courseElement}
          </Content>
        </MainBlock>
      )}
    </div>
  );
}

export default CoursesPage;
