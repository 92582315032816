/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-comment-textnodes */
import styled from 'styled-components';
import { useSpring, animated, config } from '@react-spring/web';
// @ts-ignore
import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { NavLink } from 'react-router-dom';
import articleBottom from '../../assets/images/article-bottom.png';
import articleMiddle from '../../assets/images/article-middle.png';
import articleTop from '../../assets/images/article-top.png';
import courseBottom from '../../assets/images/course-bottom.png';
import courseMiddle from '../../assets/images/course-middle.png';
import courseTop from '../../assets/images/course-top.png';
import flowBottom from '../../assets/images/flow-bottom.png';
import flowMiddle from '../../assets/images/flow-middle.png';
import flowTop from '../../assets/images/flow-top.png';
import ItemList from '../elements/ItemList';
import { AllArticleButton } from '../elements/MainPageElements';
import Typography from '../elements/Typography';
import {
  actionService,
  articleService,
  courseService,
  mainPageService,
  mediaService,
  testService,
} from '../../services';
import { ArticleType, CourseType, Test } from '../../models';
import CategoryFilter from '../elements/CategoryFilter';
import ElementsList from '../elements/ElementsList';
import Preloader from '../elements/Preloader';
import LastView from '../elements/LastView';

const ButtonWrapper = styled.span`
  margin-top: 15px;
  cursor: pointer;
  text-align: right;
`;

const ArticleFilter = styled.span<{ opacity: number }>`
  padding-right: 16px;
  font-size: 21px;
  opacity: ${(props) => props.opacity};
  cursor: pointer;
  padding-top: 16px;
  padding-bottom: 16px;
`;
const MainBlock = styled.div`
  width: 100%;
  max-width: 1752px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
`;
const Content = styled.div`
  width: 100%;
  max-width: 1272px;
  padding-bottom: 300px;
`;
const Button = styled(animated.button)`
  cursor: pointer;
  padding: 16px 24px 14px;
  gap: 8px;
  border: 0;
  width: 410px;
  margin-top: 52px;
  background: #33cccc;
  border-radius: 12px;
`;

const GreetingsTitle = styled(animated.div)`
  position: relative;
  width: fit-content;
  font-size: 96px;
  color: #272727;
  display: flex;
  align-items: center;
`;

const GreetingsTitleSecond = styled(animated.div)`
  position: relative;
  width: 1100px;
  font-size: 45px;
  color: #272727;
  display: flex;
  align-items: center;
`;

const GreetingsTitleEyes = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 60px;
  margin-left: 30px;
  padding-top: 10px;
`;

const GreetingsTitleEye = styled(animated.div)`
  background-color: #272727;
  width: 15px;
  height: 15px;
  border-radius: 50%;
`;

const Smile = styled(animated.div)`
  position: absolute;
  right: -40px;
  display: inline-block;
  width: 160px;
  height: 160px;
  border: 10px solid transparent;
  border-radius: 50%;
  border-left-color: #272727;
  margin-top: 10px;
`;

const GreetingsBody = styled(animated.div)`
  width: 1134px;
  margin-top: 140px;
  font-style: normal;
  font-weight: 400;
  font-size: 45px;
  line-height: 111%;
  letter-spacing: -0.01em;
  color: #272727;
`;
const PictureItem = styled.div`
  display: block;
  height: 379px;
  &:first-child {
    margin-bottom: -370px;
  }
  &:last-child {
    margin-top: -360px;
  }
`;
const ArticleDesc = styled.div<{ leftProps: string }>`
  cursor: pointer;
  margin-left: 40px;
  left: ${(props) => props.leftProps}px;
  font-weight: 600;
  font-size: 24px;
`;
const ArticleSet = styled.div`
  margin-top: 250px;
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const Anchor = styled.div`
  width: 100px;
  height: 203px;
  position: absolute;
`;

const ElementsListWrapper = styled.div`
  position: relative;
`;

const ElementsListOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 110%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;

  ::after {
    content: '';
    position: absolute;
    right: -100px;
    width: 100px;
    height: 100%;
    background-color: #ffffff;
  }
`;

const Spacer = styled(animated.div)`
  width: 100%;
  height: 60vh;
  overflow: hidden;
`;

function disableScroll() {
  document.body.style.overflow = 'hidden';
}

function enableScroll() {
  document.body.style.overflow = 'auto';
  window.scrollTo(0, 0);
}

function MainPage() {
  // const Authorized = cookies.get<string>('auth');

  const [scrollEnabled, setScrollEnabled] = useState(true);
  const [Authorized, setAuth] = useState<any>(true);
  const descriptionRef: React.MutableRefObject<any> = useRef();
  const articleRef: React.MutableRefObject<any> = useRef();
  const courseRef: React.MutableRefObject<any> = useRef();
  const mediaRef: React.MutableRefObject<any> = useRef();
  const pictureArticleSet = [articleBottom, articleMiddle, articleTop];
  const pictureCourseSet = [courseBottom, courseMiddle, courseTop];
  const pictureMediaSet = [flowBottom, flowMiddle, flowTop];
  const executeScroll = () =>
    descriptionRef.current.scrollIntoView({
      behavior: 'smooth',
    });
  const executeScrollToArticle = () => {
    articleRef.current.scrollIntoView();
    window.scrollBy(0, -125);
  };
  const executeScrollToCourse = () => {
    courseRef.current.scrollIntoView();
    window.scrollBy(0, -125);
  };
  const executeScrollToMedia = () => {
    mediaRef.current.scrollIntoView();
    window.scrollBy(0, -125);
  };
  const [articlesData, setArticlesData] = useState<ArticleType[]>([]);
  const [courseData, setCourseData] = useState<CourseType[]>([]);
  const [mediaData, setMediaData] = useState<any[]>([]);
  const [testFirstData, setTestFirstData] = useState<Test>({} as Test);
  const [testSecondData, setTestSecondData] = useState<Test>({} as Test);
  const [testThirdData, setTestThirdData] = useState<Test>({} as Test);
  const [testFourthData, setTestFourthData] = useState<Test>({} as Test);

  const [articlesUnauthorizedData, setArticlesUnauthorizedData] = useState<
    any[]
  >([]);

  const [fitnessData, setFitnessData] = useState<any[]>([]);
  const [mediaActiveCategory, setMediaCategory] = useState('Медитации');
  const [isLoading, setIsLoading] = useState(false);
  const [isArticlesLoading, setIsArticlesLoading] = useState(false);
  const [isMeditationLoading, setIsMeditationLoading] = useState(false);
  const [isPreloaderStarted, setIsPreloaderStarted] = useState(false);

  const [lastView, setLastView] = useState<any>();

  const [animationsEnd, setAnimationsEnd] = useState(false);

  const getArticlesUnauthorized = async () => {
    const arr: any[] = [];
    await articleService
      .getArtilce(50)
      .then((response: any) => {
        arr.push(response.data);
        return articleService.getArtilce(55);
      })
      .then((response: any) => {
        arr.push(response.data);
        return articleService.getArtilce(31);
      })
      .then((response: any) => {
        arr.push(response.data);
        return articleService.getArtilce(21);
      })
      .then((response: any) => {
        arr.push(response.data);
        return articleService.getArtilce(66);
      })
      .then((response: any) => {
        arr.push(response.data);
      })
      .then(() => setArticlesUnauthorizedData(arr));
  };

  useEffect(() => {
    actionService.getFirstLogin().then((e) => {
      if (e.data === '' || e.data === false) {
        setAuth(null);
        getArticlesUnauthorized();
        actionService.setFirstLogin(true);
      }
    });
  }, []);

  const getArticles = async () => {
    await articleService.getArticles(1, '1', 10).then((response: any) => {
      setArticlesData(response.data.content);
    });
  };
  const getModules = async () => {
    await courseService.getAllModules('3').then((response: any) => {
      setCourseData(response.data.content);
    });
  };
  const getAllMedia = async () => {
    await testService.getTest('69').then(({ data }) => setTestFirstData(data));
    await testService.getTest('70').then(({ data }) => setTestSecondData(data));
    await testService.getTest('71').then(({ data }) => setTestThirdData(data));
    await testService.getTest('72').then(({ data }) => setTestFourthData(data));

    await mediaService.getAllMedia(1, 10).then((response: any) => {
      const filtered = response.data.content[1].fitness.filter(
        (a: any) => a.fitnessLevel === 'LOW'
      );

      setMediaData(response.data.content[0].meditations.slice(0, 10));

      setFitnessData(
        [
          ...filtered.filter(
            (a: any) => a.title === 'С кем вы будете тренироваться'
          ),
          ...filtered.sort(
            (a: any, b: any) => a.title.slice(-2) - b.title.slice(-2)
          ),
        ].slice(0, 10)
      );
    });
  };
  const getLastView = async () => {
    await mainPageService.getCategory().then((response: any) => {
      setLastView(response.data);
    });
  };

  useEffect(() => {
    getLastView();
    if (Authorized) getArticles();

    getModules();
    getAllMedia();
    window.scrollTo(0, 0);
    disableScroll();
  }, []);

  const [movingStyles, movingApi] = useSpring(() => ({
    from: {
      transform: 'scale(1) translateY(700px) translateX(0px)',
      opacity: 0,
    },
    config: config.stiff,
  }));

  const [movingSecondStyles, movingSecondApi] = useSpring(() => ({
    from: {
      transform: 'scale(1) translateY(700px) translateX(0px)',
      opacity: 0,
    },
    config: config.stiff,
  }));

  const [leftEyeStyles, leftEyeApi] = useSpring(() => ({
    from: {
      transform: 'scale(0)',
    },
    config: config.wobbly,
  }));

  const [rightEyeStyles, rightEyeApi] = useSpring(() => ({
    from: {
      transform: 'scale(0)',
    },
    config: config.wobbly,
  }));

  const [smileStyles, smileApi] = useSpring(() => ({
    from: {
      transform: 'rotate(0deg)',
      opacity: 0,
    },
    config: config.wobbly,
  }));

  const [paragraphStyles, paragraphApi] = useSpring(() => ({
    from: {
      transform: 'translateY(500px)',
      opacity: 0,
    },
    config: config.stiff,
  }));

  const [buttonStyles, buttonApi] = useSpring(() => ({
    from: {
      transform: 'translateY(200px)',
      opacity: 0,
    },
    config: config.gentle,
  }));

  const [spacerStyles, spacerApi] = useSpring(() => ({
    from: {
      marginTop: '0px',
      width: '60vh',
    },
    config: config.gentle,
  }));

  useEffect(
    () => {
      const animationHandler = () => {
        if (document.hidden) {
          movingApi.pause();
          spacerApi.pause();
          movingSecondApi.pause();
          leftEyeApi.pause();
          rightEyeApi.pause();
          smileApi.pause();
          paragraphApi.pause();
          buttonApi.pause();
        } else {
          movingApi.resume();
          spacerApi.resume();
          movingSecondApi.resume();
          leftEyeApi.resume();
          rightEyeApi.resume();
          smileApi.resume();
          paragraphApi.resume();
          buttonApi.resume();
        }
      };

      document.addEventListener('visibilitychange', animationHandler);

      if (lastView && lastView?.length !== 0) {
        movingSecondApi.start({
          to: [
            {
              transform: 'scale(1) translateY(330px) translateX(0px)',
              opacity: 1,
              delay: 1800,
            },
            {
              transform: 'scale(1) translateY(100px) translateX(0px)',
              opacity: 1,
              delay: 2000,
            },
          ],
        });
      }
      spacerApi.start({
        to: [
          {
            marginTop: '-700px',
            width: '0vh',
            delay: 2000,
          },
        ],
      });

      movingApi.start({
        to: [
          {
            transform: 'scale(1) translateY(330px) translateX(0px)',
            opacity: 1,
            delay: 4000,
          },
          {
            transform: 'scale(0.43) translateY(300px) translateX(-251px)',
            opacity: 1,
            delay: 2200,
          },
          {
            transform: 'scale(0.43) translateY(200px) translateX(-251px)',
            opacity: 1,
            delay: 3900,
          },
        ],
      });
      leftEyeApi.start({
        to: [
          {
            transform: 'scale(1)',
            delay: 4600,
          },
        ],
      });
      rightEyeApi.start({
        to: [
          {
            transform: 'scale(1)',
            delay: 4700,
          },
        ],
      });
      smileApi.start({
        to: [
          {
            transform: 'rotate(180deg)',
            opacity: 1,
            delay: 4800,
          },
        ],
      });

      paragraphApi.start({
        to: [
          {
            transform: 'translateY(0px)',
            opacity: 1,
            delay: 6700,
            // delay: !Authorized ? 6700 : 4500,
            onRest: () => setAnimationsEnd(true),
          },
          {
            transform: !Authorized ? 'translateY(-50px)' : 'translateY(§0px)',
            opacity: 1,
            delay: 3900,
          },
        ],
      });
      buttonApi.start({
        to: [
          {
            transform: 'translateY(0px)',
            opacity: 1,
            delay: 11000,
          },
        ],
      });
      return () =>
        document.removeEventListener('visibilitychange', animationHandler);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lastView]
  );

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    setIsMeditationLoading(true);

    let timer1: ReturnType<typeof setTimeout>;

    // eslint-disable-next-line prefer-const
    timer1 = setTimeout(() => setIsMeditationLoading(false), 400);

    return () => {
      clearTimeout(timer1);
      setIsMeditationLoading(true);
    };
  }, [isPreloaderStarted]);
  useEffect(() => {
    setIsArticlesLoading(true);

    let timer1: ReturnType<typeof setTimeout>;

    // eslint-disable-next-line prefer-const
    timer1 = setTimeout(() => setIsArticlesLoading(false), 300);

    return () => {
      clearTimeout(timer1);
      setIsArticlesLoading(true);
    };
  }, [isPreloaderStarted]);

  useEffect(() => {
    const footer = document.querySelector('.footer-wrapper');
    if (!footer) return;
    if (animationsEnd) {
      setScrollEnabled(true);
      enableScroll();
      footer.classList.remove('hidden');
    } else {
      footer.classList.add('hidden');
    }
  }, [animationsEnd]);

  const setCourseFilter: any = (cat: any, activeCategories: any) => {
    setIsLoading(true);
    if (cat.name === activeCategories) {
      courseService.getAllModules('').then((response: any) => {
        setCourseData(response.data.content);
        setIsLoading(false);
      });
    } else {
      courseService.getAllModules(cat.id).then((response: any) => {
        setCourseData(response.data.content);
        setIsLoading(false);
      });
    }
  };
  const setFilter: any = (cat: any, activeCategories: any) => {
    setIsPreloaderStarted(true);
    if (cat.name === activeCategories) {
      articleService.getArticles(1, '', 20).then((response: any) => {
        setArticlesData(response.data.content);
        setIsPreloaderStarted(false);
      });
    } else {
      articleService.getArticles(1, cat.id, 20).then((response: any) => {
        setArticlesData(response.data.content);
        setIsPreloaderStarted(false);
      });
    }
  };

  return (
    <MainBlock>
      <Content>
        <div>
          {!Authorized && (
            <GreetingsTitle style={movingStyles}>
              Привет
              <GreetingsTitleEyes>
                <GreetingsTitleEye style={leftEyeStyles} />
                <GreetingsTitleEye style={rightEyeStyles} />
              </GreetingsTitleEyes>
              <Smile style={smileStyles} />
            </GreetingsTitle>
          )}
          {Authorized && (
            <GreetingsTitleSecond style={movingSecondStyles}>
              С возвращением! Вот, на чём вы остановились.
            </GreetingsTitleSecond>
          )}
          <GreetingsBody style={paragraphStyles}>
            {!Authorized ? (
              `Сайт Wellness — сборник образовательных программ, бесплатных видеотренировок, аудиомедитаций и полезных статей, которые помогут приобретать полезные привычки. Здесь вы сможете укрепить ментальное и физическое здоровье. `
            ) : lastView && lastView.length !== 0 ? (
              <LastView lastView={lastView} />
            ) : (
              <Spacer style={spacerStyles} />
            )}
          </GreetingsBody>
          {!Authorized && (
            <div>
              <Button style={buttonStyles} onClick={executeScroll}>
                <Typography fz={28} fw={600} color="#FFFFFF">
                  Отлично, что дальше?
                </Typography>
              </Button>
              <Anchor ref={descriptionRef} />
              <Typography mt={290} fz={45}>
                Проходите курсы, читайте статьи, медитируйте
              </Typography>

              <Typography mt={16} fz={21} mb={58}>
                Узнайте, как научиться быстро засыпать, правильно медитировать,
                питаться сбалансированно и регулярно тренироваться дома.
              </Typography>

              <ItemList cols={3} colGap={0} rowGap={0} itemWidth={300}>
                <div>
                  {pictureArticleSet.map((item) => (
                    <PictureItem
                      key={uuidv4()}
                      onClick={executeScrollToArticle}
                    >
                      <img alt="test" src={item} />
                    </PictureItem>
                  ))}
                </div>
                <div>
                  {pictureCourseSet.map((item) => (
                    <PictureItem key={uuidv4()} onClick={executeScrollToCourse}>
                      <img alt="test" src={item} />
                    </PictureItem>
                  ))}
                </div>
                <div>
                  {pictureMediaSet.map((item) => (
                    <PictureItem key={uuidv4()} onClick={executeScrollToMedia}>
                      <img alt="test" src={item} />
                    </PictureItem>
                  ))}
                </div>
              </ItemList>
              <ItemList mb={197} cols={3} colGap={0} rowGap={0} itemWidth={300}>
                <ArticleDesc onClick={executeScrollToArticle} leftProps="200">
                  Перейти к статьям
                </ArticleDesc>
                <ArticleDesc onClick={executeScrollToCourse} leftProps="620">
                  Выбрать курсы
                </ArticleDesc>
                <ArticleDesc onClick={executeScrollToMedia} leftProps="1019">
                  Медитировать
                </ArticleDesc>
              </ItemList>
            </div>
          )}
        </div>

        {scrollEnabled && animationsEnd && (
          <>
            <ArticleSet ref={articleRef}>
              <Typography fz={45}>Статьи на каждый день</Typography>
              <ButtonWrapper>
                <NavLink to="/articles">
                  <AllArticleButton text="Все статьи" />
                </NavLink>
              </ButtonWrapper>
            </ArticleSet>
            {Authorized && (
              <CategoryFilter
                category="articleCategory"
                setFilter={setFilter}
              />
            )}
            <ElementsListWrapper>
              <ElementsList
                elementData={
                  Authorized ? articlesData : articlesUnauthorizedData
                }
                type="articles"
              />
              {isArticlesLoading && (
                <ElementsListOverlay>
                  <Preloader withoutStyles />
                </ElementsListOverlay>
              )}
            </ElementsListWrapper>

            <ArticleSet ref={courseRef}>
              <Typography fz={45}> Курсы для саморазвития </Typography>
              <ButtonWrapper>
                <NavLink to="/courses">
                  <AllArticleButton text="Все курсы" />
                </NavLink>
              </ButtonWrapper>
            </ArticleSet>
            <CategoryFilter
              category="courseCategory"
              setFilter={setCourseFilter}
            />
            <ElementsListWrapper>
              <ElementsList
                elementData={courseData}
                type="modules/:id/courses"
              />
              {isLoading && (
                <ElementsListOverlay>
                  <Preloader withoutStyles />
                </ElementsListOverlay>
              )}
            </ElementsListWrapper>

            <ArticleSet ref={mediaRef}>
              <Typography fz={45}> Медитации, тренировки, тесты </Typography>
              <ButtonWrapper>
                <NavLink to="/media">
                  <AllArticleButton text="Все медиа" />
                </NavLink>
              </ButtonWrapper>
            </ArticleSet>
            <ArticleFilter
              onClick={() => {
                setMediaCategory('Медитации');
                setIsPreloaderStarted((prev) => !prev);
              }}
              opacity={mediaActiveCategory === 'Медитации' ? 1 : 0.2}
            >
              Медитации
            </ArticleFilter>
            <ArticleFilter
              onClick={() => {
                setMediaCategory('Тесты');
                setIsPreloaderStarted((prev) => !prev);
              }}
              opacity={mediaActiveCategory === 'Тесты' ? 1 : 0.2}
            >
              Тесты
            </ArticleFilter>
            <ArticleFilter
              onClick={() => {
                setMediaCategory('Фитнес');
                setIsPreloaderStarted((prev) => !prev);
              }}
              opacity={mediaActiveCategory === 'Фитнес' ? 1 : 0.2}
            >
              Фитнес-тренировки
            </ArticleFilter>

            {mediaActiveCategory === 'Медитации' && (
              <ElementsListWrapper>
                <ElementsList elementData={mediaData} type="media" />
                {isMeditationLoading && (
                  <ElementsListOverlay>
                    <Preloader withoutStyles />
                  </ElementsListOverlay>
                )}
              </ElementsListWrapper>
            )}
            {mediaActiveCategory === 'Тесты' && (
              <ElementsListWrapper>
                <ElementsList
                  elementData={[
                    testFirstData,
                    testSecondData,
                    testThirdData,
                    testFourthData,
                  ]}
                  type="test"
                />
                {isMeditationLoading && (
                  <ElementsListOverlay>
                    <Preloader withoutStyles />
                  </ElementsListOverlay>
                )}
              </ElementsListWrapper>
            )}
            {mediaActiveCategory === 'Фитнес' && (
              <ElementsListWrapper>
                <ElementsList elementData={fitnessData} type="media-fitness" />
                {isMeditationLoading && (
                  <ElementsListOverlay>
                    <Preloader withoutStyles />
                  </ElementsListOverlay>
                )}
              </ElementsListWrapper>
            )}
          </>
        )}
      </Content>
    </MainBlock>
  );
}

export default MainPage;
