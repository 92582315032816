import styled from 'styled-components';
import { AnswerType, Question } from '../../models';
import Typography from './Typography';

type Props = {
  question: Question;
  type: string;
  onClick: (index: number) => void;
};

const Answers = styled.div`
  display: flex;
  flex-direction: column;
`;

const AnswerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
  & > div {
    cursor: pointer;
  }
`;

const radioColors = {
  right: '#33CCCC',
  wrong: '#E03131',
  default: 'transparent',
};

type AnswerProps = {
  color: string;
  selected: boolean;
  correct: boolean;
  type: string;
};
const getRadioColor = (props: AnswerProps) => {
  const { color, correct, selected, type } = props;
  if (type === 'color wheel') {
    return color;
  }
  let answerType = selected && correct ? 'right' : 'wrong';
  if (!selected) answerType = 'default';
  return radioColors[answerType as AnswerType];
};

const RadioButton = styled.div`
  position: relative;
  margin-top: -4px;
  height: 24px;
  min-width: 24px;
  border: 2px solid
    ${(props: AnswerProps) => {
      const { color, correct, selected, type } = props;
      return type !== 'color wheel' && !selected
        ? '#C1C1C1'
        : getRadioColor({ color, correct, selected, type });
    }};
  border-radius: 59px;
  background-color: ${(props: AnswerProps) => {
    const { color, correct, selected, type } = props;
    return getRadioColor({ color, correct, selected, type });
  }};
  ::after {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    top: 6px;
    left: 6px;
    background-color: ${(props: AnswerProps) => {
      const { color, correct, selected, type } = props;
      return selected
        ? '#fff'
        : getRadioColor({ color, correct, selected, type });
    }};
    border-radius: 50%;
  }
`;

const QuizImage = styled.img`
  width: 375px;
  height: 505px;
  margin-left: 15px;
  margin-bottom: 64px;
`;

const QuizWrapper = styled.div`
  display: flex;

  justify-content: space-between;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  b {
    font-size: 24px;
    font-weight: 600;
  }
  .question-title {
    font-size: 16px;
  }
  & > div:first-child {
    margin-bottom: 40px;
  }
`;

function Test(props: Props) {
  const { onClick, question, type } = props;
  const { name: title, answers } = question;

  const renderTitle = () =>
    title.includes('<b>') ? (
      <div
        className="question-title"
        dangerouslySetInnerHTML={{ __html: title }}
      />
    ) : (
      <Typography fz={24} mb={40} fw={600}>
        {title}
      </Typography>
    );

  return (
    <QuizWrapper>
      <MainContent>
        {renderTitle()}
        <Answers>
          {answers.map((answer, index) => (
            <AnswerWrapper key={index}>
              <RadioButton
                type={type}
                color={answer.color}
                selected={!!answer.selected}
                correct={
                  !['with picture', 'default+'].includes(type)
                    ? true
                    : !!answer.correct
                }
                onClick={() => onClick(index)}
              />
              <Typography fz={20} lh={32} onClick={() => onClick(index)}>
                {answer.name}
              </Typography>
            </AnswerWrapper>
          ))}
        </Answers>
      </MainContent>
      {question.imageFileKey && (
        <QuizImage
          src={`https://static.playground.sysdyn.ru/wellness/${question.imageFileKey}`}
          alt="quizImage"
        />
      )}
    </QuizWrapper>
  );
}
export default Test;
