import api from '../api';
import { Test } from '../models';

export const testService = {
  getAll: (page = 1, size = 10) =>
    api.get<{ content: Test[] }>(`content/test/all?page=${page}&size=${size}`),
  getTest: (id: string) => api.get<Test>(`content/test/${id}`),
  getResult: (id: string, params: any) =>
    api.get<string>(`content/test/${id}/result`, {
      params,
    }),
};
