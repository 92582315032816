import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import ItemList from '../elements/ItemList';
import PageTitle from '../elements/PageTitle';
import Typography from '../elements/Typography';
import Meditation from '../elements/Meditation';
import Exercise from '../elements/Exercise';
import { mediaService, testService } from '../../services';
import { MediaFitnessListItem, MediaListItem, Test } from '../../models';
// @ts-ignore
import meditatioImg from '../../assets/images/meditationImage.png';
import exerciseImg from '../../assets/images/exercise.png';
import Preloader from '../elements/Preloader';

const PageWrapper = styled.div`
  width: 100%;
  max-width: 1752px;
  margin: 0 auto;
  padding: 72px 122px 166px;
  display: flex;
  justify-content: center;
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
    padding: 122px 16px 16px;
  }
`;

const Content = styled.div`
  width: 100%;
  max-width: 1272px;
`;

const Categories = styled.div`
  display: flex;
  column-gap: 16px;
  margin-bottom: 56px;
  & > * {
    cursor: pointer;
  }
`;

const fitnessLevels = {
  LOW: 'Начинающим',
  MIDDLE: 'Продолжающим',
  HIGH: 'Подготовленным',
};

const arr = Object.keys(fitnessLevels);

function MediaPage() {
  // @ts-ignore
  window.ym(90053722, 'reachGoal', 'openMedia');

  const [fitnessLevel, setFitnessLevel] = useState(0);

  const [mediaFitnessData, setMediaFitnessData] = useState<
    MediaListItem['fitness'] | MediaFitnessListItem[]
  >([]);
  const [mediaData, setMediaData] = useState<MediaListItem['meditations']>([]);
  const [testFirstData, setTestFirstData] = useState<Test>({} as Test);
  const [testSecondData, setTestSecondData] = useState<Test>({} as Test);
  const [preloader, setPreloader] = useState(true);
  const [testThirdData, setTestThirdData] = useState<Test>({} as Test);
  const [testFourthData, setTestFourthData] = useState<Test>({} as Test);

  useEffect(() => {
    mediaService
      .getAllMedia()
      .then(({ data }) => setMediaData(data.content[0].meditations));

    mediaService
      .getAllMediaFitness(arr[fitnessLevel], 1, 30)
      .then(({ data }) => setMediaFitnessData(data.content));

    testService.getTest('69').then(({ data }) => setTestFirstData(data));
    testService.getTest('70').then(({ data }) => setTestSecondData(data));
    testService.getTest('71').then(({ data }) => setTestThirdData(data));
    testService.getTest('72').then(({ data }) => setTestFourthData(data));
  }, []);

  useEffect(() => {
    mediaService
      .getAllMediaFitness(arr[fitnessLevel], 1, 30)
      .then(({ data }) => {
        setMediaFitnessData(data.content);
        setPreloader(false);
      });
  }, [fitnessLevel]);

  return !preloader && mediaData && mediaFitnessData ? (
    <PageWrapper>
      <Content>
        <PageTitle mb={56} text="Медитации на каждый день" />
        <ItemList cols={3} colGap={19} rowGap={56} mb={120} itemWidth={411}>
          {mediaData
            .sort((a: any, b: any) => (a.contentId > b.contentId ? 1 : -1))
            .map((meditation) => (
              <NavLink to={`/media/${meditation.contentId}`}>
                <Meditation
                  isWatched={meditation.isWatched}
                  key={meditation.id}
                  src={meditation.imageFileKey || meditatioImg}
                  description={meditation.title}
                />
              </NavLink>
            ))}
        </ItemList>
        <PageTitle mb={56} text="Тесты" />
        <ItemList cols={3} colGap={19} rowGap={56} mb={120} itemWidth={411}>
          <NavLink to={`/test/${testFirstData.contentId}`}>
            <Meditation
              isWatched={testFirstData.isWatched}
              key={testFirstData.id}
              src={testFirstData.imageFileKey || meditatioImg}
              description={testFirstData.title}
            />
          </NavLink>
          <NavLink to={`/test/${testSecondData.contentId}`}>
            <Meditation
              isWatched={testSecondData.isWatched}
              key={testSecondData.id}
              src={testSecondData.imageFileKey || meditatioImg}
              description={testSecondData.title}
            />
          </NavLink>
          <NavLink to={`/test/${testThirdData.contentId}`}>
            <Meditation
              isWatched={testThirdData.isWatched}
              key={testThirdData.id}
              src={testThirdData.imageFileKey || meditatioImg}
              description={testThirdData.title}
            />
          </NavLink>
          <NavLink to={`/test/${testFourthData.contentId}`}>
            <Meditation
              isWatched={testFourthData.isWatched}
              key={testFourthData.id}
              src={testFourthData.imageFileKey || meditatioImg}
              description={testFourthData.title}
            />
          </NavLink>
        </ItemList>
        <PageTitle mb={40} text="Короткие фитнес-тренировки" />
        <Categories>
          {Object.values(fitnessLevels).map((level, index) => (
            <Typography
              mr={5}
              key={level}
              fz={21}
              lh={27}
              onClick={() => setFitnessLevel(index)}
              disable={fitnessLevel !== index}
            >
              {level}
            </Typography>
          ))}
        </Categories>
        <ItemList cols={4} colGap={15} rowGap={55} itemWidth={304}>
          {mediaFitnessData.map((mediaFitness) => (
            <NavLink to={`/media-fitness/${mediaFitness.contentId}`}>
              <Exercise
                key={mediaFitness.contentId}
                src={mediaFitness.imageFileKey || exerciseImg}
                description={mediaFitness.title}
                isWatched={mediaFitness.isWatched}
              />
            </NavLink>
          ))}
        </ItemList>
      </Content>
    </PageWrapper>
  ) : (
    <Preloader />
  );
}

export default MediaPage;
