import styled from 'styled-components';

export const PageWrapper = styled.div`
  width: 100%;
  max-width: 1392px;
  margin: 0 auto;
  padding: 52px 56px 110px;
  b {
    font-size: 24px;
    font-weight: 600;
  }
  .test-description {
    font-size: 16px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 48px;
  column-gap: 15px;
  & > div:first-child {
    font-size: 20px !important;
    font-weight: 400;
    a {
      color: #33cccc;
    }
  }
`;

export const TestResultImage = styled.img`
  width: 160px;
  height: 160px;
  margin-bottom: 56px;
`;

export const ResultBlock = styled.div`
  max-width: 588px;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > div:first-child {
    margin-right: auto;
  }
  .result-text {
    font-size: 16px;
    b {
      font-size: 20px;
      font-weight: 600;
    }
  }
`;

export const TestWrapper = styled.div`
  position: relative;
  .all-questions-description {
    margin-bottom: 48px;
  }
`;

export const DonutWrapper = styled.div`
  position: absolute;
  transform: scale(-28.5%, 28.5%);
  top: -265px;
  right: -575px;
`;
