import { ReactNode } from 'react';
import styled, { css } from 'styled-components';

type Props = {
  children: ReactNode;
  onClick?: () => void;
  className?: string;
  disable?: boolean;
  fz?: number;
  mb?: number;
  mt?: number;
  pl?: number;
  fw?: number;
  lh?: number;
  ls?: string;
  ff?: string;
  mr?: number;
  width?: number;
  opacity?: number;
  color?: string;
};

const StyledTypography = styled.div`
  ${(propList: Props) =>
    propList.pl &&
    css`
      padding-left: ${(props: Props) => `${props.pl}px`};
    `};
  ${(propList: Props) =>
    propList.ff &&
    css`
      font-family: ${(props: Props) => `${props.ff}`};
    `};
  ${(propList: Props) =>
    propList.mb &&
    css`
      margin-bottom: ${(props: Props) => `${props.mb}px`};
    `};
  ${(propList: Props) =>
    propList.mt &&
    css`
      margin-top: ${(props: Props) => `${props.mt}px`};
    `};
  ${(propList: Props) =>
    propList.mr &&
    css`
      margin-right: ${(props: Props) => `${props.mr}px`};
    `};
  ${(propList: Props) =>
    propList.fz &&
    css`
      font-size: ${(props: Props) => `${props.fz}px !important`};
    `};
  ${(propList: Props) =>
    propList.fw &&
    css`
      font-weight: ${(props: Props) => `${props.fw}`};
    `};
  ${(propList: Props) =>
    propList.lh &&
    css`
      line-height: ${(props: Props) => `${props.lh}px`};
    `};
  ${(propList: Props) =>
    propList.color &&
    css`
      color: ${(props: Props) => `${props.color}`};
    `};
  ${(propList: Props) =>
    propList.ls &&
    css`
      letter-spacing: ${(props: Props) => `${props.ls}`};
    `};
  ${(propList: Props) =>
    propList.disable &&
    css`
      color: #c1c1c1;
    `};
  ${(propList: Props) =>
    propList.opacity &&
    css`
      opacity: ${(props: Props) => `${props.opacity};`};
    `};
  ${(propList: Props) =>
    propList.width &&
    css`
      width: ${(props: Props) => `${props.width}px;`};
    `};
`;

function Typography(props: Props) {
  return <StyledTypography {...props} />;
}
export default Typography;
